import { useForumTopicsPageQuery } from "./loaders/ForumTopicsPage";
import ForumHelmet from "../components/ForumHelmet";
import TopicsPage from "./common/TopicsPage";
import ErrorPage from "./ErrorPage";
import { useIntl } from "react-intl";
import { MetaLayout } from "../common/MetaLayout";

export default function ForumTopicsPage() {
  const {
    query: { forumBySlug: forum },
    variables: { order: initialOrder },
  } = useForumTopicsPageQuery();
  const intl = useIntl();
  if (!forum) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Discussion not found",
        })}
      />
    );
  }

  return (
    <MetaLayout
      metaTitle={intl.formatMessage(
        { defaultMessage: "Quantum Discussions | {title}" },
        { title: forum.title },
      )}
      metaDescription={intl.formatMessage(
        {
          defaultMessage:
            "Join the {title} discussion on Aqora and exchange with the global community of quantum experts",
        },
        { title: forum.title },
      )}
    >
      <ForumHelmet forum={forum}>
        <TopicsPage forum={forum} initialOrder={initialOrder} />
      </ForumHelmet>
    </MetaLayout>
  );
}
