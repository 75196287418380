import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useEntitySubmissionCodePageQuery } from "./loaders/EntitySubmissionCodePage";
import ErrorPage from "./ErrorPage";
import ProjectFileBrowser from "../components/ProjectFileBrowser";
import SubmissionStatusBadge from "../components/SubmissionStatusBadge";
import ApproveProjectVersionButton from "../components/ApproveProjectVersionButton";
import { MetaLayout } from "../common/MetaLayout";

export default function EntitySubmissionCodePage() {
  const intl = useIntl();
  const {
    query,
    variables: { slug, version = "latest", fileKind },
  } = useEntitySubmissionCodePageQuery();
  const projectVersion = query.competitionBySlug?.submission?.version;
  if (!projectVersion) {
    return (
      <ErrorPage status={200}>
        <FormattedMessage defaultMessage="There's nothing to see here at the moment!" />
      </ErrorPage>
    );
  }
  const competition = query.competitionBySlug!;
  const submission = competition.submission!;
  const username = submission.entity.username;
  return (
    <MetaLayout
      metaTitle={intl.formatMessage(
        { defaultMessage: "{username} | submissions code" },
        { username },
      )}
    >
      <div className="flex flex-row mb-4 py-4 border-b border-b-gray-300 justify-between flex-wrap">
        <h2 className="inline text-2xl">
          <Link to={`/${username}`} className="text-blue-600">
            {username}
          </Link>
          <span className="text-gray-400">{" / "}</span>
          <Link
            to={`/competitions/${competition.slug}`}
            className="text-blue-600"
          >
            {competition.title}
          </Link>
          <span className="text-gray-400">{" / "}</span>
          {version}
        </h2>

        <div className="flex flex-row gap-3">
          {competition.requiresApproval && projectVersion.viewerCanApprove && (
            <ApproveProjectVersionButton projectVersion={projectVersion} />
          )}
          <SubmissionStatusBadge projectVersion={projectVersion} subscribe />
        </div>
      </div>

      <ProjectFileBrowser
        root={`/${username}/submissions/${slug}/${version}/code/${fileKind.toLowerCase()}`}
        project={submission}
      />
    </MetaLayout>
  );
}
