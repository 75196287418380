/**
 * @generated SignedSource<<9b26ccae979f788a19cc78365bb2f8f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSettingIntegrationsSubscription$variables = {
  id: string;
};
export type UserSettingIntegrationsSubscription$data = {
  readonly updatedEntity: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserSettingIntegrationsFragment">;
  };
};
export type UserSettingIntegrationsSubscription = {
  response: UserSettingIntegrationsSubscription$data;
  variables: UserSettingIntegrationsSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSettingIntegrationsSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updatedEntity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserSettingIntegrationsFragment"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserSettingIntegrationsSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updatedEntity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAvailableOnQuantumJobs",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobBoardProfileLink",
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee89f5359aae4bb16845dbc94124e55d",
    "id": null,
    "metadata": {},
    "name": "UserSettingIntegrationsSubscription",
    "operationKind": "subscription",
    "text": "subscription UserSettingIntegrationsSubscription(\n  $id: ID!\n) {\n  updatedEntity(id: $id) {\n    __typename\n    id\n    ... on User {\n      ...UserSettingIntegrationsFragment\n    }\n  }\n}\n\nfragment UserSettingIntegrationsFragment on User {\n  id\n  isAvailableOnQuantumJobs\n  jobBoardProfileLink\n}\n"
  }
};
})();

(node as any).hash = "fd2c54ed7f692de150feffd8da9b3583";

export default node;
