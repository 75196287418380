import { useState, useCallback, ReactNode } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "sonner";
import { useAuth, useLoginRedirect } from "../utils/auth";
import Button from "./Button";
import * as Dialog from "./Dialog";
import Markdown from "./Markdown";
import { EventJoinButtonFragment$key } from "./__generated__/EventJoinButtonFragment.graphql";
import { EventJoinButtonMutation as EventJoinButtonMutationType } from "./__generated__/EventJoinButtonMutation.graphql";

const EventJoinButtonFragment = graphql`
  fragment EventJoinButtonFragment on Event {
    id
    viewerCanAgree: viewerCan(action: CREATE_EVENT_RULE_AGREEMENT)
    viewerCanJoin: viewerCan(action: JOIN_EVENT)
    membership {
      id
    }
    entityRuleAgreements(first: 1) @ifAllowed {
      nodes {
        id
      }
    }
    forum {
      id
      entitySubscription @ifAllowed {
        id
      }
    }
    latestRule {
      id
      text
      entityAgreement @ifAllowed {
        id
        createdAt
      }
    }
  }
`;

const EventJoinButtonMutation = graphql`
  mutation EventJoinButtonMutation(
    $eventId: ID!
    $forumId: ID!
    $join: Boolean!
    $agree: Boolean!
    $subscribe: Boolean!
  ) {
    joinEvent(eventId: $eventId) @include(if: $join) {
      node {
        id
        event {
          membership {
            id
          }
        }
      }
    }
    agreeToEventRule(event: $eventId) @include(if: $agree) {
      id
      createdAt
      eventRule {
        event {
          id
          entityRuleAgreements(first: 1) @ifAllowed {
            nodes {
              id
            }
          }
          latestRule {
            id
            entityAgreement @ifAllowed {
              id
              createdAt
            }
          }
        }
      }
    }
    subscribeToSubject(subject: $forumId) @include(if: $subscribe) {
      node {
        subject {
          id
          entitySubscription @ifAllowed {
            id
          }
        }
      }
    }
  }
`;

interface Props {
  event: EventJoinButtonFragment$key;
  children?: ReactNode;
}

export default function EventJoinButton({
  event: eventFragment,
  children,
}: Props) {
  const event = useFragment(EventJoinButtonFragment, eventFragment);
  const { userId } = useAuth();

  if (!userId) {
    return <EventLoginButton />;
  }
  const hasJoined = !!event.membership;
  const hasAccepted = !!event.latestRule?.entityAgreement;
  const hasSubscribed = !!event.forum.entitySubscription;
  if (
    (!hasJoined && !event.viewerCanJoin) ||
    (!hasAccepted && !event.viewerCanAgree)
  ) {
    return null;
  }
  if (!hasJoined || !hasAccepted) {
    const hasAcceptedBefore = event.entityRuleAgreements?.nodes.length > 0;
    return (
      <EventJoinModal
        eventId={event.id}
        forumId={event.forum.id}
        hasJoined={hasJoined}
        hasAccepted={hasAccepted}
        hasAcceptedBefore={hasAcceptedBefore}
        hasSubscribed={hasSubscribed}
        rules={event.latestRule?.text}
      />
    );
  }
  return children;
}

function EventLoginButton() {
  const to = useLoginRedirect();
  return (
    <Button to={to}>
      <FormattedMessage defaultMessage="Join event" />
    </Button>
  );
}

function EventJoinModal({
  eventId,
  forumId,
  hasJoined,
  hasAccepted,
  hasAcceptedBefore,
  hasSubscribed,
  rules,
}: {
  eventId: string;
  forumId: string;
  hasJoined: boolean;
  hasAccepted: boolean;
  hasAcceptedBefore: boolean;
  hasSubscribed: boolean;
  rules?: string;
}) {
  const intl = useIntl();

  const [commitMutation, isMutationInFlight] =
    useMutation<EventJoinButtonMutationType>(EventJoinButtonMutation);
  const [open, setOpen] = useState(false);

  const doJoin = useCallback(() => {
    commitMutation({
      variables: {
        eventId,
        forumId,
        agree: !hasAccepted,
        join: !hasJoined,
        subscribe: !hasSubscribed,
      },
      onError() {
        toast.error(
          intl.formatMessage({
            defaultMessage: "Could not join the event. Please try again",
          }),
        );
      },
      onCompleted() {
        toast.success(
          intl.formatMessage({
            defaultMessage: "Successfully joined the event",
          }),
        );
        setOpen(false);
      },
    });
  }, [
    intl,
    eventId,
    forumId,
    commitMutation,
    setOpen,
    hasSubscribed,
    hasJoined,
    hasAccepted,
  ]);

  const onJoin = useCallback(() => {
    if (hasAccepted && hasJoined && hasSubscribed) {
      return;
    } else if (hasAccepted) {
      doJoin();
    } else {
      setOpen(true);
    }
  }, [hasAccepted, hasJoined, hasSubscribed, setOpen, doJoin]);

  const onAccept = useCallback(() => {
    doJoin();
  }, [doJoin]);

  return (
    <>
      <Button onClick={onJoin} disabled={isMutationInFlight}>
        {hasJoined && hasAcceptedBefore ? (
          <FormattedMessage defaultMessage="Review rules" />
        ) : (
          <FormattedMessage defaultMessage="Join event" />
        )}
      </Button>
      <Dialog.Root open={open}>
        <Dialog.Content>
          <div className="flex flex-col space-y-4 p-4 md:px-2">
            <h1 className="text-2xl font-bold w-full font-poppins">
              <FormattedMessage defaultMessage="Rules, Terms & Conditions" />
            </h1>
            {rules && (
              <>
                <h2 className="font-bold w-full">
                  <FormattedMessage defaultMessage="Event specific rules" />
                </h2>
                <div className="border border-gray-300 bg-background p-4 rounded-lg max-h-96 overflow-y-auto">
                  <Markdown>{rules}</Markdown>
                </div>
              </>
            )}
            <p className="py-2">
              <FormattedMessage defaultMessage="By default all events must comply to the rules defined in" />{" "}
              <a
                href="https://aqora.io/terms"
                target="_blank"
                rel="noreferrer"
                className="text-blue-400"
              >
                <FormattedMessage defaultMessage="aqora's Terms of Use" />
              </a>
            </p>
            <div className="flex flex-row space-x-4">
              <Button
                kind="secondary"
                className="w-full"
                onClick={() => setOpen(false)}
                disabled={isMutationInFlight}
              >
                <FormattedMessage defaultMessage="I decline" />
              </Button>
              <Button
                kind="primary"
                className="w-full"
                onClick={onAccept}
                disabled={isMutationInFlight}
              >
                <FormattedMessage defaultMessage="I accept" />
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
