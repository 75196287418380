/**
 * @generated SignedSource<<545e8a983294c89c1f3b27979ad8bf4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventCompetitionsPageCreateMutation$variables = {
  competitionId: string;
  connections: ReadonlyArray<string>;
  eventId: string;
};
export type EventCompetitionsPageCreateMutation$data = {
  readonly addEventCompetition: {
    readonly node: {
      readonly competition: {
        readonly id: string;
        readonly slug: string;
        readonly title: string;
        readonly " $fragmentSpreads": FragmentRefs<"CompetitionCardFragment">;
      };
      readonly id: string;
      readonly viewerCanDelete: boolean;
    };
  };
};
export type EventCompetitionsPageCreateMutation = {
  response: EventCompetitionsPageCreateMutation$data;
  variables: EventCompetitionsPageCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "competitionId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "eventId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "competitionId",
    "variableName": "competitionId"
  },
  {
    "kind": "Variable",
    "name": "eventId",
    "variableName": "eventId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": "viewerCanDelete",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "REMOVE_EVENT_COMPETITION"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"REMOVE_EVENT_COMPETITION\")"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventCompetitionsPageCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "EventCompetitionEdge",
        "kind": "LinkedField",
        "name": "addEventCompetition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventCompetition",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Competition",
                "kind": "LinkedField",
                "name": "competition",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CompetitionCardFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "EventCompetitionsPageCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "EventCompetitionEdge",
        "kind": "LinkedField",
        "name": "addEventCompetition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventCompetition",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Competition",
                "kind": "LinkedField",
                "name": "competition",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbnail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "host",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isEntity"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageThumbnail",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompetitionTagConnection",
                    "kind": "LinkedField",
                    "name": "tags",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TagEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Tag",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": null,
        "handle": "prependEdge",
        "key": "",
        "kind": "LinkedHandle",
        "name": "addEventCompetition",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "5ce186d3281590674098b1c16d08539a",
    "id": null,
    "metadata": {},
    "name": "EventCompetitionsPageCreateMutation",
    "operationKind": "mutation",
    "text": "mutation EventCompetitionsPageCreateMutation(\n  $eventId: ID!\n  $competitionId: ID!\n) {\n  addEventCompetition(eventId: $eventId, competitionId: $competitionId) {\n    node {\n      id\n      viewerCanDelete: viewerCan(action: REMOVE_EVENT_COMPETITION)\n      competition {\n        id\n        slug\n        title\n        ...CompetitionCardFragment\n      }\n    }\n  }\n}\n\nfragment ActivityCardHostFragment on Entity {\n  __isEntity: __typename\n  username\n  ...EntityProfilePicFragment_49kWkb\n}\n\nfragment ActivityCardTagsFragment on CompetitionTagConnection {\n  edges {\n    node {\n      id\n      ...CompetitionTagFragment\n    }\n  }\n}\n\nfragment CompetitionCardFragment on Competition {\n  id\n  slug\n  title\n  shortDescription\n  thumbnail\n  host {\n    __typename\n    ...ActivityCardHostFragment\n    id\n  }\n  tags {\n    ...ActivityCardTagsFragment\n  }\n}\n\nfragment CompetitionTagFragment on Tag {\n  name\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n"
  }
};
})();

(node as any).hash = "cf898850ae59eeae2f89998cd6af21da";

export default node;
