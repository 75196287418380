import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { CompetitionTopicsPageQuery as CompetitionTopicsPageQueryType } from "./__generated__/CompetitionTopicsPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";
import {
  getVotableOrderParam,
  DEFAULT_VOTABLE_ORDER,
} from "../../utils/votableOrder";

export type { CompetitionTopicsPageQuery as CompetitionTopicsPageQueryType } from "./__generated__/CompetitionTopicsPageQuery.graphql";

export const CompetitionTopicsPageQuery = graphql`
  query CompetitionTopicsPageQuery($slug: String!, $order: VotableOrder!) {
    competitionBySlug(slug: $slug) {
      forum {
        ...TopicsPageFragment @arguments(order: $order)
        ...ForumHelmetFragment
      }
    }
  }
`;

export const competitionTopicsPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug }, request }: LoaderArgs) => {
    const order = getVotableOrderParam(request) || DEFAULT_VOTABLE_ORDER;
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<CompetitionTopicsPageQueryType>(
      environment,
      CompetitionTopicsPageQuery,
      { slug, order },
    );
  };
};

export const useCompetitionTopicsPageQuery = () =>
  usePreloaded<CompetitionTopicsPageQueryType>();
