import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { EventTopicCreatePageQuery as EventTopicCreatePageQueryType } from "./__generated__/EventTopicCreatePageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { EventTopicCreatePageQuery as EventTopicCreatePageQueryType } from "./__generated__/EventTopicCreatePageQuery.graphql";

export const EventTopicCreatePageQuery = graphql`
  query EventTopicCreatePageQuery($slug: String!) {
    eventBySlug(slug: $slug) {
      id
      forum {
        id
      }
      slug
    }
  }
`;

export const eventTopicCreatePageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<EventTopicCreatePageQueryType>(
      environment,
      EventTopicCreatePageQuery,
      { slug },
    );
  };
};

export const useEventTopicCreatePageQuery = () =>
  usePreloaded<EventTopicCreatePageQueryType>();
