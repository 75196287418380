import { useState } from "react";
import { useForm } from "react-hook-form";
import { graphql, useMutation } from "react-relay";
import { Link, useSearchParams } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { useLocation } from "../utils/location";

import { SignupPageUserMutation as SignupPageUserMutationType } from "./__generated__/SignupPageUserMutation.graphql";
import { useOnAuthCallback } from "../utils/auth";
import { isNotBannedUsername } from "../utils/validation";

import Button from "../components/Button";
import FormGroup from "../components/FormGroup";
import TextInput from "../components/TextInput";
import { logger } from "../common/logger";
import { MetaLayout } from "../common/MetaLayout";

const SignupPageUserMutation = graphql`
  mutation SignupPageUserMutation($input: SignupUserInput!) {
    signupUser(input: $input) {
      node {
        id
      }
    }
  }
`;

type FormData = {
  displayName: string;
  username: string;
  email: string;
  password: string;
};

export default function Signup() {
  const location = useLocation();
  const intl = useIntl();
  const [searchParams, _setSearchParams] = useSearchParams();
  const onAuthenticated = useOnAuthCallback();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [commitMutation, isMutationInFlight] =
    useMutation<SignupPageUserMutationType>(SignupPageUserMutation);
  const onSubmit = handleSubmit((data) => {
    setFormError(undefined);
    commitMutation({
      variables: {
        input: data,
      },
      onError(error) {
        logger.error(error);
        setFormError(
          intl.formatMessage({
            defaultMessage:
              "Could not create account. Username or email may already exist. Check your credentials and try again.",
          }),
        );
      },
      onCompleted() {
        onAuthenticated();
      },
    });
  });
  const errorMessages = {
    displayName: {
      required: intl.formatMessage({ defaultMessage: "Name is required" }),
      maxLength: intl.formatMessage({
        defaultMessage: "Name must be at most 50 characters",
      }),
    },
    username: {
      required: intl.formatMessage({ defaultMessage: "Username is required" }),
      minLength: intl.formatMessage({
        defaultMessage: "Username must be at least 3 characters",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "Username must be at most 20 characters",
      }),
      pattern: intl.formatMessage({
        defaultMessage:
          "Username can only contain letters, numbers, and underscores",
      }),
      isNotBannedUsername: intl.formatMessage({
        defaultMessage: "Username not allowed",
      }),
    },
    email: {
      required: intl.formatMessage({ defaultMessage: "Email is required" }),
    },
    password: {
      required: intl.formatMessage({ defaultMessage: "Password is required" }),
      minLength: intl.formatMessage({
        defaultMessage: "Password must be at least 6 characters",
      }),
    },
  };
  return (
    <MetaLayout
      metaTitle={intl.formatMessage({
        defaultMessage:
          "Sign Up on Aqora.io - Join the Global Quantum Community",
      })}
    >
      <h1 className="text-xl font-bold mb-4">
        <FormattedMessage defaultMessage="Signup" />
      </h1>
      <form onSubmit={onSubmit}>
        <fieldset className="flex flex-col space-y-3">
          <legend className="sr-only">
            {intl.formatMessage({ defaultMessage: "Signup Form" })}
          </legend>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Name" })}
            error={
              typeof errors.displayName?.type === "string" &&
              errorMessages.displayName[
                errors.displayName
                  .type as keyof typeof errorMessages.displayName
              ]
            }
          >
            <TextInput
              aria-invalid={errors.displayName ? "true" : "false"}
              {...register("displayName", {
                setValueAs: (value) => value.trim(),
                required: true,
                maxLength: 50,
              })}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Username" })}
            error={
              typeof errors.username?.type === "string" &&
              errorMessages.username[
                errors.username.type as keyof typeof errorMessages.username
              ]
            }
          >
            <TextInput
              prefix={location.host + "/"}
              aria-invalid={errors.username ? "true" : "false"}
              {...register("username", {
                required: true,
                setValueAs: (value) => value.trim(),
                minLength: 3,
                maxLength: 20,
                pattern: /^[a-zA-Z0-9_]*$/,
                validate: { isNotBannedUsername: isNotBannedUsername([]) },
              })}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Email" })}
            error={
              typeof errors.email?.type === "string" &&
              errorMessages.email[
                errors.email.type as keyof typeof errorMessages.email
              ]
            }
          >
            <TextInput
              type="email"
              aria-invalid={errors.email ? "true" : "false"}
              {...register("email", {
                required: true,
                setValueAs: (value) => value.trim(),
              })}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Password" })}
            error={
              typeof errors.password?.type === "string" &&
              errorMessages.password[
                errors.password.type as keyof typeof errorMessages.password
              ]
            }
          >
            <TextInput
              type="password"
              aria-invalid={errors.password ? "true" : "false"}
              {...register("password", {
                required: true,
                minLength: 6,
              })}
            />
          </FormGroup>
          {formError && (
            <p className="pt-1 text-sm text-red-500">{formError}</p>
          )}
          <p className="pt-3 text-sm text-gray-600">
            <FormattedMessage
              defaultMessage="By clicking Signup below you agree to the <terms>Terms & Conditions</terms> and the <privacy>Privacy Policy</privacy>"
              values={{
                terms: (chunks) => (
                  <Link
                    to={"https://aqora.io/terms"}
                    target="_blank"
                    className="text-indigo"
                  >
                    {chunks}
                  </Link>
                ),
                privacy: (chunks) => (
                  <Link
                    to={"https://aqora.io/privacy-policy"}
                    target="_blank"
                    className="text-indigo"
                  >
                    {chunks}
                  </Link>
                ),
              }}
            />
          </p>
          <div className="py-3 flex justify-between items-center">
            <p className="text-gray-600 pr-2">
              <FormattedMessage
                defaultMessage="Already have an account? {loginLink}"
                values={{
                  loginLink: (
                    <Link
                      to={{
                        pathname: "/login",
                        search: searchParams.toString(),
                      }}
                      className="text-indigo"
                    >
                      <FormattedMessage defaultMessage="Login here" />
                    </Link>
                  ),
                }}
              />
            </p>
            <Button type="submit" disabled={isMutationInFlight}>
              <FormattedMessage defaultMessage="Signup" />
            </Button>
          </div>
        </fieldset>
      </form>
    </MetaLayout>
  );
}
