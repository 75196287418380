/**
 * @generated SignedSource<<16aa11ddca9dff0c6adc0b8221add895>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventScaffoldQuery$variables = {
  slug: string;
};
export type EventScaffoldQuery$data = {
  readonly eventBySlug: {
    readonly banner: any | null | undefined;
    readonly createdAt: string;
    readonly description: string | null | undefined;
    readonly host: {
      readonly displayName: string;
      readonly id: string;
      readonly username: string;
      readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
    };
    readonly id: string;
    readonly isPrivate: boolean;
    readonly shortDescription: string;
    readonly slug: string;
    readonly thumbnail: any | null | undefined;
    readonly title: string;
    readonly viewerCanAddCompetition: boolean;
    readonly viewerCanEdit: boolean;
    readonly viewerCanEditMembers: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"EventJoinButtonFragment">;
  } | null | undefined;
  readonly viewer: {
    readonly id: string;
  };
};
export type EventScaffoldQuery = {
  response: EventScaffoldQuery$data;
  variables: EventScaffoldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "banner",
  "storageKey": null
},
v12 = {
  "alias": "viewerCanEdit",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "UPDATE_EVENT"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_EVENT\")"
},
v13 = {
  "alias": "viewerCanEditMembers",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "ADD_EVENT_MEMBER"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"ADD_EVENT_MEMBER\")"
},
v14 = {
  "alias": "viewerCanAddCompetition",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "ADD_EVENT_COMPETITION"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"ADD_EVENT_COMPETITION\")"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPrivate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventScaffoldQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventBySlug",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "host",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "thumbnail",
                    "value": true
                  }
                ],
                "kind": "FragmentSpread",
                "name": "EntityProfilePicFragment"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventJoinButtonFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventScaffoldQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventBySlug",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "host",
            "plural": false,
            "selections": [
              (v18/*: any*/),
              (v1/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isEntity"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageThumbnail",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "viewerCanAgree",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "CREATE_EVENT_RULE_AGREEMENT"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"CREATE_EVENT_RULE_AGREEMENT\")"
          },
          {
            "alias": "viewerCanJoin",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "JOIN_EVENT"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"JOIN_EVENT\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EventMembership",
            "kind": "LinkedField",
            "name": "membership",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              }
            ],
            "concreteType": "EventRuleAgreementConnection",
            "kind": "LinkedField",
            "name": "entityRuleAgreements",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventRuleAgreement",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": "entityRuleAgreements(first:1)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Forum",
            "kind": "LinkedField",
            "name": "forum",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "entitySubscription",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EventRule",
            "kind": "LinkedField",
            "name": "latestRule",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EventRuleAgreement",
                "kind": "LinkedField",
                "name": "entityAgreement",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0b6d543d342d988a495b56576e75f565",
    "id": null,
    "metadata": {},
    "name": "EventScaffoldQuery",
    "operationKind": "query",
    "text": "query EventScaffoldQuery(\n  $slug: String!\n) {\n  viewer @ifAllowed {\n    id\n  }\n  eventBySlug(slug: $slug) {\n    id\n    slug\n    title\n    shortDescription\n    description\n    thumbnail\n    createdAt\n    banner\n    viewerCanEdit: viewerCan(action: UPDATE_EVENT)\n    viewerCanEditMembers: viewerCan(action: ADD_EVENT_MEMBER)\n    viewerCanAddCompetition: viewerCan(action: ADD_EVENT_COMPETITION)\n    isPrivate\n    host {\n      __typename\n      id\n      displayName\n      username\n      ...EntityProfilePicFragment_49kWkb\n    }\n    ...EventJoinButtonFragment\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment EventJoinButtonFragment on Event {\n  id\n  viewerCanAgree: viewerCan(action: CREATE_EVENT_RULE_AGREEMENT)\n  viewerCanJoin: viewerCan(action: JOIN_EVENT)\n  membership {\n    id\n  }\n  entityRuleAgreements(first: 1) @ifAllowed {\n    nodes {\n      id\n    }\n  }\n  forum {\n    id\n    entitySubscription @ifAllowed {\n      __typename\n      id\n    }\n  }\n  latestRule {\n    id\n    text\n    entityAgreement @ifAllowed {\n      id\n      createdAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c3b6f0f98435d2991168fd3c64802d5d";

export default node;
