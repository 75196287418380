/**
 * @generated SignedSource<<e0074593c9f8a739523baa00cace5018>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicCommentsNewCommentSubscription$variables = {
  topicId: string;
};
export type TopicCommentsNewCommentSubscription$data = {
  readonly newComments: {
    readonly hotness: number;
    readonly node: {
      readonly id: string;
      readonly parent: {
        readonly id: string;
      } | null | undefined;
      readonly topic: {
        readonly id: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"CommentFragment">;
    };
  };
};
export type TopicCommentsNewCommentSubscription = {
  response: TopicCommentsNewCommentSubscription$data;
  variables: TopicCommentsNewCommentSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "topicId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "topicId",
    "variableName": "topicId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hotness",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Comment",
  "kind": "LinkedField",
  "name": "parent",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Topic",
  "kind": "LinkedField",
  "name": "topic",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": "viewerCanDelete",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "DELETE_COMMENT"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"DELETE_COMMENT\")"
},
v8 = {
  "alias": "viewerCanUpdate",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "UPDATE_COMMENT"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_COMMENT\")"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "edited",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageThumbnail",
          "storageKey": null
        }
      ],
      "type": "Entity",
      "abstractKey": "__isEntity"
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": "viewerCanReply",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "CREATE_COMMENT"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"CREATE_COMMENT\")"
},
v14 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": "HOT"
  }
],
v15 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "votes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EntityVote",
      "kind": "LinkedField",
      "name": "voted",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "viewerCanVote",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "PUBLISH_VOTE"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"PUBLISH_VOTE\")"
    }
  ],
  "type": "Votable",
  "abstractKey": "__isVotable"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TopicCommentsNewCommentSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CommentEdge",
        "kind": "LinkedField",
        "name": "newComments",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Comment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "bottomLevel",
                    "value": true
                  }
                ],
                "kind": "FragmentSpread",
                "name": "CommentFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TopicCommentsNewCommentSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CommentEdge",
        "kind": "LinkedField",
        "name": "newComments",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Comment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": (v14/*: any*/),
                "concreteType": "CommentConnection",
                "kind": "LinkedField",
                "name": "children",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Comment",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v6/*: any*/),
                          (v5/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "numChildren",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": "children(first:10,order:\"HOT\")"
              },
              {
                "alias": null,
                "args": (v14/*: any*/),
                "filters": [
                  "order"
                ],
                "handle": "connection",
                "key": "CommentCommentsFragment_children",
                "kind": "LinkedHandle",
                "name": "children"
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f4b85bad4292df6cf08bb006d5e31ff8",
    "id": null,
    "metadata": {},
    "name": "TopicCommentsNewCommentSubscription",
    "operationKind": "subscription",
    "text": "subscription TopicCommentsNewCommentSubscription(\n  $topicId: ID!\n) {\n  newComments(topicId: $topicId) {\n    hotness\n    node {\n      id\n      parent {\n        id\n      }\n      topic {\n        id\n      }\n      ...CommentFragment_70TG5\n    }\n  }\n}\n\nfragment CommentCommentsFragment_1jcI8F on Comment {\n  children(first: 10, order: HOT) {\n    edges {\n      node {\n        id\n        ...CommentFragment_4swzNU\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment CommentDeleteFragment on Comment {\n  id\n  author {\n    id\n  }\n  topic {\n    id\n  }\n  parent {\n    id\n  }\n}\n\nfragment CommentDisplayFragment on Comment {\n  viewerCanDelete: viewerCan(action: DELETE_COMMENT)\n  viewerCanUpdate: viewerCan(action: UPDATE_COMMENT)\n  id\n  content\n  edited\n  createdAt\n  topic {\n    id\n  }\n  parent {\n    id\n  }\n  ...CommentDeleteFragment\n  author {\n    id\n    username\n    ...EntityProfilePicFragment_49kWkb\n  }\n  ...VoteDisplayFragment\n}\n\nfragment CommentFragment_4swzNU on Comment {\n  id\n  ...CommentDisplayFragment\n  ...CommentReplyFragment\n  numChildren\n}\n\nfragment CommentFragment_70TG5 on Comment {\n  id\n  ...CommentDisplayFragment\n  ...CommentReplyFragment\n  ...CommentCommentsFragment_1jcI8F\n}\n\nfragment CommentReplyFragment on Comment {\n  id\n  viewerCanReply: viewerCan(action: CREATE_COMMENT)\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment VoteDisplayFragment on Votable {\n  __isVotable: __typename\n  id\n  votes\n  voted @ifAllowed {\n    score\n  }\n  viewerCanVote: viewerCan(action: PUBLISH_VOTE)\n}\n"
  }
};
})();

(node as any).hash = "25e9b6113d177bd0ba98e4cf6f87fec2";

export default node;
