/**
 * @generated SignedSource<<074f79d1f7a5186e5828fac2de0e693d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ActivityVisibility = "AUTHENTICATED" | "MEMBERS" | "UNAUTHENTICATED";
export type UpdateCompetitionInput = {
  banner?: any | null | undefined;
  description?: string | null | undefined;
  hasLeaderboard?: boolean | null | undefined;
  requiresApproval?: boolean | null | undefined;
  rules?: string | null | undefined;
  shortDescription?: string | null | undefined;
  slug?: string | null | undefined;
  tagIds?: ReadonlyArray<string | null | undefined> | null | undefined;
  thumbnail?: any | null | undefined;
  title?: string | null | undefined;
  visibility?: ActivityVisibility | null | undefined;
};
export type CompetitionRulesPageUpdateCompetitionMutation$variables = {
  id: string;
  input: UpdateCompetitionInput;
};
export type CompetitionRulesPageUpdateCompetitionMutation$data = {
  readonly updateCompetition: {
    readonly node: {
      readonly id: string;
      readonly latestRule: {
        readonly entityAgreement: {
          readonly createdAt: string;
          readonly id: string;
        } | null | undefined;
        readonly id: string;
        readonly text: string;
      };
    };
  };
};
export type CompetitionRulesPageUpdateCompetitionMutation = {
  response: CompetitionRulesPageUpdateCompetitionMutation$data;
  variables: CompetitionRulesPageUpdateCompetitionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CompetitionEdge",
    "kind": "LinkedField",
    "name": "updateCompetition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionRule",
            "kind": "LinkedField",
            "name": "latestRule",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetitionRuleAgreement",
                "kind": "LinkedField",
                "name": "entityAgreement",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionRulesPageUpdateCompetitionMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionRulesPageUpdateCompetitionMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "fd67f1e74ee08ba743b50f3e7563068b",
    "id": null,
    "metadata": {},
    "name": "CompetitionRulesPageUpdateCompetitionMutation",
    "operationKind": "mutation",
    "text": "mutation CompetitionRulesPageUpdateCompetitionMutation(\n  $id: ID!\n  $input: UpdateCompetitionInput!\n) {\n  updateCompetition(id: $id, input: $input) {\n    node {\n      id\n      latestRule {\n        id\n        text\n        entityAgreement {\n          id\n          createdAt\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9deb495f301bb4ffd8a4302a188cffd0";

export default node;
