import { Link } from "react-router-dom";
import EventTimetable from "../components/EventTimetable";
import { FormattedMessage, useIntl } from "react-intl";
import { useEventTimetablePageQuery } from "./loaders/EventTimetablePage";
import ErrorPage from "./ErrorPage";

export default function EventTimetablePage() {
  const {
    query: { eventBySlug },
  } = useEventTimetablePageQuery();
  const intl = useIntl();

  if (!eventBySlug) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Event not found",
        })}
      />
    );
  }
  return (
    <>
      <div className="flex flex-col gap-3">
        {eventBySlug.viewerCanUpdateAgenda && (
          <Link to={"edit"} relative="route" className="flex justify-end mb-3">
            <FormattedMessage defaultMessage="Edit" />
          </Link>
        )}
        {eventBySlug.agenda ? (
          <EventTimetable timetable={eventBySlug} />
        ) : (
          <p className="text-gray-400">
            <FormattedMessage defaultMessage="The agenda has not been defined." />
          </p>
        )}
      </div>
    </>
  );
}
