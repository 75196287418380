/**
 * @generated SignedSource<<3cf78b17d2c91142b2dd34d030f51d2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventRulesPageQuery$variables = {
  slug: string;
};
export type EventRulesPageQuery$data = {
  readonly eventBySlug: {
    readonly id: string;
    readonly latestRule: {
      readonly id: string;
      readonly text: string;
    };
    readonly title: string;
    readonly viewerCanAccept: boolean;
    readonly viewerCanUpdate: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"EventAcceptRulesButtonEventFragment" | "MemberSelectEventFragment">;
  } | null | undefined;
  readonly viewer: {
    readonly id: string;
  };
};
export type EventRulesPageQuery = {
  response: EventRulesPageQuery$data;
  variables: EventRulesPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v6 = {
  "alias": "viewerCanUpdate",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "UPDATE_EVENT"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_EVENT\")"
},
v7 = {
  "alias": "viewerCanAccept",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "CREATE_EVENT_RULE_AGREEMENT"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"CREATE_EVENT_RULE_AGREEMENT\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventRulesPageQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventBySlug",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EventRule",
            "kind": "LinkedField",
            "name": "latestRule",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventAcceptRulesButtonEventFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MemberSelectEventFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventRulesPageQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventBySlug",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EventRule",
            "kind": "LinkedField",
            "name": "latestRule",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EventRuleAgreement",
                "kind": "LinkedField",
                "name": "entityAgreement",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": "textareaMembers",
            "args": null,
            "concreteType": "EventMembershipConnection",
            "kind": "LinkedField",
            "name": "members",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventMembershipEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventMembership",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "entity",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v1/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isEntity"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "167b77900af52d34cad1f5182b546c46",
    "id": null,
    "metadata": {},
    "name": "EventRulesPageQuery",
    "operationKind": "query",
    "text": "query EventRulesPageQuery(\n  $slug: String!\n) {\n  viewer @ifAllowed {\n    id\n  }\n  eventBySlug(slug: $slug) {\n    id\n    title\n    latestRule {\n      id\n      text\n    }\n    viewerCanUpdate: viewerCan(action: UPDATE_EVENT)\n    viewerCanAccept: viewerCan(action: CREATE_EVENT_RULE_AGREEMENT)\n    ...EventAcceptRulesButtonEventFragment\n    ...MemberSelectEventFragment\n  }\n}\n\nfragment EventAcceptRulesButtonEventFragment on Event {\n  id\n  latestRule {\n    id\n    entityAgreement @ifAllowed {\n      id\n      createdAt\n    }\n  }\n}\n\nfragment MemberSelectEntityFragment on Entity {\n  __isEntity: __typename\n  id\n  username\n  displayName\n}\n\nfragment MemberSelectEventFragment on Event {\n  textareaMembers: members {\n    edges {\n      node {\n        entity {\n          __typename\n          id\n          ...MemberSelectEntityFragment\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "75e4b58ebc684bb5cf8c303d5fa6a267";

export default node;
