import React from "react";
import { graphql, usePaginationFragment } from "react-relay";
import { ForumTopicsFragment$key } from "./__generated__/ForumTopicsFragment.graphql";
import TopicListItem from "./TopicListItem";
import LoadMore from "./LoadMore";
import { VotableOrder } from "../utils/votableOrder";
import { useOnChangeEffect } from "../utils/hooks";

const ForumTopicsFragment = graphql`
  fragment ForumTopicsFragment on Forum
  @refetchable(queryName: "ForumTopicsFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
    order: { type: "VotableOrder", defaultValue: HOT }
  ) {
    topics(after: $cursor, first: $count, order: $order)
      @connection(key: "ForumTopicsFragment_topics") {
      edges {
        node {
          id
          ...TopicListItemFragment
        }
      }
    }
  }
`;

interface Props {
  forum: ForumTopicsFragment$key;
  order: VotableOrder;
}

export default function ForumTopics({ forum, order }: Props) {
  const {
    data: {
      topics: { edges },
    },
    loadNext,
    hasNext,
    isLoadingNext,
    refetch,
  } = usePaginationFragment(ForumTopicsFragment, forum);

  useOnChangeEffect(order, (order) =>
    refetch({ order }, { fetchPolicy: "store-and-network" }),
  );

  return (
    <div>
      {edges.map((edge, index) => (
        <React.Fragment key={edge.node.id}>
          <TopicListItem topic={edge.node} />
          {index < edges.length - 1 && <hr className="my-4" />}
        </React.Fragment>
      ))}

      {edges.length === 0 && <p className="text-gray-400">No topics</p>}

      <LoadMore
        loadMore={loadNext}
        hasMore={hasNext}
        isLoading={isLoadingNext}
      />
    </div>
  );
}
