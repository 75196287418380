import { useEffect } from "react";
import { graphql, usePaginationFragment } from "react-relay";
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedNumber, FormattedDate } from "react-intl";
import { useDebounced } from "../utils/hooks";
import EntityProfilePic from "./EntityProfilePic";
import LoadMore from "./LoadMore";
import { GlobalLeaderboardFragment$key } from "./__generated__/GlobalLeaderboardFragment.graphql";

const GlobalLeaderboardFragment = graphql`
  fragment GlobalLeaderboardFragment on Query
  @refetchable(queryName: "GlobalLeaderboardFragmentPaginationQuery")
  @argumentDefinitions(
    search: { type: "String" }
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    leaderboard(after: $cursor, first: $count, search: $search)
      @connection(key: "GlobalLeaderboardFragment_leaderboard") {
      edges {
        rank
        points
        node {
          id
          username
          createdAt
          ...EntityProfilePicFragment @arguments(thumbnail: true)
        }
      }
    }
  }
`;

interface Props {
  search: string;
  query: GlobalLeaderboardFragment$key;
}

export default function GlobalLeaderboard({ query, search }: Props) {
  const debouncedSearch = useDebounced(search, 500);
  const {
    data: { leaderboard },
    loadNext,
    hasNext,
    isLoadingNext,
    refetch,
  } = usePaginationFragment(GlobalLeaderboardFragment, query);
  useEffect(() => {
    if (debouncedSearch.trim() === "") {
      refetch({ search: null });
    } else {
      refetch({ search: debouncedSearch });
    }
  }, [debouncedSearch, refetch]);
  if (leaderboard.edges.length == 0) {
    return (
      <p className="text-gray-400">
        <FormattedMessage defaultMessage="No Submissions" />
      </p>
    );
  }
  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="py-3 px-3 bg-gray-100 text-left">
              <FormattedMessage defaultMessage="Rank" />
            </th>
            <th className="py-3 px-3 bg-gray-100 text-left">
              <FormattedMessage defaultMessage="Profile" />
            </th>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="User since" />
            </th>
            <th className="py-3 px-3 bg-gray-100 text-right">
              <FormattedMessage defaultMessage="Points" />
            </th>
          </tr>
        </thead>
        <tbody>
          {leaderboard.edges.map(({ node: entity, rank, points }) => {
            return (
              <tr key={entity.id}>
                <td className="whitespace-nowrap py-2 px-3 text-left">
                  <FormattedNumber value={rank} />
                </td>
                <td className="whitespace-nowrap py-2 px-3">
                  <Link to={`/${entity.username}`}>
                    <div className="flex flex-row items-center hover:cursor-pointer">
                      <div className="pr-4">
                        <EntityProfilePic entity={entity} size="12" />
                      </div>
                      <span>{entity.username}</span>
                    </div>
                  </Link>
                </td>
                <td className="whitespace-nowrap py-2 px-3 text-center">
                  <FormattedDate value={entity.createdAt} />
                </td>
                <td className="whitespace-nowrap py-2 px-3 text-right">
                  <FormattedNumber value={points} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <LoadMore
        loadMore={loadNext}
        hasMore={hasNext}
        isLoading={isLoadingNext}
      />
    </div>
  );
}
