import {
  DetailedHTMLProps,
  OptionHTMLAttributes,
  SelectHTMLAttributes,
  forwardRef,
} from "react";
import { graphql } from "relay-runtime";
import { ActivityVisibility } from "./__generated__/ActivityVisibilityInputFragment.graphql";
import { FormattedMessage } from "react-intl";
import { cn } from "../utils/tailwind";

graphql`
  fragment ActivityVisibilityInputFragment on Event {
    visibility
  }
`;

export { type ActivityVisibility };

export type ActivityVisibilityInputProps = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

export default forwardRef<HTMLSelectElement, ActivityVisibilityInputProps>(
  function ActivityVisibilityInput({ className, ...props }, ref) {
    return (
      <select
        ref={ref}
        {...props}
        className={cn(className, "px-3 py-1 border rounded bg-white italic")}
      >
        <Option value="UNAUTHENTICATED">
          <FormattedMessage defaultMessage="Public" />
        </Option>
        <Option value="AUTHENTICATED">
          <FormattedMessage defaultMessage="Connected users" />
        </Option>
        <Option value="MEMBERS">
          <FormattedMessage defaultMessage="Invited members only" />
        </Option>
      </select>
    );
  },
);

const Option = (
  props: Omit<
    DetailedHTMLProps<
      OptionHTMLAttributes<HTMLOptionElement>,
      HTMLOptionElement
    >,
    "value"
  > & { value: ActivityVisibility },
) => <option {...props} />;
