/**
 * @generated SignedSource<<a0d96d9687792b14140e2cf076b13425>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSettingIntegrationsFragment$data = {
  readonly id: string;
  readonly isAvailableOnQuantumJobs: boolean;
  readonly jobBoardProfileLink: any | null | undefined;
  readonly " $fragmentType": "UserSettingIntegrationsFragment";
};
export type UserSettingIntegrationsFragment$key = {
  readonly " $data"?: UserSettingIntegrationsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSettingIntegrationsFragment">;
};

import UserSettingIntegrationsRefetchFragment_graphql from './UserSettingIntegrationsRefetchFragment.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": UserSettingIntegrationsRefetchFragment_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "UserSettingIntegrationsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAvailableOnQuantumJobs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobBoardProfileLink",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "96827c2d8993955766f617732b6ad3c3";

export default node;
