import { useRef } from "react";
import { useCompetitionLeaderboardPageQuery } from "./loaders/CompetitionLeaderboardPage";
import CompetitionLeaderboard, {
  RefetchFn,
} from "../components/CompetitionLeaderboard";
import CompetitionEntitySubmissionStatus from "../components/CompetitionEntitySubmissionStatus";
import { useIntl, FormattedMessage } from "react-intl";
import ErrorPage from "./ErrorPage";
import Suspense from "../common/Suspense";

export default function CompetitionLeaderboardPage() {
  const intl = useIntl();
  const {
    query: { competitionBySlug: competition, viewer },
  } = useCompetitionLeaderboardPageQuery();
  const refetchRef = useRef<RefetchFn>();
  if (!competition) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Competition not found",
        })}
      />
    );
  }
  return (
    <>
      <h1 className="text-2xl font-bold w-full font-poppins">
        <FormattedMessage defaultMessage="Leaderboard" />
      </h1>
      <div className="py-4">
        {viewer && (
          <CompetitionEntitySubmissionStatus
            competition={competition}
            entity={viewer}
            refetchLeaderboard={refetchRef.current}
          />
        )}
      </div>
      <div className="pt-4 pb-8">
        <Suspense>
          <CompetitionLeaderboard
            competition={competition}
            registerRefresh={(refetchFn) => {
              refetchRef.current = refetchFn;
            }}
          />
        </Suspense>
      </div>
    </>
  );
}
