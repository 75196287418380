/**
 * @generated SignedSource<<1b5b3f5168cd5ee8cae468fcab90b4d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubjectKind = "FORUM" | "TOPIC";
export type SubjectSubscriptionButtonSubscribeMutation$variables = {
  subject: string;
};
export type SubjectSubscriptionButtonSubscribeMutation$data = {
  readonly subscribeToSubject: {
    readonly node: {
      readonly id: string;
      readonly kind: SubjectKind;
      readonly subject: {
        readonly " $fragmentSpreads": FragmentRefs<"SubjectSubscriptionButtonFragment">;
      };
    };
  };
};
export type SubjectSubscriptionButtonSubscribeMutation = {
  response: SubjectSubscriptionButtonSubscribeMutation$data;
  variables: SubjectSubscriptionButtonSubscribeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subject"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "subject",
    "variableName": "subject"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubjectSubscriptionButtonSubscribeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubjectSubscriptionEdge",
        "kind": "LinkedField",
        "name": "subscribeToSubject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "subject",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SubjectSubscriptionButtonFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubjectSubscriptionButtonSubscribeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubjectSubscriptionEdge",
        "kind": "LinkedField",
        "name": "subscribeToSubject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "subject",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isSubscribable"
                  },
                  (v2/*: any*/),
                  {
                    "alias": "viewerCanSubscribe",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "action",
                        "value": "CREATE_SUBJECT_SUBSCRIPTION"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "viewerCan",
                    "storageKey": "viewerCan(action:\"CREATE_SUBJECT_SUBSCRIPTION\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "entitySubscription",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": "viewerCanUnsubscribe",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "action",
                            "value": "DELETE_SUBJECT_SUBSCRIPTION"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "viewerCan",
                        "storageKey": "viewerCan(action:\"DELETE_SUBJECT_SUBSCRIPTION\")"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f651c9d4b721ae20edbb19befdf6b7f3",
    "id": null,
    "metadata": {},
    "name": "SubjectSubscriptionButtonSubscribeMutation",
    "operationKind": "mutation",
    "text": "mutation SubjectSubscriptionButtonSubscribeMutation(\n  $subject: ID!\n) {\n  subscribeToSubject(subject: $subject) {\n    node {\n      __typename\n      id\n      kind\n      subject {\n        __typename\n        ...SubjectSubscriptionButtonFragment\n        id\n      }\n    }\n  }\n}\n\nfragment SubjectSubscriptionButtonFragment on Subscribable {\n  __isSubscribable: __typename\n  id\n  viewerCanSubscribe: viewerCan(action: CREATE_SUBJECT_SUBSCRIPTION)\n  entitySubscription @ifAllowed {\n    __typename\n    id\n    kind\n    viewerCanUnsubscribe: viewerCan(action: DELETE_SUBJECT_SUBSCRIPTION)\n  }\n}\n"
  }
};
})();

(node as any).hash = "c2ece464a6b87cae5b951fda1df0fcd3";

export default node;
