/**
 * @generated SignedSource<<e5fab52ebfd29f2d80e3754d4ecd79b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EntityKind = "ORGANIZATION" | "USER";
import { FragmentRefs } from "relay-runtime";
export type EntityHelmetFragment$data = {
  readonly bio: string | null | undefined;
  readonly comments?: {
    readonly nodes: ReadonlyArray<{
      readonly content: string;
      readonly createdAt: string;
      readonly votes: number;
    }>;
  };
  readonly createdAt: string;
  readonly displayName: string;
  readonly github: string | null | undefined;
  readonly id: string;
  readonly image: any | null | undefined;
  readonly imageThumbnail: any | null | undefined;
  readonly jobTitle?: string | null | undefined;
  readonly kind: EntityKind;
  readonly linkedin: string | null | undefined;
  readonly location: string | null | undefined;
  readonly username: string;
  readonly website: string | null | undefined;
  readonly " $fragmentType": "EntityHelmetFragment";
};
export type EntityHelmetFragment$key = {
  readonly " $data"?: EntityHelmetFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EntityHelmetFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EntityHelmetFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkedin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "github",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageThumbnail",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "last",
              "value": 10
            }
          ],
          "concreteType": "CommentConnection",
          "kind": "LinkedField",
          "name": "comments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Comment",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "content",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "votes",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "comments(last:10)"
        }
      ],
      "type": "User",
      "abstractKey": null
    }
  ],
  "type": "Entity",
  "abstractKey": "__isEntity"
};
})();

(node as any).hash = "136801cd068460f315331eb9f568f081";

export default node;
