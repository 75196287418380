import { useForumsPageQuery } from "./loaders/ForumsPage";
import Forums from "../components/Forums";
import { MetaLayout } from "../common/MetaLayout";
import { useIntl } from "react-intl";

export default function ForumsPage() {
  const intl = useIntl();
  const { query } = useForumsPageQuery();
  return (
    <MetaLayout
      metaTitle={intl.formatMessage({
        defaultMessage: "Quantum Computing Discussions",
      })}
      metaDescription={intl.formatMessage({
        defaultMessage:
          "Join discussions on quantum computing at Aqora.io. Connect with professionals, share knowledge, and dive into key quantum computing topics.",
      })}
    >
      <div className="pb-10">
        {" "}
        <Forums query={query} />{" "}
      </div>
    </MetaLayout>
  );
}
