import { graphql, useMutation, ConnectionHandler } from "react-relay";
import { UploadableMap } from "relay-runtime";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  EventCreatePageMutation as EventCreatePageMutationType,
  EventCreatePageMutation$variables,
} from "./__generated__/EventCreatePageMutation.graphql";
import CompetitionEditForm from "../components/CompetitionEditForm";
import { logger } from "../common/logger";

const EventCreatePageMutation = graphql`
  mutation EventCreatePageMutation(
    $input: CreateEventInput!
    $connections: [ID!]!
  ) {
    createEvent(input: $input) @prependEdge(connections: $connections) {
      node {
        id
        title
        description
        slug
        banner
        thumbnail
        isPrivate
      }
    }
  }
`;

export default function EventCreatePage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [commitMutation, isMutationInFlight] =
    useMutation<EventCreatePageMutationType>(EventCreatePageMutation);
  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage={"Create Event"} />
      </h1>
      <CompetitionEditForm
        routePrefix="events"
        autoSlug={true}
        onSubmit={({ data, banner, thumbnail, setFormError }) => {
          const variables: EventCreatePageMutation$variables = {
            input: {
              title: data.title,
              shortDescription: data.shortDescription,
              slug: data.slug,
              visibility: data.visibility,
              banner: banner.variable,
              thumbnail: thumbnail.variable,
            },
            connections: [
              ConnectionHandler.getConnectionID(
                "root",
                "EventsFragment_events",
              ),
            ],
          };
          const uploadables: UploadableMap = {};
          if (banner.uploadable) {
            uploadables["variables.input.banner"] = banner.uploadable;
          }
          if (thumbnail.uploadable) {
            uploadables["variables.input.thumbnail"] = thumbnail.uploadable;
          }
          commitMutation({
            variables,
            uploadables,
            onError: (error) => {
              logger.error(error);
              setFormError(
                intl.formatMessage({
                  defaultMessage:
                    "Could not create competiton. The event URL may already be taken.",
                }),
              );
            },
            onCompleted: (results) => {
              navigate(`/events/${results.createEvent.node.slug}`);
            },
          });
        }}
        isDisabled={isMutationInFlight}
      />
    </div>
  );
}
