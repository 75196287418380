/**
 * @generated SignedSource<<db233a3a54be4d8f1045a29aa207529c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ForumScaffoldQuery$variables = {
  slug: string;
};
export type ForumScaffoldQuery$data = {
  readonly forumBySlug: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ForumFragment">;
  } | null | undefined;
};
export type ForumScaffoldQuery = {
  response: ForumScaffoldQuery$data;
  variables: ForumScaffoldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForumScaffoldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Forum",
        "kind": "LinkedField",
        "name": "forumBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ForumFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForumScaffoldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Forum",
        "kind": "LinkedField",
        "name": "forumBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "icon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "owner",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Literal",
                "name": "order",
                "value": "NEWEST"
              }
            ],
            "concreteType": "TopicConnection",
            "kind": "LinkedField",
            "name": "topics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Topic",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "topics(first:1,order:\"NEWEST\")"
          },
          {
            "alias": "viewerCanEdit",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "UPDATE_FORUM"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"UPDATE_FORUM\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e465ccca68dda3463090ad56bc449ecd",
    "id": null,
    "metadata": {},
    "name": "ForumScaffoldQuery",
    "operationKind": "query",
    "text": "query ForumScaffoldQuery(\n  $slug: String!\n) {\n  forumBySlug(slug: $slug) {\n    id\n    ...ForumFragment\n  }\n}\n\nfragment ForumFragment on Forum {\n  slug\n  title\n  shortDescription\n  icon\n  owner {\n    __typename\n    slug\n    id\n  }\n  topics(first: 1, order: NEWEST) {\n    nodes {\n      createdAt\n      author {\n        __typename\n        username\n        id\n      }\n      id\n    }\n  }\n  viewerCanEdit: viewerCan(action: UPDATE_FORUM)\n}\n"
  }
};
})();

(node as any).hash = "266149a292839e1ad2b6ac1ef1f44ba3";

export default node;
