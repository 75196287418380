import { useCompetitionsPageQuery } from "./loaders/CompetitionsPage";
import Competitions from "../components/Competitions";
import { MetaLayout } from "../common/MetaLayout";
import { useIntl } from "react-intl";

export default function CompetitionsPage() {
  const intl = useIntl();
  const { query } = useCompetitionsPageQuery();
  return (
    <MetaLayout
      metaTitle={intl.formatMessage({
        defaultMessage: "Quantum Computing Competitions on Aqora.io | Join now",
      })}
      metaDescription={intl.formatMessage({
        defaultMessage:
          "Participate in quantum computing competitions at Aqora.io. Demonstrate your skills, collaborate with experts, and push the boundaries of innovation.",
      })}
      hideTitleTemplate
    >
      <div className="pb-10">
        <Competitions query={query} />
      </div>
    </MetaLayout>
  );
}
