/**
 * @generated SignedSource<<a60a9a62f982a41fca99f5279e407d26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationMembershipKind = "ADMIN" | "EDITOR" | "OWNER" | "READER";
export type OrganizationUsersPageAddMutation$variables = {
  connections: ReadonlyArray<string>;
  kind: OrganizationMembershipKind;
  organizationId: string;
  userId: string;
};
export type OrganizationUsersPageAddMutation$data = {
  readonly updateOrganizationMembership: {
    readonly node: {
      readonly id: string;
      readonly kind: OrganizationMembershipKind;
      readonly organization: {
        readonly viewerCanEdit: boolean;
      };
      readonly user: {
        readonly displayName: string;
        readonly username: string;
        readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
      };
      readonly viewerCanDelete: boolean;
    };
  };
};
export type OrganizationUsersPageAddMutation = {
  response: OrganizationUsersPageAddMutation$data;
  variables: OrganizationUsersPageAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "kind"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "kind",
    "variableName": "kind"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v7 = {
  "alias": "viewerCanDelete",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "REMOVE_ORGANIZATION_MEMBER"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"REMOVE_ORGANIZATION_MEMBER\")"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v10 = {
  "alias": "viewerCanEdit",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "UPDATE_ORGANIZATION_MEMBERSHIP"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_ORGANIZATION_MEMBERSHIP\")"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationUsersPageAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "OrganizationMembershipEdge",
        "kind": "LinkedField",
        "name": "updateOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "thumbnail",
                        "value": true
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "EntityProfilePicFragment"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrganizationUsersPageAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "OrganizationMembershipEdge",
        "kind": "LinkedField",
        "name": "updateOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageThumbnail",
                        "storageKey": null
                      }
                    ],
                    "type": "Entity",
                    "abstractKey": "__isEntity"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": null,
        "handle": "prependEdge",
        "key": "",
        "kind": "LinkedHandle",
        "name": "updateOrganizationMembership",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "181e438d9e60500f07e815551ed6983d",
    "id": null,
    "metadata": {},
    "name": "OrganizationUsersPageAddMutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationUsersPageAddMutation(\n  $organizationId: ID!\n  $userId: ID!\n  $kind: OrganizationMembershipKind!\n) {\n  updateOrganizationMembership(organizationId: $organizationId, userId: $userId, kind: $kind) {\n    node {\n      id\n      kind\n      viewerCanDelete: viewerCan(action: REMOVE_ORGANIZATION_MEMBER)\n      user {\n        username\n        displayName\n        ...EntityProfilePicFragment_49kWkb\n        id\n      }\n      organization {\n        viewerCanEdit: viewerCan(action: UPDATE_ORGANIZATION_MEMBERSHIP)\n        id\n      }\n    }\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n"
  }
};
})();

(node as any).hash = "7c036c5eec6c4287da32cba815eb877e";

export default node;
