/**
 * @generated SignedSource<<0e97be2fa37cc2fb412cde645823771d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SubjectKind = "FORUM" | "TOPIC";
import { FragmentRefs } from "relay-runtime";
export type SubjectSubscriptionButtonFragment$data = {
  readonly entitySubscription: {
    readonly id: string;
    readonly kind: SubjectKind;
    readonly viewerCanUnsubscribe: boolean;
  } | null | undefined;
  readonly id: string;
  readonly viewerCanSubscribe: boolean;
  readonly " $fragmentType": "SubjectSubscriptionButtonFragment";
};
export type SubjectSubscriptionButtonFragment$key = {
  readonly " $data"?: SubjectSubscriptionButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubjectSubscriptionButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubjectSubscriptionButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "viewerCanSubscribe",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "CREATE_SUBJECT_SUBSCRIPTION"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"CREATE_SUBJECT_SUBSCRIPTION\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "entitySubscription",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": "viewerCanUnsubscribe",
          "args": [
            {
              "kind": "Literal",
              "name": "action",
              "value": "DELETE_SUBJECT_SUBSCRIPTION"
            }
          ],
          "kind": "ScalarField",
          "name": "viewerCan",
          "storageKey": "viewerCan(action:\"DELETE_SUBJECT_SUBSCRIPTION\")"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Subscribable",
  "abstractKey": "__isSubscribable"
};
})();

(node as any).hash = "9eb9f4940043b86115a8bca5e1c2d14c";

export default node;
