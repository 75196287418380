/**
 * @generated SignedSource<<8107dd947bed1ca80f1c778714cfc46a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventTopicCreatePageQuery$variables = {
  slug: string;
};
export type EventTopicCreatePageQuery$data = {
  readonly eventBySlug: {
    readonly forum: {
      readonly id: string;
    };
    readonly id: string;
    readonly slug: string;
  } | null | undefined;
};
export type EventTopicCreatePageQuery = {
  response: EventTopicCreatePageQuery$data;
  variables: EventTopicCreatePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "Event",
    "kind": "LinkedField",
    "name": "eventBySlug",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Forum",
        "kind": "LinkedField",
        "name": "forum",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventTopicCreatePageQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventTopicCreatePageQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9f813a1a0febbed0251b40047310e3c8",
    "id": null,
    "metadata": {},
    "name": "EventTopicCreatePageQuery",
    "operationKind": "query",
    "text": "query EventTopicCreatePageQuery(\n  $slug: String!\n) {\n  eventBySlug(slug: $slug) {\n    id\n    forum {\n      id\n    }\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "2424123f35f9d37994f6a87b9eb0c833";

export default node;
