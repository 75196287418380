import { useCompetitionTopicsPageQuery } from "./loaders/CompetitionTopicsPage";
import TopicsPage from "./common/TopicsPage";
import { useIntl } from "react-intl";
import ErrorPage from "./ErrorPage";
import ForumHelmet from "../components/ForumHelmet";

export default function CompetitionTopicsPage() {
  const {
    query,
    variables: { order: initialOrder },
  } = useCompetitionTopicsPageQuery();
  const forum = query?.competitionBySlug?.forum;
  const intl = useIntl();
  if (!forum) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Competition not found",
        })}
      />
    );
  }
  return (
    <ForumHelmet forum={forum}>
      <TopicsPage forum={forum} initialOrder={initialOrder} />
    </ForumHelmet>
  );
}
