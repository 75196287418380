import { graphql, useFragment, useMutation } from "react-relay";
import { FormattedMessage, useIntl, IntlShape } from "react-intl";
import { MdNotifications, MdNotificationsActive } from "react-icons/md";
import {
  SubjectSubscriptionButtonFragment$key,
  SubjectKind,
} from "./__generated__/SubjectSubscriptionButtonFragment.graphql";
import { SubjectSubscriptionButtonSubscribeMutation as SubjectSubscriptionButtonSubscribeMutationType } from "./__generated__/SubjectSubscriptionButtonSubscribeMutation.graphql";
import { SubjectSubscriptionButtonUnsubscribeMutation as SubjectSubscriptionButtonUnsubscribeMutationType } from "./__generated__/SubjectSubscriptionButtonUnsubscribeMutation.graphql";
import { toast } from "sonner";
import Button from "./Button";
import { cn } from "../utils/tailwind";
import { useAuth } from "../utils/auth";

const SubjectSubscriptionButtonFragment = graphql`
  fragment SubjectSubscriptionButtonFragment on Subscribable {
    id
    viewerCanSubscribe: viewerCan(action: CREATE_SUBJECT_SUBSCRIPTION)
    entitySubscription @ifAllowed {
      id
      kind
      viewerCanUnsubscribe: viewerCan(action: DELETE_SUBJECT_SUBSCRIPTION)
    }
  }
`;

const SubjectSubscriptionButtonSubscribeMutation = graphql`
  mutation SubjectSubscriptionButtonSubscribeMutation($subject: ID!) {
    subscribeToSubject(subject: $subject) {
      node {
        id
        kind
        subject {
          ...SubjectSubscriptionButtonFragment
        }
      }
    }
  }
`;

const SubjectSubscriptionButtonUnsubscribeMutation = graphql`
  mutation SubjectSubscriptionButtonUnsubscribeMutation($subscription: ID!) {
    unsubscribeFromSubject(subscription: $subscription)
  }
`;

const formatSubjectKind = (intl: IntlShape, kind: SubjectKind) => {
  switch (kind) {
    case "FORUM":
      return intl.formatMessage({ defaultMessage: "discussion" });
    case "TOPIC":
      return intl.formatMessage({ defaultMessage: "topic" });
  }
};

interface Props {
  subject: SubjectSubscriptionButtonFragment$key;
  collapsed?: boolean;
}

export default function SubjectSubscriptionButton({
  subject: subjectFragment,
  collapsed = false,
}: Props) {
  const intl = useIntl();
  const { userId } = useAuth();
  const subject = useFragment(
    SubjectSubscriptionButtonFragment,
    subjectFragment,
  );
  const isSubscribed = !!subject.entitySubscription;
  const [commitSubscribe, isSubscribeInFlight] =
    useMutation<SubjectSubscriptionButtonSubscribeMutationType>(
      SubjectSubscriptionButtonSubscribeMutation,
    );
  const [commitUnsubscribe, isUnubscribeInFlight] =
    useMutation<SubjectSubscriptionButtonUnsubscribeMutationType>(
      SubjectSubscriptionButtonUnsubscribeMutation,
    );
  const unsubscribe = () => {
    if (!subject.entitySubscription) {
      return;
    }
    const id = subject.entitySubscription.id;
    const kind = subject.entitySubscription.kind;
    commitUnsubscribe({
      variables: { subscription: id },
      updater: (store) => {
        store.delete(id);
      },
      onCompleted: () => {
        toast.success(
          intl.formatMessage(
            {
              defaultMessage: "Unsubscribed from {subject}",
            },
            { subject: formatSubjectKind(intl, kind) },
          ),
        );
      },
      onError: (error) => {
        toast.error(
          intl.formatMessage(
            {
              defaultMessage: "Could not unsubscribe: {error}",
            },
            { error: error.toString() },
          ),
        );
      },
    });
  };

  const subscribe = () => {
    if (subject.entitySubscription) {
      return;
    }
    commitSubscribe({
      variables: { subject: subject.id },
      onCompleted: ({
        subscribeToSubject: {
          node: { kind },
        },
      }) => {
        toast.success(
          intl.formatMessage(
            {
              defaultMessage: "Subscribed to {subject}",
            },
            { subject: formatSubjectKind(intl, kind) },
          ),
        );
      },
      onError: (error) => {
        toast.error(
          intl.formatMessage(
            {
              defaultMessage: "Could not subscribe: {error}",
            },
            { error: error.toString() },
          ),
        );
      },
    });
  };

  const action =
    isSubscribed && subject.entitySubscription.viewerCanUnsubscribe
      ? unsubscribe
      : subject.viewerCanSubscribe
        ? subscribe
        : undefined;

  if (!userId) {
    return null;
  }

  return (
    <Button
      onClick={action}
      kind={isSubscribed ? "primary" : "secondary"}
      disabled={isSubscribeInFlight || isUnubscribeInFlight}
    >
      <span className={cn("inline-block", collapsed ? "hidden" : "pr-2")}>
        {isSubscribed ? (
          <FormattedMessage defaultMessage="Subscribed" />
        ) : (
          <FormattedMessage defaultMessage="Subscribe" />
        )}
      </span>
      {isSubscribed ? (
        <MdNotificationsActive className="inline-block" />
      ) : (
        <MdNotifications className="inline-block" />
      )}
    </Button>
  );
}
