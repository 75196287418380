import { graphql, useFragment } from "react-relay";
import { Link } from "react-router-dom";
import { TopicListItemFragment$key } from "./__generated__/TopicListItemFragment.graphql";
import { FormattedMessage, useIntl } from "react-intl";
import EntityProfilePic from "./EntityProfilePic";
import VoteDisplay from "./VoteDisplay";
import { getTopicPath } from "../utils/routing";
import { TimeAgo } from "./TimeAgo";
import { MdOutlineComment } from "react-icons/md";
import { MdOpenInNew } from "react-icons/md";
import Note from "./Note";

const TopicListItemFragment = graphql`
  fragment TopicListItemFragment on Topic {
    id
    title
    url
    createdAt
    commentCount
    forum {
      slug
      owner {
        __typename
        slug
      }
    }
    author {
      username
      ...EntityProfilePicFragment @arguments(thumbnail: true)
    }
    comments(first: 1, order: NEWEST) {
      nodes {
        createdAt
        author {
          username
          displayName
        }
      }
    }
    ...VoteDisplayFragment
  }
`;

interface Props {
  topic: TopicListItemFragment$key;
}

export default function TopicListItem({ topic: topicFragment }: Props) {
  const intl = useIntl();
  const topic = useFragment(TopicListItemFragment, topicFragment);
  const [comment] = topic.comments.nodes;
  const link = getTopicPath(topic);
  return (
    <article className="flex flex-row space-x-4 sm:items-center items-start">
      <EntityProfilePic entity={topic.author} size="12" />

      <div className="flex-1 flex justify-between sm:items-center items-start sm:flex-row sm:gap-0 gap-6 flex-col">
        <Link
          to={topic.url ?? link}
          target={topic.url ? "_blank" : undefined}
          className="block flex-1"
        >
          <div className="flex-1 flex flex-wrap items-center gap-1">
            <p className="text-l font-bold">{topic.title}</p>
            {topic.url && (
              <Note icon={<MdOpenInNew />} className="text-xs">
                <FormattedMessage defaultMessage="External link" />
              </Note>
            )}
          </div>

          <p className="text-sm text-slate-600">
            <FormattedMessage
              defaultMessage="By {authorName} • {timeAgo}{hasComment, select, true { • Last comment {commentCreatedAt} by {commentAuthor}} other {}}"
              values={{
                authorName: (
                  <Link
                    to={`/${topic.author.username}`}
                    className="underline underline-offset-1"
                    aria-label={intl.formatMessage(
                      { defaultMessage: "View profile of {authorName}" },
                      { authorName: topic.author.username },
                    )}
                  >
                    {topic.author.username}
                  </Link>
                ),
                timeAgo: <TimeAgo createdAt={new Date(topic.createdAt)} />,
                hasComment: comment ? "true" : "false",
                commentAuthor: comment?.author.username,
                commentCreatedAt: (
                  <TimeAgo createdAt={new Date(comment?.createdAt)} />
                ),
              }}
            />
          </p>
        </Link>

        <div className="flex sm:flex-row flex-row-reverse sm:justify-end justify-between items-center gap-6">
          <Link to={link} rel="canonical">
            <div className="flex items-center gap-2">
              {/* TODO: Use Igor's icon, and activity indicator */}
              <MdOutlineComment size={ICON_SIZE} />
              <p className="text-base text-slate-600 hidden sm:block">
                <FormattedMessage
                  defaultMessage="{commentCount, plural, =1 {# comment} other {# comments}}"
                  values={{
                    commentCount: topic.commentCount,
                  }}
                />
              </p>
              <p className="text-base text-slate-600 block sm:hidden">
                {topic.commentCount}
              </p>
            </div>
          </Link>

          <VoteDisplay subject={topic} kind="horizontal" />
        </div>
      </div>
    </article>
  );
}

const ICON_SIZE = 24;
