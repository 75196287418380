/**
 * @generated SignedSource<<0f5226a4195d90a7a366570128ae019a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ForumsFragmentPaginationQuery$variables = {
  count?: number | null | undefined;
  cursor?: string | null | undefined;
};
export type ForumsFragmentPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ForumsFragment">;
};
export type ForumsFragmentPaginationQuery = {
  response: ForumsFragmentPaginationQuery$data;
  variables: ForumsFragmentPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 100,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForumsFragmentPaginationQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          }
        ],
        "kind": "FragmentSpread",
        "name": "ForumsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForumsFragmentPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "canCreateForum",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "CREATE_FORUM"
              }
            ],
            "kind": "ScalarField",
            "name": "can",
            "storageKey": "can(action:\"CREATE_FORUM\")"
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ForumConnection",
        "kind": "LinkedField",
        "name": "forums",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ForumEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Forum",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "icon",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "owner",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                      },
                      {
                        "kind": "Literal",
                        "name": "order",
                        "value": "NEWEST"
                      }
                    ],
                    "concreteType": "TopicConnection",
                    "kind": "LinkedField",
                    "name": "topics",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Topic",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "topics(first:1,order:\"NEWEST\")"
                  },
                  {
                    "alias": "viewerCanEdit",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "action",
                        "value": "UPDATE_FORUM"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "viewerCan",
                    "storageKey": "viewerCan(action:\"UPDATE_FORUM\")"
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": null,
        "handle": "connection",
        "key": "ForumsFragment_forums",
        "kind": "LinkedHandle",
        "name": "forums"
      }
    ]
  },
  "params": {
    "cacheID": "520d2727e4679b7071d39cfa2f1f94ad",
    "id": null,
    "metadata": {},
    "name": "ForumsFragmentPaginationQuery",
    "operationKind": "query",
    "text": "query ForumsFragmentPaginationQuery(\n  $count: Int = 100\n  $cursor: String\n) {\n  ...ForumsFragment_1G22uz\n}\n\nfragment ForumFragment on Forum {\n  slug\n  title\n  shortDescription\n  icon\n  owner {\n    __typename\n    slug\n    id\n  }\n  topics(first: 1, order: NEWEST) {\n    nodes {\n      createdAt\n      author {\n        __typename\n        username\n        id\n      }\n      id\n    }\n  }\n  viewerCanEdit: viewerCan(action: UPDATE_FORUM)\n}\n\nfragment ForumsFragment_1G22uz on Query {\n  viewer @ifAllowed {\n    canCreateForum: can(action: CREATE_FORUM)\n    id\n  }\n  forums(after: $cursor, first: $count) {\n    edges {\n      node {\n        id\n        ...ForumFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f948e651298fe135e890fd38e446d9a3";

export default node;
