/**
 * @generated SignedSource<<ae61e19ae5cfd4778483c09541729723>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventAcceptRulesButtonMutation$variables = {
  asEntity?: string | null | undefined;
  event: string;
};
export type EventAcceptRulesButtonMutation$data = {
  readonly agreeToEventRule: {
    readonly createdAt: string;
    readonly eventRule: {
      readonly event: {
        readonly id: string;
        readonly latestRule: {
          readonly entityAgreement: {
            readonly createdAt: string;
            readonly id: string;
          } | null | undefined;
          readonly id: string;
        };
      };
    };
    readonly id: string;
  };
};
export type EventAcceptRulesButtonMutation = {
  response: EventAcceptRulesButtonMutation$data;
  variables: EventAcceptRulesButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "asEntity"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "event"
},
v2 = [
  {
    "kind": "Variable",
    "name": "asEntity",
    "variableName": "asEntity"
  },
  {
    "kind": "Variable",
    "name": "event",
    "variableName": "event"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Event",
  "kind": "LinkedField",
  "name": "event",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EventRule",
      "kind": "LinkedField",
      "name": "latestRule",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EventRuleAgreement",
          "kind": "LinkedField",
          "name": "entityAgreement",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventAcceptRulesButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "EventRuleAgreement",
        "kind": "LinkedField",
        "name": "agreeToEventRule",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EventRule",
            "kind": "LinkedField",
            "name": "eventRule",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EventAcceptRulesButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "EventRuleAgreement",
        "kind": "LinkedField",
        "name": "agreeToEventRule",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EventRule",
            "kind": "LinkedField",
            "name": "eventRule",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "09d0caad9d00c1e39dcb4c9a73a2e7ee",
    "id": null,
    "metadata": {},
    "name": "EventAcceptRulesButtonMutation",
    "operationKind": "mutation",
    "text": "mutation EventAcceptRulesButtonMutation(\n  $event: ID!\n  $asEntity: UsernameOrID\n) {\n  agreeToEventRule(event: $event, asEntity: $asEntity) {\n    id\n    createdAt\n    eventRule {\n      event {\n        id\n        latestRule {\n          id\n          entityAgreement {\n            id\n            createdAt\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d64bdfa3886f330379e78b1b3f11f46c";

export default node;
