import { Helmet } from "react-helmet-async";
import { graphql, useFragment } from "react-relay";
import { LocationContextValue, useLocation } from "../utils/location";
import { getForumPath, getTopicPath } from "../utils/routing";
import {
  ForumHelmetFragment$data,
  ForumHelmetFragment$key,
} from "./__generated__/ForumHelmetFragment.graphql";
import { PropsWithChildren } from "react";
import { addIfDefined } from "../utils/helpers";
import _ from "lodash";

const Fragment = graphql`
  fragment ForumHelmetFragment on Forum {
    id
    title
    slug
    owner {
      __typename
      slug
    }
    shortDescription
    createdAt
    forumTopics: topics(last: 10, order: HOT) {
      nodes {
        id
        title
        description
        createdAt
        author {
          displayName
          username
        }
      }
    }
  }
`;

export interface ForumHelmetProps extends PropsWithChildren {
  forum: ForumHelmetFragment$key;
}

export default function ForumHelmet({
  forum: forumFragment,
  children,
}: ForumHelmetProps) {
  const forum = useFragment(Fragment, forumFragment);
  const location = useLocation();

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(formatWebPageSchema(forum, location))}
        </script>
      </Helmet>
      {children}
    </>
  );
}

function formatWebPageSchema(
  forum: ForumHelmetFragment$data,
  location: LocationContextValue,
) {
  const forumUrl = new URL(getForumPath(forum), location.origin);
  const firstTopic = forum.forumTopics.nodes[0] ?? undefined;
  const topicUrl =
    firstTopic &&
    new URL(
      getTopicPath({ id: firstTopic.id, title: firstTopic.title, forum }),
      location.origin,
    );

  const breadcrumbList = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: _.trim(getForumPath(forum), "/"),
        item: forumUrl,
      },
      {
        "@type": "ListItem",
        position: 2,
        ...addIfDefined(
          "name",
          !firstTopic
            ? null
            : _.trimStart(
                _.trimStart(
                  getTopicPath({
                    id: firstTopic.id,
                    title: firstTopic.title,
                    forum,
                  }),
                  getForumPath(forum),
                ),
                "/",
              ),
        ),
        ...addIfDefined("item", topicUrl),
      },
    ],
  };

  const discussionItems = forum.forumTopics.nodes.map((topic) => {
    const topicUrl = new URL(
      getTopicPath({ ...topic, forum }),
      location.origin,
    ).toString();

    return {
      "@type": "DiscussionForumPosting",
      headline: topic.title,
      url: topicUrl,
      author: {
        "@type": "Person",
        name: topic.author.displayName,
        url: new URL(`/${topic.author.username}`, location.origin).toString(),
      },
      dateCreated: topic.createdAt,
    };
  });

  return {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: forum.title,
    description: forum.shortDescription,
    url: forumUrl,
    discussionUrl: forumUrl,
    breadcrumb: breadcrumbList,
    mainEntity: {
      "@type": "ItemList",
      itemListElement: discussionItems.map((item, index) => ({
        "@type": "ListItem",
        position: index + 1,
        item: item,
      })),
    },
  };
}
