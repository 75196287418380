import { OperationType } from "relay-runtime";
import {
  IndexRouteObject as BaseIndexRouteObject,
  NonIndexRouteObject as BaseNonIndexRouteObject,
  Params,
} from "react-router-dom";
import { PreloadedData, LoadedData } from "./relay";
import { MessageDescriptor } from "react-intl";

export interface LoaderArgs {
  params: Params;
  request: Request;
}

export type Preloader = (args: LoaderArgs) => PreloadedData<OperationType>;
export type Loader = (args: LoaderArgs) => Promise<LoadedData<OperationType>>;

export interface RouteHandle {
  titleI18n: MessageDescriptor;
}

export interface IndexRouteObject<Loader>
  extends Omit<BaseIndexRouteObject, "loader"> {
  loader?: Loader;
}

export interface NonIndexRouteObject<Loader>
  extends Omit<BaseNonIndexRouteObject, "loader" | "children"> {
  loader?: Loader;
  children?: RouteObject<Loader>[];
}

export type RouteObject<Loader> =
  | IndexRouteObject<Loader>
  | NonIndexRouteObject<Loader>;

export const isRouteHandle = (handle: unknown): handle is RouteHandle =>
  typeof handle === "object" && handle !== null && "titleI18n" in handle;
