/**
 * @generated SignedSource<<d3055f095578378a1119762f0123a3f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubmissionStatusBadgeSubscription$variables = {
  id: string;
};
export type SubmissionStatusBadgeSubscription$data = {
  readonly projectVersionStatusUpdate: {
    readonly " $fragmentSpreads": FragmentRefs<"SubmissionStatusBadgeFragment">;
  };
};
export type SubmissionStatusBadgeSubscription = {
  response: SubmissionStatusBadgeSubscription$data;
  variables: SubmissionStatusBadgeSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "projectVersionId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubmissionStatusBadgeSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectVersion",
        "kind": "LinkedField",
        "name": "projectVersionStatusUpdate",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SubmissionStatusBadgeFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubmissionStatusBadgeSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectVersion",
        "kind": "LinkedField",
        "name": "projectVersionStatusUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "version",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectVersionEvaluation",
            "kind": "LinkedField",
            "name": "evaluation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "score",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "error",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "finalizedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "max",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Competition",
                "kind": "LinkedField",
                "name": "competition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasLeaderboard",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e20d873ef52c593bed479d059825d0d2",
    "id": null,
    "metadata": {},
    "name": "SubmissionStatusBadgeSubscription",
    "operationKind": "subscription",
    "text": "subscription SubmissionStatusBadgeSubscription(\n  $id: ID!\n) {\n  projectVersionStatusUpdate(projectVersionId: $id) {\n    ...SubmissionStatusBadgeFragment\n    id\n  }\n}\n\nfragment SubmissionStatusBadgeFragment on ProjectVersion {\n  id\n  status\n  version\n  evaluation {\n    score\n    error\n    finalizedAt\n    max\n    id\n  }\n  project {\n    __typename\n    competition {\n      hasLeaderboard\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "64a462e6382b05331dbf4793fcabd5a5";

export default node;
