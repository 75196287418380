import { useIntl, defineMessages, IntlShape } from "react-intl";
import { useLocation, useMatches } from "react-router-dom";
import { RouteHandle, isRouteHandle } from "./route";
import { EntityKind } from "../pages/loaders/__generated__/EntityScaffoldQuery.graphql";

const metaTagMessages = defineMessages({
  profileTitle: {
    defaultMessage: "Meet {name}",
  },
  profileTitleWithJobTitle: {
    defaultMessage: "Meet {name}, {job}",
  },
  profileDescription: {
    defaultMessage:
      "Meet {name}. Learn more about {namePossessive} contributions to the quantum computing field.",
  },
  organizationTitle: {
    defaultMessage: "Explore {namePossessive} Activities in Quantum Computing",
  },
  organizationDescription: {
    defaultMessage:
      "Explore how {name} is leveraging quantum computing to drive innovation and solve industry challenges. Learn more about {namePossessive} key projects, collaborations, and contributions to the quantum ecosystem.",
  },
  subpageTitle: {
    defaultMessage: "{namePossessive} {subpage} | Aqora.io",
  },
  subpageDescription: {
    defaultMessage:
      "Explore {namePossessive} {subpage}, and contributions to the field of quantum computing at Aqora.io.",
  },
  eventsPageTitle: {
    defaultMessage: "Quantum Computing Events on Aqora.io | Join now",
  },
  eventsPageDescription: {
    defaultMessage:
      "Participate in quantum computing hackathons at Aqora.io. Demonstrate your skills, collaborate with experts, and push the boundaries of innovation.",
  },
  eventTitle: {
    defaultMessage: "{event} | Aqora.io",
  },
  eventDescription: {
    defaultMessage:
      "Join the {event} on Aqora.io. Designed for quantum computing professionals and enthusiasts.",
  },
  eventSubpageTitle: {
    defaultMessage: "{event} on Aqora.io | {subpage}",
  },
  eventSubpageDescription: {
    defaultMessage:
      "{event}: Checkout the {subpage} on Aqora.io. Designed for quantum computing professionals and enthusiasts.",
  },
  competitionTitle: {
    defaultMessage: "{competition}: Online Quantum Competition | Aqora.io",
  },
  competitionDescription: {
    defaultMessage:
      "{competition}: Join the online quantum competition on Aqora.io. Designed for quantum computing professionals and enthusiasts.",
  },
  competitionSubpageTitle: {
    defaultMessage: "{competition} on Aqora.io | {subpage}",
  },
  competitionSubpageDescription: {
    defaultMessage:
      "{competition}: Checkout the {subpage} on Aqora.io. Designed for quantum computing professionals and enthusiasts.",
  },
});

export interface MetaTags {
  metaTitle: string;
  metaDescription: string;
}

interface BaseMetaTags {
  intl: IntlShape;
  matchedRoute?: RouteHandle;
  subpage: string;
  pathname: string;
}

const getPathSegments = (pathname: string, depth: number): string[] =>
  pathname.split("/").filter(Boolean).slice(0, depth);

const getNamePossessive = (name: string): string =>
  `${name}${name.endsWith("s") ? "'" : "'s"}`;

function useBaseMetaTags(): BaseMetaTags {
  const intl = useIntl();
  const matches = useMatches();
  const { pathname } = useLocation();

  const matchedRoute = matches
    .slice()
    .reverse()
    .find((match) => isRouteHandle(match.handle))?.handle as
    | RouteHandle
    | undefined;

  const subpage = matchedRoute
    ? intl.formatMessage(matchedRoute.titleI18n).toLowerCase()
    : "";

  return { intl, matchedRoute, subpage, pathname };
}

export function useProfileMetaTags(
  fullname: string,
  kind: EntityKind,
  job?: string | null,
): MetaTags {
  const { intl, subpage, pathname } = useBaseMetaTags();
  const pathSegments = getPathSegments(pathname, 3);
  const isSubpage = !!pathSegments[1];
  const namePossessive = getNamePossessive(fullname);

  const messageValues = {
    name: fullname,
    job,
    namePossessive,
    subpage,
  };

  let metaTags: MetaTags;

  if (isSubpage && !!subpage) {
    metaTags = {
      metaTitle: intl.formatMessage(
        metaTagMessages.subpageTitle,
        messageValues,
      ),
      metaDescription: intl.formatMessage(
        metaTagMessages.subpageDescription,
        messageValues,
      ),
    };
  } else if (kind === "USER" && !!job) {
    metaTags = {
      metaTitle: intl.formatMessage(
        metaTagMessages.profileTitleWithJobTitle,
        messageValues,
      ),
      metaDescription: intl.formatMessage(
        metaTagMessages.profileDescription,
        messageValues,
      ),
    };
  } else if (kind === "ORGANIZATION") {
    metaTags = {
      metaTitle: intl.formatMessage(
        metaTagMessages.organizationTitle,
        messageValues,
      ),
      metaDescription: intl.formatMessage(
        metaTagMessages.organizationDescription,
        messageValues,
      ),
    };
  } else {
    metaTags = {
      metaTitle: intl.formatMessage(
        metaTagMessages.profileTitle,
        messageValues,
      ),
      metaDescription: intl.formatMessage(
        metaTagMessages.profileDescription,
        messageValues,
      ),
    };
  }

  return metaTags;
}

export function useEventsMetaTags(title?: string): MetaTags {
  const { intl, subpage, pathname } = useBaseMetaTags();
  const pathSegments = getPathSegments(pathname, 3);
  const isSubpage = !!pathSegments[2];

  if (!title) {
    return {
      metaTitle: metaTagMessages.eventsPageTitle.defaultMessage,
      metaDescription: metaTagMessages.eventsPageDescription.defaultMessage,
    };
  }

  const metaTitle = isSubpage
    ? intl.formatMessage(metaTagMessages.eventSubpageTitle, {
        event: title,
        subpage,
      })
    : intl.formatMessage(metaTagMessages.eventTitle, {
        event: title,
      });

  const metaDescription = isSubpage
    ? intl.formatMessage(metaTagMessages.eventSubpageDescription, {
        event: title,
        subpage,
      })
    : intl.formatMessage(metaTagMessages.eventDescription, {
        event: title,
      });

  return { metaTitle, metaDescription };
}

export function useCompetitionMetaTags(title?: string): MetaTags {
  const { intl, subpage, pathname } = useBaseMetaTags();
  const pathSegments = getPathSegments(pathname, 3);
  const isSubpage = !!pathSegments[2];

  if (!title) {
    return {
      metaTitle: "",
      metaDescription: "",
    };
  }

  const metaTitle = isSubpage
    ? intl.formatMessage(metaTagMessages.competitionSubpageTitle, {
        competition: title,
        subpage,
      })
    : intl.formatMessage(metaTagMessages.competitionTitle, {
        competition: title,
      });

  const metaDescription = isSubpage
    ? intl.formatMessage(metaTagMessages.competitionSubpageDescription, {
        competition: title,
        subpage,
      })
    : intl.formatMessage(metaTagMessages.competitionDescription, {
        competition: title,
      });

  return { metaTitle, metaDescription };
}
