/**
 * @generated SignedSource<<7bcb165574cadf691cbb7857e4437f57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EntityActivitiesConnectionKind = "COMMENT" | "SUBMISSION" | "TOPIC";
export type EntityOverviewPageQuery$variables = {
  activity_date: string;
  activity_kinds?: ReadonlyArray<EntityActivitiesConnectionKind> | null | undefined;
  username: string;
};
export type EntityOverviewPageQuery$data = {
  readonly entityByUsername: {
    readonly bio: string | null | undefined;
    readonly id: string;
    readonly viewerCanReadActivity?: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"EntityBadgeListFragment" | "EntityHelmetFragment" | "UserActivityFragment">;
  } | null | undefined;
};
export type EntityOverviewPageQuery = {
  response: EntityOverviewPageQuery$data;
  variables: EntityOverviewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "activity_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "activity_kinds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v3 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bio",
  "storageKey": null
},
v6 = {
  "alias": "viewerCanReadActivity",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "READ_ACTIVITY_TRACKER"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"READ_ACTIVITY_TRACKER\")"
},
v7 = {
  "kind": "Variable",
  "name": "kinds",
  "variableName": "activity_kinds"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EntityOverviewPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EntityBadgeListFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EntityHelmetFragment"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "date",
                    "variableName": "activity_date"
                  },
                  (v7/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "UserActivityFragment"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EntityOverviewPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isEntity"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": "viewerCanAward",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "AWARD_BADGE"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"AWARD_BADGE\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EntityBadgeConnection",
            "kind": "LinkedField",
            "name": "badges",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EntityBadgeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EntityBadge",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "badge",
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "connection",
            "key": "EntityBadgeList_entity_badges",
            "kind": "LinkedHandle",
            "name": "badges"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "linkedin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "github",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "website",
            "storageKey": null
          },
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageThumbnail",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "last",
                    "value": 10
                  }
                ],
                "concreteType": "CommentConnection",
                "kind": "LinkedField",
                "name": "comments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "content",
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "votes",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "comments(last:10)"
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "after",
                    "variableName": "activity_date"
                  },
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 366
                  },
                  (v7/*: any*/)
                ],
                "concreteType": "ActivityConnection",
                "kind": "LinkedField",
                "name": "activities",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Activity",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "date",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "level",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "points",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5fcbd2dba07472774b09b9592b62755a",
    "id": null,
    "metadata": {},
    "name": "EntityOverviewPageQuery",
    "operationKind": "query",
    "text": "query EntityOverviewPageQuery(\n  $username: String!\n  $activity_kinds: [EntityActivitiesConnectionKind!]\n  $activity_date: String!\n) {\n  entityByUsername(username: $username) {\n    __typename\n    id\n    bio\n    ...EntityBadgeListFragment\n    ...EntityHelmetFragment\n    ... on User {\n      viewerCanReadActivity: viewerCan(action: READ_ACTIVITY_TRACKER)\n      ...UserActivityFragment_3h4ozr\n    }\n  }\n}\n\nfragment EntityBadgeFragment on EntityBadge {\n  badge\n}\n\nfragment EntityBadgeListFragment on Entity {\n  __isEntity: __typename\n  username\n  id\n  viewerCanAward: viewerCan(action: AWARD_BADGE)\n  badges {\n    edges {\n      node {\n        id\n        badge\n        ...EntityBadgeFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EntityHelmetFragment on Entity {\n  __isEntity: __typename\n  id\n  kind\n  displayName\n  location\n  username\n  linkedin\n  github\n  website\n  bio\n  createdAt\n  image\n  imageThumbnail\n  ... on User {\n    jobTitle\n    comments(last: 10) {\n      nodes {\n        content\n        createdAt\n        votes\n        id\n      }\n    }\n  }\n}\n\nfragment UserActivityCalendarFragment_3h4ozr on User {\n  activities(first: 366, after: $activity_date, kinds: $activity_kinds) {\n    edges {\n      node {\n        date\n        level\n        points\n      }\n    }\n  }\n  id\n}\n\nfragment UserActivityFragment_3h4ozr on User {\n  createdAt\n  ...UserActivityCalendarFragment_3h4ozr\n}\n"
  }
};
})();

(node as any).hash = "82d8a34735f85b24a08f3f6b4b65b131";

export default node;
