import { useMemo, cloneElement, useState, useEffect } from "react";
import { graphql } from "relay-runtime";
import { useFragment, useRefetchableFragment } from "react-relay";
import ActivityCalendar, {
  ThemeInput,
  Activity,
} from "react-activity-calendar";
import { FormattedMessage, useIntl } from "react-intl";
import Suspense from "../common/Suspense";
import Button from "./Button";
import {
  UserActivityCalendarFragment$data,
  UserActivityCalendarFragment$key,
} from "./__generated__/UserActivityCalendarFragment.graphql";

import { EntityActivitiesConnectionKind } from "./__generated__/UserActivityCalendarFragmentQuery.graphql";
import { UserActivityFragment$key } from "./__generated__/UserActivityFragment.graphql";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  ACTIVITY_KINDS,
  formatActivityKind,
  useSetSearchActivityFilters,
} from "../utils/activityTracker";

const MILLISECONDS_IN_A_DAY = 86_400_000;
const GAP_BETWEEN_DATES = 2;

const UserActivityFragment = graphql`
  fragment UserActivityFragment on User
  @argumentDefinitions(
    kinds: { type: "[EntityActivitiesConnectionKind!]" }
    date: { type: "String!" }
  ) {
    createdAt
    ...UserActivityCalendarFragment @arguments(kinds: $kinds, date: $date)
  }
`;

const UserActivityCalendarFragment = graphql`
  fragment UserActivityCalendarFragment on User
  @refetchable(queryName: "UserActivityCalendarFragmentQuery")
  @argumentDefinitions(
    kinds: { type: "[EntityActivitiesConnectionKind!]" }
    date: { type: "String!" }
    first: { type: "Int", defaultValue: 366 }
  ) {
    activities(first: $first, after: $date, kinds: $kinds) {
      edges {
        node {
          date
          level
          points
        }
      }
    }
  }
`;

const explicitTheme: ThemeInput = {
  light: ["#eceafc", "#a99cf3", "#6953ea", "#3d24d0", "#25167e"],
  dark: ["#000000", "#101828", "#4328E5", "#182230", "#25167E"],
};

export interface Props {
  user: UserActivityFragment$key;
  date: string;
  kind?: EntityActivitiesConnectionKind;
}

export function UserActivity({
  user: userFragment,
  date: initialDate,
  kind: initialKind,
}: Props) {
  const [year, setYear] = useState(
    new Date(initialDate).getFullYear().toString(),
  );
  const [kind, setKind] = useState(initialKind);
  const user = useFragment(UserActivityFragment, userFragment);

  return (
    <div className="flex items-start gap-6 w-full">
      <div className="p-6 border border-grey rounded-md w-full bg-white">
        <Suspense>
          <Calendar user={user} year={year} kind={kind} />
        </Suspense>
        <ActivityKindFilter kind={kind} setKind={setKind} />
      </div>
      <ActivityYearFilter
        beginning={user.createdAt}
        year={year}
        setYear={setYear}
      />
    </div>
  );
}

interface ActivityYearFilterProps {
  beginning: string;
  year: string;
  setYear: (date: string) => void;
}

function ActivityYearFilter({
  beginning,
  year: activeYear,
  setYear,
}: ActivityYearFilterProps) {
  return (
    <div className="flex flex-col gap-3">
      {generateYears(
        new Date(beginning).getFullYear(),
        new Date().getFullYear(),
      ).map((year) => (
        <Button
          key={year}
          kind={activeYear == year.toString() ? "primary" : "secondary"}
          onClick={() => {
            setYear(year.toString());
          }}
        >
          {year}
        </Button>
      ))}
    </div>
  );
}

interface ActivityKindFilterProps {
  kind?: EntityActivitiesConnectionKind;
  setKind: (kind?: EntityActivitiesConnectionKind) => void;
}

function ActivityKindFilter({
  kind: activeKind,
  setKind,
}: ActivityKindFilterProps) {
  const intl = useIntl();
  return (
    <div className="flex gap-3 mt-8">
      {ACTIVITY_KINDS.map((kind) => (
        <Button
          key={kind}
          kind={activeKind == kind ? "primary" : "secondary"}
          size="sm"
          onClick={() => setKind(kind)}
        >
          {formatActivityKind(intl, kind)}
        </Button>
      ))}
      <Button
        kind={activeKind == undefined ? "primary" : "secondary"}
        size="sm"
        onClick={() => setKind()}
      >
        {formatActivityKind(intl)}
      </Button>
    </div>
  );
}

function generateYears(start: number, end: number): number[] {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i).reverse();
}

const generateDataArray = (
  year: string,
  activityEdges: UserActivityCalendarFragment$data["activities"]["edges"],
): Activity[] => {
  const startDate = new Date(year);
  const currentDate = new Date();

  const isCurrentYear = startDate.getFullYear() === currentDate.getFullYear();

  const endDate = isCurrentYear
    ? new Date(
        Math.min(
          currentDate.getTime() + GAP_BETWEEN_DATES * MILLISECONDS_IN_A_DAY,
          Date.now() + GAP_BETWEEN_DATES * MILLISECONDS_IN_A_DAY,
        ),
      )
    : new Date(startDate.getFullYear(), 11, 31);

  const activityMap = new Map(
    activityEdges.map(({ node }) => [
      node.date,
      { level: node.level, count: node.points, date: node.date },
    ]),
  );

  return Array.from(
    generateDateStrings(startDate, endDate),
    (date) => activityMap.get(date) || { level: 0, count: 0, date },
  );
};

function* generateDateStrings(start: Date, end: Date): Generator<string> {
  for (let d = new Date(start); d <= end; ) {
    yield d.toISOString().split("T")[0];
    d = new Date(d.getTime() + MILLISECONDS_IN_A_DAY);
  }
}

interface CalendarProps {
  user: UserActivityCalendarFragment$key;
  year: string;
  kind?: EntityActivitiesConnectionKind;
}

function Calendar({ user: userFragment, year, kind }: CalendarProps) {
  const intl = useIntl();
  const setSearchActivityFilters = useSetSearchActivityFilters();
  const [user, refetch] = useRefetchableFragment(
    UserActivityCalendarFragment,
    userFragment,
  );
  const data = useMemo(
    () => generateDataArray(year, user.activities.edges),
    [year, user.activities.edges],
  );

  useEffect(() => {
    refetch({ date: `${year}-01-01`, kinds: kind ? [kind] : undefined });
    setSearchActivityFilters({ year, kind });
  }, [year, kind, refetch, setSearchActivityFilters]);

  if (user.activities.edges.length === 0) {
    return (
      <div className="container mx-auto h-full flex justify-center items-center px-4 py-4">
        <div className="w-full text-center">
          <p className="text-gray-500 italic">
            <FormattedMessage defaultMessage="No activity yet!" />
          </p>
        </div>
      </div>
    );
  }
  return (
    <>
      <ActivityCalendar
        data={data}
        theme={explicitTheme}
        colorScheme="light"
        weekStart={1}
        maxLevel={4}
        labels={{ totalCount: "{{count}} points" }}
        renderBlock={(block, activity) =>
          cloneElement(block, {
            "data-tooltip-id": "react-tooltip",
            "data-tooltip-html": intl.formatMessage(
              {
                defaultMessage:
                  "{count, plural, one {# point} other {# points}} on {date}",
              },
              {
                count: activity.count,
                date: intl.formatDate(activity.date, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }),
              },
            ),
          })
        }
      />
      <ReactTooltip id="react-tooltip" />
    </>
  );
}
