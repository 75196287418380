import { formatSlug } from "./format";

export type PathSegment = string;
export type Path = PathSegment[];

export function formatPath(...args: Path): string {
  return args.join("/");
}

export function formatAbsolutePath(...args: Path): string {
  return "/" + formatPath(...args);
}

export interface ForumOwnerShape {
  __typename: string;
  slug: string;
}

export interface ForumShape {
  slug: string;
  owner: ForumOwnerShape;
}

export interface TopicShape {
  id: ID;
  title: string;
  forum: ForumShape;
}

export function getForumPath({ slug, owner }: ForumShape, ...rest: Path) {
  switch (owner.__typename) {
    case "Forum":
      return formatAbsolutePath("discussions", slug, ...rest);
    case "Competition":
      return formatAbsolutePath(
        "competitions",
        owner.slug,
        "discussions",
        ...rest,
      );
    case "Event":
      return formatAbsolutePath("events", owner.slug, "discussions", ...rest);
    default:
      throw new Error(`invalid forum type: ${owner.__typename}`);
  }
}

export function getTopicPath({ id, title, forum }: TopicShape, ...rest: Path) {
  if (rest.length) {
    return getForumPath(forum, id, ...rest);
  }
  return getForumPath(forum, id, formatSlug(title));
}
