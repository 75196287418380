import { graphql, useFragment } from "react-relay";
import { cn } from "../utils/tailwind";
import ProfilePic from "./ProfilePic";
import { EntityProfilePicFragment$key } from "./__generated__/EntityProfilePicFragment.graphql";

const EntityProfilePicFragment = graphql`
  fragment EntityProfilePicFragment on Entity
  @argumentDefinitions(thumbnail: { type: "Boolean!" }) {
    displayName
    imageThumbnail @include(if: $thumbnail)
    image @skip(if: $thumbnail)
  }
`;

type AvaiableSizeType = Record<string, string>;

const availablesSizes: AvaiableSizeType = {
  "6": "w-6",
  "8": "w-8",
  "10": "w-10",
  "12": "w-12",
  "16": "w-16",
  "20": "w-20",
  "22": "w-22",
  "28": "w-28",
  "32": "w-32",
} as const satisfies AvaiableSizeType;

type AvailableSizesKeys = keyof typeof availablesSizes;

interface Props {
  entity: EntityProfilePicFragment$key;
  className?: string;
  size?: AvailableSizesKeys;
}

export default function EntityProfilePic({
  entity,
  className,
  size: sizeKey,
}: Readonly<Props>) {
  const data = useFragment(EntityProfilePicFragment, entity);
  return (
    <div
      className={cn(
        "shrink-0",
        sizeKey ? `${availablesSizes[sizeKey]}` : "w-full",
      )}
    >
      <ProfilePic
        src={data.image || data.imageThumbnail}
        alt={data.displayName}
        className={className}
      />
    </div>
  );
}
