import React, { useState } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { CommentDisplayFragment$key } from "./__generated__/CommentDisplayFragment.graphql";
import { FormattedMessage, useIntl } from "react-intl";
import EntityProfilePic from "./EntityProfilePic";
import UserMarkdown from "./UserMarkdown";
import DeleteComment from "./CommentDelete";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import CommentEditForm from "./CommentEditForm";
import VoteDisplay from "./VoteDisplay";
import CopyLink from "./CopyLink";
import { useLocation } from "../utils/location";
import { cn } from "../utils/tailwind";
import { TimeAgo } from "./TimeAgo";

const CommentDisplayFragment = graphql`
  fragment CommentDisplayFragment on Comment {
    viewerCanDelete: viewerCan(action: DELETE_COMMENT)
    viewerCanUpdate: viewerCan(action: UPDATE_COMMENT)
    id
    content
    edited
    createdAt
    topic {
      id
    }
    parent {
      id
    }
    ...CommentDeleteFragment
    author {
      id
      username
      ...EntityProfilePicFragment @arguments(thumbnail: true)
    }
    ...VoteDisplayFragment
  }
`;

const CommentDisplayUpdateMutation = graphql`
  mutation CommentDisplayUpdateMutation($id: ID!, $content: String!) {
    updateComment(id: $id, input: { content: $content }) {
      node {
        id
        content
        edited
      }
    }
  }
`;

interface Props {
  comment: CommentDisplayFragment$key;
  highlighted?: boolean;
  children?: React.ReactNode;
}

export default function CommentDisplay({
  comment: commentFragment,
  highlighted,
  children,
}: Props) {
  const comment = useFragment(CommentDisplayFragment, commentFragment);
  const [isEditing, setIsEditing] = useState(false);
  const [commitMutation, isMutationInFlight] = useMutation(
    CommentDisplayUpdateMutation,
  );
  const location = useLocation();
  const intl = useIntl();
  const commentPermalink = new URL(
    `/comments/${comment.id}`,
    location.origin,
  ).toString();
  return (
    <div className="flex flex-row space-x-4 items-start">
      <div className="flex flex-col gap-4 items-center">
        <EntityProfilePic entity={comment.author} size="10" />
        <VoteDisplay subject={comment} kind="vertical" />
      </div>
      <div className="flex-grow space-y-1">
        <div className={cn(highlighted && "px-2 py-1 rounded bg-sky-50")}>
          <div className="flex flex-row justify-between items-center">
            <p className="text-gray-400 text-sm">
              <FormattedMessage
                defaultMessage="Posted by {authorName} • {timeAgo}{edited, select, true { (edited)} other {}}"
                values={{
                  authorName: (
                    <Link
                      to={`/${comment.author.username}`}
                      className="underline underline-offset-1"
                      aria-label={intl.formatMessage(
                        { defaultMessage: "View profile of {authorName}" },
                        { authorName: comment.author.username },
                      )}
                    >
                      {comment.author.username}
                    </Link>
                  ),
                  timeAgo: <TimeAgo createdAt={new Date(comment.createdAt)} />,
                  edited: comment.edited ? "true" : "false",
                }}
              />
            </p>
            <div className="flex flex-row space-x-2 hover:cursor-pointer">
              <CopyLink to={commentPermalink} />
              {comment.viewerCanUpdate && !isEditing && (
                <div onClick={() => setIsEditing(true)}>
                  <MdEdit />
                </div>
              )}
              {comment.viewerCanDelete && <DeleteComment comment={comment} />}
            </div>
          </div>
          {isEditing ? (
            <CommentEditForm
              isDisabled={isMutationInFlight}
              onCancel={() => setIsEditing(false)}
              content={comment.content}
              onSubmit={({ content, setFormError, resetForm }) => {
                commitMutation({
                  variables: {
                    id: comment.id,
                    content,
                  },
                  onError: (error) => {
                    setFormError(error.message);
                  },
                  onCompleted: () => {
                    setIsEditing(false);
                    resetForm();
                  },
                });
              }}
            />
          ) : (
            <UserMarkdown>{comment.content}</UserMarkdown>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}
