import { IntlShape } from "react-intl";
import { getParamFromRequest, parseEnumParam } from "./enum";
import { createContext } from "react";

export type VotableOrder = "HOT" | "NEWEST" | "OLDEST";

export const DEFAULT_VOTABLE_ORDER: VotableOrder = "HOT";
export const VOTABLE_ORDERS: ReadonlyArray<VotableOrder> = [
  "HOT",
  "NEWEST",
  "OLDEST",
];

export const TopicVotableOrderContext = createContext<VotableOrder | null>(
  null,
);
export const CommentVotableOrderContext = createContext<VotableOrder | null>(
  null,
);

export const parseVotableOrder = (order: string): VotableOrder | undefined => {
  const orderUppercase = order.toUpperCase() as VotableOrder;
  if (VOTABLE_ORDERS.includes(orderUppercase)) {
    return orderUppercase;
  }
  return undefined;
};

export const getVotableOrderParam = (
  request: Request,
): VotableOrder | undefined => {
  const orderParam = getParamFromRequest(request, "order");
  return orderParam ? parseEnumParam(orderParam, VOTABLE_ORDERS) : undefined;
};

export const formatVotableOrder = (intl: IntlShape, order: VotableOrder) => {
  switch (order) {
    case "HOT":
      return intl.formatMessage({ defaultMessage: "Hot" });
    case "NEWEST":
      return intl.formatMessage({ defaultMessage: "Newest" });
    case "OLDEST":
      return intl.formatMessage({ defaultMessage: "Oldest" });
  }
};
