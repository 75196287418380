import { FormattedMessage } from "react-intl";
import { graphql } from "relay-runtime";
import Button from "./Button";
import { useMutation } from "react-relay";
import { LogoutButtonMutation as LogoutButtonMutationType } from "./__generated__/LogoutButtonMutation.graphql";
import { useLocation } from "../utils/location";
import { logger } from "../common/logger";

const REDIRECT_LANDING = import.meta.env.VITE_REDIRECT_LANDING;

const LogoutButtonMutation = graphql`
  mutation LogoutButtonMutation {
    logoutUser
  }
`;

export default function LogoutButton() {
  const location = useLocation();
  const [commitMutation, isMutationInFlight] =
    useMutation<LogoutButtonMutationType>(LogoutButtonMutation);
  const onSubmit = () => {
    commitMutation({
      variables: {},
      onError(err) {
        logger.error(err);
      },
      onCompleted() {
        location.assign(REDIRECT_LANDING ? REDIRECT_LANDING : "/");
      },
    });
  };
  return (
    <Button
      kind="secondary"
      onClick={onSubmit}
      disabled={isMutationInFlight}
      className="w-full"
    >
      <FormattedMessage defaultMessage="Log out" />
    </Button>
  );
}
