/**
 * @generated SignedSource<<07163acb74b68e8f9d34982ba7bdd84b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionMembersPageQuery$variables = {
  slug: string;
};
export type CompetitionMembersPageQuery$data = {
  readonly competitionBySlug: {
    readonly id: string;
    readonly title: string;
    readonly viewerCanEditMembers: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"CompetitionMembersFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UserAutocompleteFragment">;
};
export type CompetitionMembersPageQuery = {
  response: CompetitionMembersPageQuery$data;
  variables: CompetitionMembersPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "viewerCanEditMembers",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "ADD_COMPETITION_MEMBER"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"ADD_COMPETITION_MEMBER\")"
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionMembersPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserAutocompleteFragment"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompetitionMembersFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionMembersPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "EntityConnection",
        "kind": "LinkedField",
        "name": "entities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EntityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "entities(first:10)"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": "viewerCanEdit",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "UPDATE_COMPETITION"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"UPDATE_COMPETITION\")"
          },
          {
            "alias": "viewerCanTransferOwnership",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "TRANSFER_COMPETITION_OWNERSHIP"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"TRANSFER_COMPETITION_OWNERSHIP\")"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "CompetitionMembershipConnection",
            "kind": "LinkedField",
            "name": "members",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetitionMembershipEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompetitionMembership",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": "viewerCanDelete",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "action",
                            "value": "REMOVE_COMPETITION_MEMBER"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "viewerCan",
                        "storageKey": "viewerCan(action:\"REMOVE_COMPETITION_MEMBER\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "entity",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v3/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isEntity"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageThumbnail",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "members(first:10)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "CompetitionMembersFragment_members",
            "kind": "LinkedHandle",
            "name": "members"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a5b05f3b14599e7b39cca380fd3cc8f",
    "id": null,
    "metadata": {},
    "name": "CompetitionMembersPageQuery",
    "operationKind": "query",
    "text": "query CompetitionMembersPageQuery(\n  $slug: String!\n) {\n  ...UserAutocompleteFragment\n  competitionBySlug(slug: $slug) {\n    title\n    id\n    viewerCanEditMembers: viewerCan(action: ADD_COMPETITION_MEMBER)\n    ...CompetitionMembersFragment\n  }\n}\n\nfragment CompetitionMembersFragment on Competition {\n  id\n  viewerCanEdit: viewerCan(action: UPDATE_COMPETITION)\n  viewerCanEditMembers: viewerCan(action: ADD_COMPETITION_MEMBER)\n  viewerCanTransferOwnership: viewerCan(action: TRANSFER_COMPETITION_OWNERSHIP)\n  members(first: 10) {\n    edges {\n      node {\n        id\n        kind\n        viewerCanDelete: viewerCan(action: REMOVE_COMPETITION_MEMBER)\n        entity {\n          __typename\n          id\n          username\n          displayName\n          ...EntityProfilePicFragment_49kWkb\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment UserAutocompleteFragment on Query {\n  entities(first: 10) {\n    edges {\n      node {\n        __typename\n        id\n        kind\n        username\n        displayName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff14995ca0d64af2386bcea6e7033089";

export default node;
