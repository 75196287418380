import { useEventsPageQuery } from "./loaders/EventsPage";
import Events from "../components/Events";
import { useIntl } from "react-intl";
import { MetaLayout } from "../common/MetaLayout";

export default function EventsPage() {
  const intl = useIntl();
  const { query } = useEventsPageQuery();
  return (
    <MetaLayout
      metaTitle={intl.formatMessage({
        defaultMessage: "Quantum Computing Events on Aqora.io | Join now",
      })}
      metaDescription={intl.formatMessage({
        defaultMessage:
          "Participate in quantum computing hackathons at Aqora.io. Demonstrate your skills, collaborate with experts, and push the boundaries of innovation.",
      })}
      hideTitleTemplate
    >
      <div className="pb-10">
        <Events query={query} />
      </div>
    </MetaLayout>
  );
}
