import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { EntityOverviewPageQuery as EntityOverviewPageQueryType } from "./__generated__/EntityOverviewPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";
import { getActivityFiltersParams } from "../../utils/activityTracker";

export type { EntityOverviewPageQuery as EntityOverviewPageQueryType } from "./__generated__/EntityOverviewPageQuery.graphql";

export const EntityOverviewPageQuery = graphql`
  query EntityOverviewPageQuery(
    $username: String!
    $activity_kinds: [EntityActivitiesConnectionKind!]
    $activity_date: String!
  ) {
    entityByUsername(username: $username) {
      id
      bio
      ...EntityBadgeListFragment
      ...EntityHelmetFragment
      ... on User {
        viewerCanReadActivity: viewerCan(action: READ_ACTIVITY_TRACKER)
        ...UserActivityFragment
          @arguments(kinds: $activity_kinds, date: $activity_date)
      }
    }
  }
`;

export const entityOverviewPageQueryLoader =
  (environment: Environment) =>
  ({ params: { username }, request }: LoaderArgs) => {
    if (!username) {
      throw new Response("No username", { status: 500 });
    }
    const { year, kind } = getActivityFiltersParams(request) || {};
    return preload<EntityOverviewPageQueryType>(
      environment,
      EntityOverviewPageQuery,
      {
        username,
        activity_kinds: kind ? [kind] : undefined,
        activity_date: `${year}-01-01`,
      },
    );
  };

export const useEntityOverviewPageQuery = () =>
  usePreloaded<EntityOverviewPageQueryType>();
