/**
 * @generated SignedSource<<954694aaddb52456d1bec7e9d44fd7ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicCommentsUpdatedCommentSubscription$variables = {
  topicId: string;
};
export type TopicCommentsUpdatedCommentSubscription$data = {
  readonly updatedComments: {
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"CommentDisplayFragment">;
    };
  };
};
export type TopicCommentsUpdatedCommentSubscription = {
  response: TopicCommentsUpdatedCommentSubscription$data;
  variables: TopicCommentsUpdatedCommentSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "topicId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "topicId",
    "variableName": "topicId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TopicCommentsUpdatedCommentSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CommentEdge",
        "kind": "LinkedField",
        "name": "updatedComments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Comment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CommentDisplayFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TopicCommentsUpdatedCommentSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CommentEdge",
        "kind": "LinkedField",
        "name": "updatedComments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Comment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "viewerCanDelete",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "DELETE_COMMENT"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"DELETE_COMMENT\")"
              },
              {
                "alias": "viewerCanUpdate",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "UPDATE_COMMENT"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"UPDATE_COMMENT\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "edited",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Topic",
                "kind": "LinkedField",
                "name": "topic",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Comment",
                "kind": "LinkedField",
                "name": "parent",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageThumbnail",
                        "storageKey": null
                      }
                    ],
                    "type": "Entity",
                    "abstractKey": "__isEntity"
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "votes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EntityVote",
                    "kind": "LinkedField",
                    "name": "voted",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "score",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "viewerCanVote",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "action",
                        "value": "PUBLISH_VOTE"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "viewerCan",
                    "storageKey": "viewerCan(action:\"PUBLISH_VOTE\")"
                  }
                ],
                "type": "Votable",
                "abstractKey": "__isVotable"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "81955827b96d1fa26a8aa1c866e7e167",
    "id": null,
    "metadata": {},
    "name": "TopicCommentsUpdatedCommentSubscription",
    "operationKind": "subscription",
    "text": "subscription TopicCommentsUpdatedCommentSubscription(\n  $topicId: ID!\n) {\n  updatedComments(topicId: $topicId) {\n    node {\n      id\n      ...CommentDisplayFragment\n    }\n  }\n}\n\nfragment CommentDeleteFragment on Comment {\n  id\n  author {\n    id\n  }\n  topic {\n    id\n  }\n  parent {\n    id\n  }\n}\n\nfragment CommentDisplayFragment on Comment {\n  viewerCanDelete: viewerCan(action: DELETE_COMMENT)\n  viewerCanUpdate: viewerCan(action: UPDATE_COMMENT)\n  id\n  content\n  edited\n  createdAt\n  topic {\n    id\n  }\n  parent {\n    id\n  }\n  ...CommentDeleteFragment\n  author {\n    id\n    username\n    ...EntityProfilePicFragment_49kWkb\n  }\n  ...VoteDisplayFragment\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment VoteDisplayFragment on Votable {\n  __isVotable: __typename\n  id\n  votes\n  voted @ifAllowed {\n    score\n  }\n  viewerCanVote: viewerCan(action: PUBLISH_VOTE)\n}\n"
  }
};
})();

(node as any).hash = "3a17131756282f8422c10ba5b4f131db";

export default node;
