import { PropsWithChildren, ReactNode } from "react";

export interface Props extends PropsWithChildren {
  title: string | ReactNode;
  description: string | ReactNode;
}

export function OptionToggleCard({
  title,
  description,
  children,
}: Readonly<Props>) {
  return (
    <div className="flex rounded-xl px-6 py-4 items-center border border-transparent bg-gray-50 hover:border-slate-200 hover:bg-slate-50 hover:text-slate-600">
      <div className="flex flex-col flex-1 gap-1">
        <span className="first-letter:uppercase font-bold">{title}</span>
        <span className="first-letter:uppercase text-gray-500 text-sm">
          {description}
        </span>
      </div>
      {children}
    </div>
  );
}
