/**
 * @generated SignedSource<<466af3d2349796cc0ac26bb0fa1eb112>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationUsersPageQuery$variables = {
  username: string;
};
export type OrganizationUsersPageQuery$data = {
  readonly entityByUsername: {
    readonly id: string;
    readonly viewerCanEdit?: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationUsersFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UserAutocompleteFragment">;
};
export type OrganizationUsersPageQuery = {
  response: OrganizationUsersPageQuery$data;
  variables: OrganizationUsersPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = {
  "kind": "Literal",
  "name": "kinds",
  "value": [
    "USER"
  ]
},
v2 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "viewerCanEdit",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "UPDATE_ORGANIZATION_MEMBERSHIP"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_ORGANIZATION_MEMBERSHIP\")"
},
v5 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v10 = [
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationUsersPageQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "UserAutocompleteFragment"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrganizationUsersFragment"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationUsersPageQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v5/*: any*/),
          (v1/*: any*/)
        ],
        "concreteType": "EntityConnection",
        "kind": "LinkedField",
        "name": "entities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EntityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "entities(first:10,kinds:[\"USER\"])"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": "viewerCanTransferOwnership",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "TRANSFER_ORGANIZATION_OWNERSHIP"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"TRANSFER_ORGANIZATION_OWNERSHIP\")"
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "OrganizationUserConnection",
                "kind": "LinkedField",
                "name": "users",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": "viewerCanDelete",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "action",
                                "value": "REMOVE_ORGANIZATION_MEMBER"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "viewerCan",
                            "storageKey": "viewerCan(action:\"REMOVE_ORGANIZATION_MEMBER\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "imageThumbnail",
                                    "storageKey": null
                                  }
                                ],
                                "type": "Entity",
                                "abstractKey": "__isEntity"
                              }
                            ],
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "users(first:10)"
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "OrganizationUsersFragment_users",
                "kind": "LinkedHandle",
                "name": "users"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cacdf15ee1f3c3a948c3b30169cdb835",
    "id": null,
    "metadata": {},
    "name": "OrganizationUsersPageQuery",
    "operationKind": "query",
    "text": "query OrganizationUsersPageQuery(\n  $username: String!\n) {\n  ...UserAutocompleteFragment_4ddT5H\n  entityByUsername(username: $username) {\n    __typename\n    id\n    ... on Organization {\n      viewerCanEdit: viewerCan(action: UPDATE_ORGANIZATION_MEMBERSHIP)\n      ...OrganizationUsersFragment\n    }\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment OrganizationUsersFragment on Organization {\n  id\n  viewerCanEdit: viewerCan(action: UPDATE_ORGANIZATION_MEMBERSHIP)\n  viewerCanTransferOwnership: viewerCan(action: TRANSFER_ORGANIZATION_OWNERSHIP)\n  users(first: 10) {\n    edges {\n      node {\n        id\n        kind\n        viewerCanDelete: viewerCan(action: REMOVE_ORGANIZATION_MEMBER)\n        user {\n          id\n          username\n          displayName\n          ...EntityProfilePicFragment_49kWkb\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment UserAutocompleteFragment_4ddT5H on Query {\n  entities(first: 10, kinds: [USER]) {\n    edges {\n      node {\n        __typename\n        id\n        kind\n        username\n        displayName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "66aa196b4376e14f9394c428533cdf2b";

export default node;
