import Outlet from "../common/Outlet";
import { useScaffoldQuery } from "./loaders/Scaffold";
import Header from "../components/Header";
import { ScrollRestoration } from "react-router-dom";

export default function Scaffold() {
  const { query } = useScaffoldQuery();
  return (
    <div className="bg-background min-h-full">
      <Header query={query} />
      <div className="container mx-auto max-w-screen-lg space-y-6 flex flex-col px-4">
        <Outlet />
        <ScrollRestoration />
      </div>
    </div>
  );
}
