/**
 * @generated SignedSource<<6fb8a340bdefaedc13aa8b7c7c5c3f64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventJoinButtonMutation$variables = {
  agree: boolean;
  eventId: string;
  forumId: string;
  join: boolean;
  subscribe: boolean;
};
export type EventJoinButtonMutation$data = {
  readonly agreeToEventRule?: {
    readonly createdAt: string;
    readonly eventRule: {
      readonly event: {
        readonly entityRuleAgreements: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
          }>;
        };
        readonly id: string;
        readonly latestRule: {
          readonly entityAgreement: {
            readonly createdAt: string;
            readonly id: string;
          } | null | undefined;
          readonly id: string;
        };
      };
    };
    readonly id: string;
  };
  readonly joinEvent?: {
    readonly node: {
      readonly event: {
        readonly membership: {
          readonly id: string;
        } | null | undefined;
      };
      readonly id: string;
    };
  };
  readonly subscribeToSubject?: {
    readonly node: {
      readonly subject: {
        readonly entitySubscription: {
          readonly id: string;
        } | null | undefined;
        readonly id: string;
      };
    };
  };
};
export type EventJoinButtonMutation = {
  response: EventJoinButtonMutation$data;
  variables: EventJoinButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "agree"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "eventId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "forumId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "join"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subscribe"
},
v5 = [
  {
    "kind": "Variable",
    "name": "eventId",
    "variableName": "eventId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "EventMembership",
  "kind": "LinkedField",
  "name": "membership",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "event",
    "variableName": "eventId"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Event",
  "kind": "LinkedField",
  "name": "event",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "EventRuleAgreementConnection",
      "kind": "LinkedField",
      "name": "entityRuleAgreements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EventRuleAgreement",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": (v7/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": "entityRuleAgreements(first:1)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventRule",
      "kind": "LinkedField",
      "name": "latestRule",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EventRuleAgreement",
          "kind": "LinkedField",
          "name": "entityAgreement",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "subject",
    "variableName": "forumId"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventJoinButtonMutation",
    "selections": [
      {
        "condition": "join",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "EventMembershipEdge",
            "kind": "LinkedField",
            "name": "joinEvent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventMembership",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Event",
                    "kind": "LinkedField",
                    "name": "event",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "agree",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "EventRuleAgreement",
            "kind": "LinkedField",
            "name": "agreeToEventRule",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EventRule",
                "kind": "LinkedField",
                "name": "eventRule",
                "plural": false,
                "selections": [
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "subscribe",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "SubjectSubscriptionEdge",
            "kind": "LinkedField",
            "name": "subscribeToSubject",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "subject",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "entitySubscription",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "EventJoinButtonMutation",
    "selections": [
      {
        "condition": "join",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "EventMembershipEdge",
            "kind": "LinkedField",
            "name": "joinEvent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventMembership",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Event",
                    "kind": "LinkedField",
                    "name": "event",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "agree",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "EventRuleAgreement",
            "kind": "LinkedField",
            "name": "agreeToEventRule",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EventRule",
                "kind": "LinkedField",
                "name": "eventRule",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "subscribe",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "SubjectSubscriptionEdge",
            "kind": "LinkedField",
            "name": "subscribeToSubject",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "subject",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "entitySubscription",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "4157529335ecf6d3fe702877d47018e5",
    "id": null,
    "metadata": {},
    "name": "EventJoinButtonMutation",
    "operationKind": "mutation",
    "text": "mutation EventJoinButtonMutation(\n  $eventId: ID!\n  $forumId: ID!\n  $join: Boolean!\n  $agree: Boolean!\n  $subscribe: Boolean!\n) {\n  joinEvent(eventId: $eventId) @include(if: $join) {\n    node {\n      id\n      event {\n        membership {\n          id\n        }\n        id\n      }\n    }\n  }\n  agreeToEventRule(event: $eventId) @include(if: $agree) {\n    id\n    createdAt\n    eventRule {\n      event {\n        id\n        entityRuleAgreements(first: 1) @ifAllowed {\n          nodes {\n            id\n          }\n        }\n        latestRule {\n          id\n          entityAgreement @ifAllowed {\n            id\n            createdAt\n          }\n        }\n      }\n      id\n    }\n  }\n  subscribeToSubject(subject: $forumId) @include(if: $subscribe) {\n    node {\n      __typename\n      subject {\n        __typename\n        id\n        entitySubscription @ifAllowed {\n          __typename\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f46b1a4bce7b5a13c52c157d42093573";

export default node;
