import { graphql, useMutation } from "react-relay";
import { UploadableMap } from "relay-runtime";
import { useCompetitionEditPageQuery } from "./loaders/CompetitionEditPage";
import {
  CompetitionEditPageMutation as CompetitionEditPageMutationType,
  CompetitionEditPageMutation$variables,
} from "./__generated__/CompetitionEditPageMutation.graphql";
import { useNavigate } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { useIntl, FormattedMessage } from "react-intl";
import CompetitionEditForm from "../components/CompetitionEditForm";
import CompetitionDeleteButton from "../components/CompetitionDeleteButton";
import { logger } from "../common/logger";

const CompetitionEditPageMutation = graphql`
  mutation CompetitionEditPageMutation(
    $id: ID!
    $input: UpdateCompetitionInput!
  ) {
    updateCompetition(id: $id, input: $input) {
      node {
        id
        slug
        title
        shortDescription
        banner
        thumbnail
        visibility
        requiresApproval
        hasLeaderboard
        tags {
          ...CompetitionTagInputForCompetitionEditPageFragment
        }
      }
    }
  }
`;

export default function CompetitionEditPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { query } = useCompetitionEditPageQuery();
  const competition = query.competitionBySlug;
  const tags = query;

  const [commitMutation, isMutationInFlight] =
    useMutation<CompetitionEditPageMutationType>(CompetitionEditPageMutation);
  if (!competition) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Competition not found",
        })}
      />
    );
  }

  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage={"Edit Competition"} />
      </h1>
      <CompetitionEditForm
        routePrefix="competitions"
        defaultValues={{
          ...competition,
          currentTags: competition.tags,
        }}
        availableTags={tags}
        onSubmit={({ data, dirtyFields, banner, thumbnail, setFormError }) => {
          const variables: CompetitionEditPageMutation$variables = {
            id: competition.id,
            input: {
              title: dirtyFields.title ? data.title : undefined,
              shortDescription: dirtyFields.shortDescription
                ? data.shortDescription
                : undefined,
              slug: dirtyFields.slug ? data.slug : undefined,
              visibility: dirtyFields.visibility ? data.visibility : undefined,
              requiresApproval: dirtyFields.requiresApproval
                ? data.requiresApproval
                : undefined,
              hasLeaderboard: dirtyFields.hasLeaderboard
                ? data.hasLeaderboard
                : undefined,
              banner: banner.variable,
              thumbnail: thumbnail.variable,
              tagIds: data.tagIds,
            },
          };
          const uploadables: UploadableMap = {};
          if (banner.uploadable) {
            uploadables["variables.input.banner"] = banner.uploadable;
          }
          if (thumbnail.uploadable) {
            uploadables["variables.input.thumbnail"] = thumbnail.uploadable;
          }
          commitMutation({
            variables,
            uploadables,
            onError: (error) => {
              logger.error(error);
              setFormError(
                intl.formatMessage({
                  defaultMessage:
                    "Could not update competiton. The competition URL may already be taken.",
                }),
              );
            },
            onCompleted: (results) => {
              navigate(`/competitions/${results.updateCompetition.node.slug}`);
            },
          });
        }}
        isDisabled={isMutationInFlight}
      />
      <CompetitionDeleteButton id={competition.id} title={competition.title} />
    </div>
  );
}
