/**
 * @generated SignedSource<<090acd8ce8d4733e63679f0b497ae666>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ActivityVisibility = "AUTHENTICATED" | "MEMBERS" | "UNAUTHENTICATED";
export type CreateEventInput = {
  banner?: any | null | undefined;
  description?: string | null | undefined;
  shortDescription: string;
  slug: string;
  thumbnail?: any | null | undefined;
  title: string;
  visibility?: ActivityVisibility;
};
export type EventCreatePageMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateEventInput;
};
export type EventCreatePageMutation$data = {
  readonly createEvent: {
    readonly node: {
      readonly banner: any | null | undefined;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly isPrivate: boolean;
      readonly slug: string;
      readonly thumbnail: any | null | undefined;
      readonly title: string;
    };
  };
};
export type EventCreatePageMutation = {
  response: EventCreatePageMutation$data;
  variables: EventCreatePageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": (v2/*: any*/),
  "concreteType": "EventEdge",
  "kind": "LinkedField",
  "name": "createEvent",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Event",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "banner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPrivate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventCreatePageMutation",
    "selections": [
      (v3/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EventCreatePageMutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": null,
        "handle": "prependEdge",
        "key": "",
        "kind": "LinkedHandle",
        "name": "createEvent",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "753e10d36c20cd476da6a602a04af37e",
    "id": null,
    "metadata": {},
    "name": "EventCreatePageMutation",
    "operationKind": "mutation",
    "text": "mutation EventCreatePageMutation(\n  $input: CreateEventInput!\n) {\n  createEvent(input: $input) {\n    node {\n      id\n      title\n      description\n      slug\n      banner\n      thumbnail\n      isPrivate\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "78e8ea73b07b066edfe32e26176d9ae4";

export default node;
