import { createSearchParams } from "react-router-dom";

export function parseEnumParam<T>(
  value: string,
  validValues: ReadonlyArray<T>,
): T | undefined {
  const uppercasedValue = value.toUpperCase() as T;
  if (validValues.includes(uppercasedValue)) {
    return uppercasedValue;
  }
  return undefined;
}

export function getParamFromRequest(request: Request, paramName: string) {
  const searchParams = createSearchParams(new URL(request.url).searchParams);
  return searchParams.get(paramName);
}
