/**
 * @generated SignedSource<<9db9247a067fdf4978c937136eddfcc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationMembershipKind = "ADMIN" | "EDITOR" | "OWNER" | "READER";
export type OrganizationUsersPageTransferOwnershipMutation$variables = {
  connections: ReadonlyArray<string>;
  organizationId: string;
  toUserId: string;
};
export type OrganizationUsersPageTransferOwnershipMutation$data = {
  readonly transferOrganizationOwnership: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly kind: OrganizationMembershipKind;
      readonly organization: {
        readonly displayName: string;
        readonly id: string;
        readonly username: string;
        readonly viewerCanEdit: boolean;
        readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
      };
      readonly user: {
        readonly displayName: string;
        readonly id: string;
        readonly username: string;
        readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
      };
    };
  }>;
};
export type OrganizationUsersPageTransferOwnershipMutation = {
  response: OrganizationUsersPageTransferOwnershipMutation$data;
  variables: OrganizationUsersPageTransferOwnershipMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toUserId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "toUserId",
    "variableName": "toUserId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v8 = {
  "alias": "viewerCanEdit",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "UPDATE_ORGANIZATION_MEMBERSHIP"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_ORGANIZATION_MEMBERSHIP\")"
},
v9 = {
  "args": [
    {
      "kind": "Literal",
      "name": "thumbnail",
      "value": true
    }
  ],
  "kind": "FragmentSpread",
  "name": "EntityProfilePicFragment"
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageThumbnail",
      "storageKey": null
    }
  ],
  "type": "Entity",
  "abstractKey": "__isEntity"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationUsersPageTransferOwnershipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "OrganizationMembershipEdge",
        "kind": "LinkedField",
        "name": "transferOrganizationOwnership",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrganizationUsersPageTransferOwnershipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "OrganizationMembershipEdge",
        "kind": "LinkedField",
        "name": "transferOrganizationOwnership",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": null,
        "handle": "prependEdge",
        "key": "",
        "kind": "LinkedHandle",
        "name": "transferOrganizationOwnership",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "5c002b9626bf33dc53764780b88cf7d3",
    "id": null,
    "metadata": {},
    "name": "OrganizationUsersPageTransferOwnershipMutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationUsersPageTransferOwnershipMutation(\n  $organizationId: ID!\n  $toUserId: ID!\n) {\n  transferOrganizationOwnership(organizationId: $organizationId, toUserId: $toUserId) {\n    node {\n      id\n      kind\n      organization {\n        id\n        username\n        displayName\n        viewerCanEdit: viewerCan(action: UPDATE_ORGANIZATION_MEMBERSHIP)\n        ...EntityProfilePicFragment_49kWkb\n      }\n      user {\n        id\n        username\n        displayName\n        ...EntityProfilePicFragment_49kWkb\n      }\n    }\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n"
  }
};
})();

(node as any).hash = "5b34708ebda207e12cf0de6c2bb0117a";

export default node;
