/**
 * @generated SignedSource<<94371fb929e60f816bf3d2ad41908702>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ActivityVisibility = "AUTHENTICATED" | "MEMBERS" | "UNAUTHENTICATED";
export type UpdateCompetitionInput = {
  banner?: any | null | undefined;
  description?: string | null | undefined;
  hasLeaderboard?: boolean | null | undefined;
  requiresApproval?: boolean | null | undefined;
  rules?: string | null | undefined;
  shortDescription?: string | null | undefined;
  slug?: string | null | undefined;
  tagIds?: ReadonlyArray<string | null | undefined> | null | undefined;
  thumbnail?: any | null | undefined;
  title?: string | null | undefined;
  visibility?: ActivityVisibility | null | undefined;
};
export type CompetitionOverviewPageUpdateCompetitionMutation$variables = {
  id: string;
  input: UpdateCompetitionInput;
};
export type CompetitionOverviewPageUpdateCompetitionMutation$data = {
  readonly updateCompetition: {
    readonly node: {
      readonly description: string | null | undefined;
      readonly id: string;
    };
  };
};
export type CompetitionOverviewPageUpdateCompetitionMutation = {
  response: CompetitionOverviewPageUpdateCompetitionMutation$data;
  variables: CompetitionOverviewPageUpdateCompetitionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CompetitionEdge",
    "kind": "LinkedField",
    "name": "updateCompetition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionOverviewPageUpdateCompetitionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionOverviewPageUpdateCompetitionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5e77671f4cf1300ffe3501740266883d",
    "id": null,
    "metadata": {},
    "name": "CompetitionOverviewPageUpdateCompetitionMutation",
    "operationKind": "mutation",
    "text": "mutation CompetitionOverviewPageUpdateCompetitionMutation(\n  $id: ID!\n  $input: UpdateCompetitionInput!\n) {\n  updateCompetition(id: $id, input: $input) {\n    node {\n      id\n      description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "94cf4c66d7f360745a587b21d7afda40";

export default node;
