/**
 * @generated SignedSource<<c5c63f8158f9a2a4876b551b9638738f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserActivityFragment$data = {
  readonly createdAt: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserActivityCalendarFragment">;
  readonly " $fragmentType": "UserActivityFragment";
};
export type UserActivityFragment$key = {
  readonly " $data"?: UserActivityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserActivityFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "date"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "kinds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserActivityFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "date",
          "variableName": "date"
        },
        {
          "kind": "Variable",
          "name": "kinds",
          "variableName": "kinds"
        }
      ],
      "kind": "FragmentSpread",
      "name": "UserActivityCalendarFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "8744802f13934d4a8eff17d8baec2863";

export default node;
