import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import ProfilePic from "../components/ProfilePic";
import Button from "../components/Button";
import FormGroup from "../components/FormGroup";
import MarkdownEditor from "../components/MarkdownEditor";
import TextInput from "../components/TextInput";
import { isNotBannedUsername, isUrl, maxFileSize } from "../utils/validation";
import { useImagePreview, useUpload } from "../utils/hooks";
import AspectDiv from "../components/AspectDiv";
import { useLocation } from "../utils/location";
import {
  type MarkdownEditorContextProps,
  type MarkdownValue,
} from "./MarkdownEditor/context";
import { MarkdownEditorProvider } from "./MarkdownEditor/Provider";

export interface FormData {
  username: string;
  displayName: string;
  location: string;
  linkedin: string;
  github: string;
  website: string;
  bio: string;
  image: FileList;
}

interface DefaultValues {
  username?: string;
  displayName?: string;
  location?: string | null;
  linkedin?: string | null;
  github?: string | null;
  website?: string | null;
  bio?: string | null;
  image?: string | null;
}

export type SubmitData<T> = {
  data: T;
  image: { variable: null | undefined; uploadable: File | undefined };
  setFormError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

interface Props<T extends FormData> {
  onSubmit: (data: SubmitData<T>) => void;
  form: UseFormReturn<T>;
  isDisabled?: boolean;
  defaultValues?: DefaultValues;
  children?: React.ReactNode;
}

export default function EntityEditForm<T extends FormData>({
  form,
  onSubmit,
  isDisabled,
  defaultValues,
  children,
}: Props<T>) {
  const location = useLocation();
  const intl = useIntl();
  const {
    register,
    resetField,
    watch,
    control,
    formState: { errors },
  } = form as unknown as UseFormReturn<FormData>;
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [markdownValue, setMarkdownValue] = useState<MarkdownValue>(
    defaultValues?.bio ?? "",
  );
  const image = useUpload(defaultValues?.image, watch("image"), () =>
    resetField("image"),
  );
  const imagePreview = useImagePreview(image.value);

  const doSubmit = form.handleSubmit((data) => {
    onSubmit({
      data,
      image: {
        variable: image.variable,
        uploadable: image.value,
      },
      setFormError,
    });
  });

  const errorMessages = {
    displayName: {
      required: intl.formatMessage({ defaultMessage: "Name is required" }),
      maxLength: intl.formatMessage({
        defaultMessage: "Name must be at most 50 characters",
      }),
    },
    username: {
      required: intl.formatMessage({ defaultMessage: "Username is required" }),
      minLength: intl.formatMessage({
        defaultMessage: "Username must be at least 3 characters",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "Username must be at most 20 characters",
      }),
      pattern: intl.formatMessage({
        defaultMessage:
          "Username can only contain letters, numbers, and underscores",
      }),
      isNotBannedUsername: intl.formatMessage({
        defaultMessage: "Username not allowed",
      }),
    },
    image: {
      size: intl.formatMessage({
        defaultMessage: "Image must be at most 2 MB",
      }),
    },
    linkedin: {
      pattern: intl.formatMessage({
        defaultMessage: "Invalid LinkedIn username",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "LinkedIn username must be at most 255 characters",
      }),
    },
    github: {
      pattern: intl.formatMessage({
        defaultMessage: "Invalid Github username",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "Github username must be at most 255 characters",
      }),
    },
    website: {
      isUrl: intl.formatMessage({
        defaultMessage: "Invalid website URL",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "Personal website must be at most 255 characters",
      }),
    },
    password: {
      minLength: intl.formatMessage({
        defaultMessage: "Password must be at least 6 characters",
      }),
    },
    oldPassword: {
      required: intl.formatMessage({ defaultMessage: "Password is required" }),
    },
    bio: {
      maxLength: intl.formatMessage({
        defaultMessage: "Bio must be at most 2000 characters",
      }),
    },
  };

  const contextValue: MarkdownEditorContextProps<FormData> = {
    name: "bio",
    control,
    defaultValue: defaultValues?.bio ?? "",
    setMarkdownValue,
    markdownValue,
    canUploadFiles: false,
    rules: { maxLength: 2000 },
  };

  return (
    <form onSubmit={doSubmit}>
      <div className="space-y-6">
        <div className="flex">
          <div className="flex-grow">
            {formError && (
              <p className="pt-1 text-sm text-red-500">{formError}</p>
            )}
          </div>
          <div>
            <Button kind="primary" onClick={doSubmit} disabled={isDisabled}>
              <FormattedMessage defaultMessage="Save" />
            </Button>
          </div>
        </div>

        <div className="flex flex-col space-y-3">
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Name" })}
            error={
              typeof errors.displayName?.type === "string" &&
              errorMessages.displayName[
                errors.displayName
                  .type as keyof typeof errorMessages.displayName
              ]
            }
          >
            <TextInput
              aria-invalid={errors.displayName ? "true" : "false"}
              defaultValue={defaultValues?.displayName}
              {...register("displayName", {
                setValueAs: (value) => value.trim(),
                required: true,
                maxLength: 50,
              })}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Username" })}
            error={
              typeof errors.username?.type === "string" &&
              errorMessages.username[
                errors.username.type as keyof typeof errorMessages.username
              ]
            }
          >
            <TextInput
              prefix={location.host + "/"}
              aria-invalid={errors.username ? "true" : "false"}
              defaultValue={defaultValues?.username}
              {...register("username", {
                setValueAs: (value) => value.trim(),
                required: true,
                minLength: 3,
                maxLength: 20,
                pattern: /^[a-zA-Z0-9_]*$/,
                validate: {
                  isNotBannedUsername: isNotBannedUsername(
                    defaultValues?.username ? [defaultValues?.username] : [],
                  ),
                },
              })}
            />
          </FormGroup>
          <div className="py-5">
            <hr />
          </div>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Picture" })}
            error={
              typeof errors.image?.type === "string" &&
              errorMessages.image[
                errors.image.type as keyof typeof errorMessages.image
              ]
            }
          >
            <div className="flex flex-row items-center space-x-3">
              <div className="w-28">
                {image.isDirty ? (
                  <AspectDiv ratio={1} className="rounded-full">
                    <ProfilePic
                      src={imagePreview}
                      className="h-full"
                      alt={intl.formatMessage({
                        defaultMessage: "Thumbnail preview",
                      })}
                    />
                  </AspectDiv>
                ) : (
                  <ProfilePic
                    src={defaultValues?.image || undefined}
                    alt={intl.formatMessage({
                      defaultMessage: "Default image preview",
                    })}
                  />
                )}
              </div>
              <div>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  aria-invalid={errors.image ? "true" : "false"}
                  {...register("image", {
                    validate: {
                      size: maxFileSize(1024 * 1024 * 2), // 2 MB
                    },
                  })}
                />
                <div className="flex flex-row">
                  {image.isDirty && (
                    <Button kind="text" onClick={image.reset}>
                      <FormattedMessage defaultMessage="Reset" />
                    </Button>
                  )}
                  {image.canDelete && (
                    <Button kind="text" onClick={image.deleteImage}>
                      <FormattedMessage defaultMessage="Delete" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </FormGroup>
          <div className="py-5">
            <hr />
          </div>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "LinkedIn" })}
            error={
              typeof errors.linkedin?.type === "string" &&
              errorMessages.linkedin[
                errors.linkedin.type as keyof typeof errorMessages.linkedin
              ]
            }
          >
            <TextInput
              prefix="linkedin.com/"
              aria-invalid={errors.linkedin ? "true" : "false"}
              defaultValue={defaultValues?.linkedin || undefined}
              {...register("linkedin", {
                pattern: /^(in|company|school)\/[-a-zA-Z0-9_]+\/?$/,
                maxLength: 255,
                setValueAs: (value) => value.trim(),
              })}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Github" })}
            error={
              typeof errors.github?.type === "string" &&
              errorMessages.github[
                errors.github.type as keyof typeof errorMessages.github
              ]
            }
          >
            <TextInput
              prefix="github.com/"
              aria-invalid={errors.github ? "true" : "false"}
              defaultValue={defaultValues?.github || undefined}
              {...register("github", {
                pattern: /^[-a-zA-Z0-9_]+$/,
                maxLength: 255,
                setValueAs: (value) => value.trim(),
              })}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Personal Website" })}
            error={
              typeof errors.website?.type === "string" &&
              errorMessages.website[
                errors.website.type as keyof typeof errorMessages.website
              ]
            }
          >
            <TextInput
              type="url"
              aria-invalid={errors.website ? "true" : "false"}
              defaultValue={defaultValues?.website || undefined}
              {...register("website", {
                setValueAs: (value) => value.trim(),
                maxLength: 255,
                validate: {
                  isUrl,
                },
              })}
            />
          </FormGroup>
          <div className="py-5">
            <hr />
          </div>
          <FormGroup label={intl.formatMessage({ defaultMessage: "Location" })}>
            <TextInput
              defaultValue={defaultValues?.location || undefined}
              {...register("location", {
                setValueAs: (value) => value.trim(),
              })}
            />
          </FormGroup>
          {children}
          <div className="py-5">
            <hr />
          </div>
          <MarkdownEditorProvider value={contextValue}>
            <FormGroup
              label={intl.formatMessage({ defaultMessage: "Bio" })}
              error={
                typeof errors.bio?.type === "string" &&
                errorMessages.bio[
                  errors.bio.type as keyof typeof errorMessages.bio
                ]
              }
            >
              <MarkdownEditor
                rows={15}
                aria-invalid={errors.bio ? "true" : "false"}
              />
            </FormGroup>
          </MarkdownEditorProvider>
        </div>
      </div>
    </form>
  );
}
