/**
 * @generated SignedSource<<2e1bf297dee21cec68c5a4ce97c1c2e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventCompetitionsPageQuery$variables = {
  slug: string;
};
export type EventCompetitionsPageQuery$data = {
  readonly eventBySlug: {
    readonly id: string;
    readonly title: string;
    readonly viewerCanEditCompetitions: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"EventCompetitionsFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CompetitionAutocompleteFragment">;
};
export type EventCompetitionsPageQuery = {
  response: EventCompetitionsPageQuery$data;
  variables: EventCompetitionsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": "viewerCanEditCompetitions",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "ADD_EVENT_COMPETITION"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"ADD_EVENT_COMPETITION\")"
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventCompetitionsPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CompetitionAutocompleteFragment"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventCompetitionsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventCompetitionsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "CompetitionConnection",
        "kind": "LinkedField",
        "name": "competitions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Competition",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "competitions(first:10)"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "EventCompetitionConnection",
            "kind": "LinkedField",
            "name": "competitions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventCompetitionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventCompetition",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": "viewerCanDelete",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "action",
                            "value": "REMOVE_EVENT_COMPETITION"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "viewerCan",
                        "storageKey": "viewerCan(action:\"REMOVE_EVENT_COMPETITION\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Competition",
                        "kind": "LinkedField",
                        "name": "competition",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v6/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shortDescription",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnail",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "host",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isEntity"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "imageThumbnail",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CompetitionTagConnection",
                            "kind": "LinkedField",
                            "name": "tags",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TagEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Tag",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "competitions(first:10)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "EventCompetitionsFragment_competitions",
            "kind": "LinkedHandle",
            "name": "competitions"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2e236a0ee40e50fa73af89ea51bdf417",
    "id": null,
    "metadata": {},
    "name": "EventCompetitionsPageQuery",
    "operationKind": "query",
    "text": "query EventCompetitionsPageQuery(\n  $slug: String!\n) {\n  ...CompetitionAutocompleteFragment\n  eventBySlug(slug: $slug) {\n    id\n    title\n    viewerCanEditCompetitions: viewerCan(action: ADD_EVENT_COMPETITION)\n    ...EventCompetitionsFragment\n  }\n}\n\nfragment ActivityCardHostFragment on Entity {\n  __isEntity: __typename\n  username\n  ...EntityProfilePicFragment_49kWkb\n}\n\nfragment ActivityCardTagsFragment on CompetitionTagConnection {\n  edges {\n    node {\n      id\n      ...CompetitionTagFragment\n    }\n  }\n}\n\nfragment CompetitionAutocompleteFragment on Query {\n  competitions(first: 10) {\n    edges {\n      node {\n        id\n        title\n        slug\n      }\n    }\n  }\n}\n\nfragment CompetitionCardFragment on Competition {\n  id\n  slug\n  title\n  shortDescription\n  thumbnail\n  host {\n    __typename\n    ...ActivityCardHostFragment\n    id\n  }\n  tags {\n    ...ActivityCardTagsFragment\n  }\n}\n\nfragment CompetitionTagFragment on Tag {\n  name\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment EventCompetitionsFragment on Event {\n  id\n  competitions(first: 10) {\n    edges {\n      node {\n        id\n        viewerCanDelete: viewerCan(action: REMOVE_EVENT_COMPETITION)\n        competition {\n          id\n          slug\n          title\n          ...CompetitionCardFragment\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c9a6925dfd19d1ba840b5e4bcb0ead8a";

export default node;
