/**
 * @generated SignedSource<<02ad8f480efb298b404b4c38a9beca62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Action = "ADD_COMPETITION_MEMBER" | "ADD_EVENT_COMPETITION" | "ADD_EVENT_MEMBER" | "AWARD_BADGE" | "CREATE_COMMENT" | "CREATE_COMPETITION" | "CREATE_COMPETITION_RULE_AGREEMENT" | "CREATE_EVENT" | "CREATE_EVENT_RULE_AGREEMENT" | "CREATE_FORUM" | "CREATE_ORGANIZATION" | "CREATE_PROJECT_VERSION_APPROVAL" | "CREATE_SUBJECT_SUBSCRIPTION" | "CREATE_SUBMISSION_VERSION" | "CREATE_TAG" | "CREATE_TOPIC" | "CREATE_USE_CASE_VERSION" | "DELETE_COMMENT" | "DELETE_COMPETITION" | "DELETE_EVENT" | "DELETE_FORUM" | "DELETE_ORGANIZATION" | "DELETE_PROJECT_VERSION_APPROVAL" | "DELETE_SUBJECT_SUBSCRIPTION" | "DELETE_TAG" | "DELETE_TOPIC" | "DELETE_USER" | "FETCH_WEBSITE_METADATA" | "JOIN_COMPETITION" | "JOIN_EVENT" | "PUBLISH_VOTE" | "READ_ACTIVITY_TRACKER" | "READ_COMMENT" | "READ_COMPETITION" | "READ_COMPETITION_MEMBERSHIP" | "READ_COMPETITION_RULE" | "READ_COMPETITION_RULE_AGREEMENT" | "READ_EVENT" | "READ_EVENT_COMPETITION" | "READ_EVENT_MEMBERSHIP" | "READ_EVENT_RULE" | "READ_EVENT_RULE_AGREEMENT" | "READ_PROJECT_VERSION" | "READ_PROJECT_VERSION_APPROVAL" | "READ_PROJECT_VERSION_EVALUATION" | "READ_PROJECT_VERSION_FILE" | "READ_SUBJECT_SUBSCRIPTION" | "READ_TOPIC" | "READ_USER_EMAIL" | "READ_USER_NOTIFICATIONS" | "READ_USER_PERMISSIONS" | "REMOVE_COMPETITION_MEMBER" | "REMOVE_EVENT_COMPETITION" | "REMOVE_EVENT_MEMBER" | "REMOVE_ORGANIZATION_MEMBER" | "SET_COMPETITION_ORDERING_PRIORITY" | "TRANSFER_COMPETITION_OWNERSHIP" | "TRANSFER_EVENT_OWNERSHIP" | "TRANSFER_ORGANIZATION_OWNERSHIP" | "UPDATE_AGENDA" | "UPDATE_COMMENT" | "UPDATE_COMPETITION" | "UPDATE_EVENT" | "UPDATE_FORUM" | "UPDATE_ORGANIZATION" | "UPDATE_ORGANIZATION_MEMBERSHIP" | "UPDATE_PROJECT_VERSION" | "UPDATE_PROJECT_VERSION_FILE" | "UPDATE_TOPIC" | "UPDATE_USER" | "UPLOAD_FILES";
export type Permission = {
  actingAs?: string | null | undefined;
  on?: string | null | undefined;
  to: Action;
};
export type UserEntitiesAutocompleteFragmentPaginationQuery$variables = {
  count?: number | null | undefined;
  id: string;
  permission?: Permission | null | undefined;
  search?: string | null | undefined;
};
export type UserEntitiesAutocompleteFragmentPaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"UserEntitiesAutocompleteFragment">;
  };
};
export type UserEntitiesAutocompleteFragmentPaginationQuery = {
  response: UserEntitiesAutocompleteFragmentPaginationQuery$data;
  variables: UserEntitiesAutocompleteFragmentPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "permission"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "kind": "Variable",
  "name": "permission",
  "variableName": "permission"
},
v6 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserEntitiesAutocompleteFragmentPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "UserEntitiesAutocompleteFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserEntitiesAutocompleteFragmentPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "count"
                  },
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "concreteType": "UserEntitiesConnection",
                "kind": "LinkedField",
                "name": "entities",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EntityEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "47a44ab4eb4b5a53eda42254d003ecad",
    "id": null,
    "metadata": {},
    "name": "UserEntitiesAutocompleteFragmentPaginationQuery",
    "operationKind": "query",
    "text": "query UserEntitiesAutocompleteFragmentPaginationQuery(\n  $count: Int = 10\n  $permission: Permission\n  $search: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...UserEntitiesAutocompleteFragment_2YZHm4\n    id\n  }\n}\n\nfragment UserEntitiesAutocompleteFragment_2YZHm4 on User {\n  id\n  username\n  entities(first: $count, search: $search, permission: $permission) {\n    edges {\n      node {\n        __typename\n        id\n        kind\n        username\n        displayName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3950ac25c2feeaedc166c0adf6411319";

export default node;
