/**
 * @generated SignedSource<<17cb8fe6b011abdb646215ae052f1667>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionMembersFragmentPaginationQuery$variables = {
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  id: string;
};
export type CompetitionMembersFragmentPaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"CompetitionMembersFragment">;
  };
};
export type CompetitionMembersFragmentPaginationQuery = {
  response: CompetitionMembersFragmentPaginationQuery$data;
  variables: CompetitionMembersFragmentPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionMembersFragmentPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ],
            "kind": "FragmentSpread",
            "name": "CompetitionMembersFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionMembersFragmentPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "viewerCanEdit",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "UPDATE_COMPETITION"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"UPDATE_COMPETITION\")"
              },
              {
                "alias": "viewerCanEditMembers",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "ADD_COMPETITION_MEMBER"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"ADD_COMPETITION_MEMBER\")"
              },
              {
                "alias": "viewerCanTransferOwnership",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "TRANSFER_COMPETITION_OWNERSHIP"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"TRANSFER_COMPETITION_OWNERSHIP\")"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "CompetitionMembershipConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompetitionMembershipEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CompetitionMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "alias": "viewerCanDelete",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "action",
                                "value": "REMOVE_COMPETITION_MEMBER"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "viewerCan",
                            "storageKey": "viewerCan(action:\"REMOVE_COMPETITION_MEMBER\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "entity",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                              },
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isEntity"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "imageThumbnail",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "CompetitionMembersFragment_members",
                "kind": "LinkedHandle",
                "name": "members"
              }
            ],
            "type": "Competition",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a70ef9a1df2c5a62fcfa7b51abf3a36d",
    "id": null,
    "metadata": {},
    "name": "CompetitionMembersFragmentPaginationQuery",
    "operationKind": "query",
    "text": "query CompetitionMembersFragmentPaginationQuery(\n  $count: Int = 10\n  $cursor: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...CompetitionMembersFragment_1G22uz\n    id\n  }\n}\n\nfragment CompetitionMembersFragment_1G22uz on Competition {\n  id\n  viewerCanEdit: viewerCan(action: UPDATE_COMPETITION)\n  viewerCanEditMembers: viewerCan(action: ADD_COMPETITION_MEMBER)\n  viewerCanTransferOwnership: viewerCan(action: TRANSFER_COMPETITION_OWNERSHIP)\n  members(first: $count, after: $cursor) {\n    edges {\n      node {\n        id\n        kind\n        viewerCanDelete: viewerCan(action: REMOVE_COMPETITION_MEMBER)\n        entity {\n          __typename\n          id\n          username\n          displayName\n          ...EntityProfilePicFragment_49kWkb\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n"
  }
};
})();

(node as any).hash = "34d3fb5bef1732a17c768632fefd2ac4";

export default node;
