/**
 * @generated SignedSource<<39d1243334ad358baf9338cb8f4aabca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JoinParisHackPageQuery$variables = Record<PropertyKey, never>;
export type JoinParisHackPageQuery$data = {
  readonly viewer: {
    readonly displayName: string;
    readonly email: string;
    readonly id: string;
    readonly username: string;
    readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
  };
};
export type JoinParisHackPageQuery = {
  response: JoinParisHackPageQuery$data;
  variables: JoinParisHackPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "JoinParisHackPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": [
              {
                "kind": "Literal",
                "name": "thumbnail",
                "value": true
              }
            ],
            "kind": "FragmentSpread",
            "name": "EntityProfilePicFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "JoinParisHackPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageThumbnail",
                "storageKey": null
              }
            ],
            "type": "Entity",
            "abstractKey": "__isEntity"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fc1e09487686abb2b5ab03f9e6f70e13",
    "id": null,
    "metadata": {},
    "name": "JoinParisHackPageQuery",
    "operationKind": "query",
    "text": "query JoinParisHackPageQuery {\n  viewer @ifAllowed {\n    id\n    username\n    email\n    displayName\n    ...EntityProfilePicFragment_49kWkb\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n"
  }
};
})();

(node as any).hash = "0b23b5f43a57cbdddab9e1d9a4f3dda3";

export default node;
