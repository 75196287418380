/**
 * @generated SignedSource<<84856ce7d2337f890b8a17f734623ce7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionJoinButtonFragment$data = {
  readonly entityRuleAgreements: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
    }>;
  };
  readonly forum: {
    readonly entitySubscription: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
  };
  readonly id: string;
  readonly latestRule: {
    readonly entityAgreement: {
      readonly createdAt: string;
      readonly id: string;
    } | null | undefined;
    readonly id: string;
    readonly text: string;
  };
  readonly membership: {
    readonly id: string;
  } | null | undefined;
  readonly viewerCanAgree: boolean;
  readonly viewerCanJoin: boolean;
  readonly " $fragmentType": "CompetitionJoinButtonFragment";
};
export type CompetitionJoinButtonFragment$key = {
  readonly " $data"?: CompetitionJoinButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetitionJoinButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompetitionJoinButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "viewerCanAgree",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "CREATE_COMPETITION_RULE_AGREEMENT"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"CREATE_COMPETITION_RULE_AGREEMENT\")"
    },
    {
      "alias": "viewerCanJoin",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "JOIN_COMPETITION"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"JOIN_COMPETITION\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CompetitionMembership",
      "kind": "LinkedField",
      "name": "membership",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "CompetitionRuleAgreementConnection",
      "kind": "LinkedField",
      "name": "entityRuleAgreements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetitionRuleAgreement",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": "entityRuleAgreements(first:1)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Forum",
      "kind": "LinkedField",
      "name": "forum",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "entitySubscription",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CompetitionRule",
      "kind": "LinkedField",
      "name": "latestRule",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetitionRuleAgreement",
          "kind": "LinkedField",
          "name": "entityAgreement",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Competition",
  "abstractKey": null
};
})();

(node as any).hash = "965af61a4c21444dec4093f6c1428bdf";

export default node;
