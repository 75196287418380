/**
 * @generated SignedSource<<3ed64f8a7eec24330b270bee34e074d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ActivityVisibility = "AUTHENTICATED" | "MEMBERS" | "UNAUTHENTICATED";
export type UpdateEventInput = {
  banner?: any | null | undefined;
  description?: string | null | undefined;
  rules?: string | null | undefined;
  shortDescription?: string | null | undefined;
  slug?: string | null | undefined;
  thumbnail?: any | null | undefined;
  title?: string | null | undefined;
  visibility?: ActivityVisibility | null | undefined;
};
export type EventEditPageMutation$variables = {
  id: string;
  input: UpdateEventInput;
};
export type EventEditPageMutation$data = {
  readonly updateEvent: {
    readonly node: {
      readonly banner: any | null | undefined;
      readonly id: string;
      readonly shortDescription: string;
      readonly slug: string;
      readonly thumbnail: any | null | undefined;
      readonly title: string;
      readonly viewerCanUpdateAgenda: boolean;
      readonly visibility: ActivityVisibility;
    };
  };
};
export type EventEditPageMutation = {
  response: EventEditPageMutation$data;
  variables: EventEditPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EventEdge",
    "kind": "LinkedField",
    "name": "updateEvent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "banner",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visibility",
            "storageKey": null
          },
          {
            "alias": "viewerCanUpdateAgenda",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "UPDATE_AGENDA"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"UPDATE_AGENDA\")"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventEditPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventEditPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6750e443e6b77cf3e2fb24cc8b2779d1",
    "id": null,
    "metadata": {},
    "name": "EventEditPageMutation",
    "operationKind": "mutation",
    "text": "mutation EventEditPageMutation(\n  $id: ID!\n  $input: UpdateEventInput!\n) {\n  updateEvent(id: $id, input: $input) {\n    node {\n      id\n      slug\n      title\n      shortDescription\n      banner\n      thumbnail\n      visibility\n      viewerCanUpdateAgenda: viewerCan(action: UPDATE_AGENDA)\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "54ec1e3309bf81ccb0a142c812a2a44f";

export default node;
