import { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { graphql, useMutation } from "react-relay";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { LoginPageUserMutation as LoginPageUserMutationType } from "./__generated__/LoginPageUserMutation.graphql";
import { useOnAuthCallback } from "../utils/auth";

import Button from "../components/Button";
import FormGroup from "../components/FormGroup";
import TextInput from "../components/TextInput";
import { MetaLayout } from "../common/MetaLayout";

const LoginPageUserMutation = graphql`
  mutation LoginPageUserMutation($input: LoginUserInput!) {
    loginUser(input: $input) {
      node {
        id
      }
    }
  }
`;

type FormData = {
  usernameOrEmail: string;
  password: string;
};

export default function Login() {
  const intl = useIntl();
  const [searchParams, _setSearchParams] = useSearchParams();
  const onAuthenticated = useOnAuthCallback();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [commitMutation, isMutationInFlight] =
    useMutation<LoginPageUserMutationType>(LoginPageUserMutation);
  const onSubmit = handleSubmit((data) => {
    setFormError(undefined);
    commitMutation({
      variables: {
        input: data,
      },
      onError() {
        setFormError(
          intl.formatMessage({
            defaultMessage:
              "Could not login. Check your credentials and try again.",
          }),
        );
      },
      onCompleted() {
        onAuthenticated();
      },
    });
  });
  const errorMessages = {
    usernameOrEmail: {
      required: intl.formatMessage({
        defaultMessage: "Username or email is required",
      }),
    },
    password: {
      required: intl.formatMessage({ defaultMessage: "Password is required" }),
    },
  };
  return (
    <MetaLayout
      metaTitle={intl.formatMessage({
        defaultMessage: "Login on Aqora.io - Join the Global Quantum Community",
      })}
    >
      <h1 className="text-xl font-bold mb-4">
        <FormattedMessage defaultMessage="Login" />
      </h1>
      <form onSubmit={onSubmit}>
        <fieldset className="flex flex-col space-y-3">
          <legend className="sr-only">
            {intl.formatMessage({ defaultMessage: "Login Form" })}
          </legend>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Username or Email" })}
            error={
              typeof errors.usernameOrEmail?.type === "string" &&
              errorMessages.usernameOrEmail[
                errors.usernameOrEmail
                  .type as keyof typeof errorMessages.usernameOrEmail
              ]
            }
          >
            <TextInput
              aria-invalid={errors.usernameOrEmail ? "true" : "false"}
              {...register("usernameOrEmail", {
                required: true,
              })}
            />
          </FormGroup>
          <FormGroup
            label={
              <div className="flex flex-row justify-between">
                <FormattedMessage defaultMessage="Password" />
                <Link
                  className="text-gray-600 hover:text-gray-700 text-sm"
                  tabIndex={-1}
                  to="/send-password-reset"
                >
                  <FormattedMessage defaultMessage="Forgot Password?" />
                </Link>
              </div>
            }
            error={
              typeof errors.password?.type === "string" &&
              errorMessages.password[
                errors.password.type as keyof typeof errorMessages.password
              ]
            }
          >
            <TextInput
              type="password"
              aria-invalid={errors.password ? "true" : "false"}
              {...register("password", {
                required: true,
              })}
            />
          </FormGroup>
          {formError && (
            <p className="pt-1 text-sm text-red-500">{formError}</p>
          )}
          <div className="py-3 flex justify-between items-center">
            <p className="text-gray-600 pr-2">
              <FormattedMessage
                defaultMessage="Don't have an account? {signupLink}"
                values={{
                  signupLink: (
                    <Link
                      to={{
                        pathname: "/signup",
                        search: searchParams.toString(),
                      }}
                      className="text-indigo"
                    >
                      <FormattedMessage defaultMessage="Signup for Aqora" />
                    </Link>
                  ),
                }}
              />
            </p>
            <Button type="submit" disabled={isMutationInFlight}>
              <FormattedMessage defaultMessage="Login" />
            </Button>
          </div>
        </fieldset>
      </form>
    </MetaLayout>
  );
}
