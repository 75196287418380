import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { EventRulesPageQuery as EventRulesPageQueryType } from "./__generated__/EventRulesPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { EventRulesPageQuery as EventRulesPageQueryType } from "./__generated__/EventRulesPageQuery.graphql";

export const EventRulesPageQuery = graphql`
  query EventRulesPageQuery($slug: String!) {
    viewer @ifAllowed {
      id
    }
    eventBySlug(slug: $slug) {
      id
      title
      latestRule {
        id
        text
      }
      viewerCanUpdate: viewerCan(action: UPDATE_EVENT)
      viewerCanAccept: viewerCan(action: CREATE_EVENT_RULE_AGREEMENT)
      ...EventAcceptRulesButtonEventFragment
      ...MemberSelectEventFragment
    }
  }
`;

export const eventRulesPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<EventRulesPageQueryType>(environment, EventRulesPageQuery, {
      slug,
    });
  };
};

export const useEventRulesPageQuery = () =>
  usePreloaded<EventRulesPageQueryType>();
