/**
 * @generated SignedSource<<b59f754bb4c698021d35ca8dbcc2e8bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionScaffoldQuery$variables = {
  slug: string;
};
export type CompetitionScaffoldQuery$data = {
  readonly competitionBySlug: {
    readonly banner: any | null | undefined;
    readonly createdAt: string;
    readonly description: string | null | undefined;
    readonly hasLeaderboard: boolean;
    readonly host: {
      readonly displayName: string;
      readonly id: string;
      readonly username: string;
      readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
    };
    readonly id: string;
    readonly isPrivate: boolean;
    readonly requiresApproval: boolean;
    readonly shortDescription: string;
    readonly slug: string;
    readonly thumbnail: any | null | undefined;
    readonly title: string;
    readonly viewerCanEdit: boolean;
    readonly viewerCanEditMembers: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"CompetitionJoinButtonFragment" | "CompetitionSetupButtonFragment">;
  } | null | undefined;
  readonly viewer: {
    readonly canApprove: boolean;
    readonly id: string;
  };
};
export type CompetitionScaffoldQuery = {
  response: CompetitionScaffoldQuery$data;
  variables: CompetitionScaffoldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": "canApprove",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "CREATE_PROJECT_VERSION_APPROVAL"
        }
      ],
      "kind": "ScalarField",
      "name": "can",
      "storageKey": "can(action:\"CREATE_PROJECT_VERSION_APPROVAL\")"
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "banner",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v11 = {
  "alias": "viewerCanEdit",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "UPDATE_COMPETITION"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_COMPETITION\")"
},
v12 = {
  "alias": "viewerCanEditMembers",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "ADD_COMPETITION_MEMBER"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"ADD_COMPETITION_MEMBER\")"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPrivate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiresApproval",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasLeaderboard",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v19 = [
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionScaffoldQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "host",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "thumbnail",
                    "value": true
                  }
                ],
                "kind": "FragmentSpread",
                "name": "EntityProfilePicFragment"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompetitionSetupButtonFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompetitionJoinButtonFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionScaffoldQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "host",
            "plural": false,
            "selections": [
              (v18/*: any*/),
              (v1/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isEntity"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageThumbnail",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UseCase",
            "kind": "LinkedField",
            "name": "useCase",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectVersion",
                "kind": "LinkedField",
                "name": "latest",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "kind",
                        "value": "TEMPLATE"
                      }
                    ],
                    "concreteType": "ProjectVersionFile",
                    "kind": "LinkedField",
                    "name": "fileByKind",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kind",
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "extension",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectVersion",
                        "kind": "LinkedField",
                        "name": "projectVersion",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v18/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Competition",
                                "kind": "LinkedField",
                                "name": "competition",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "fileByKind(kind:\"TEMPLATE\")"
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "viewerCanAgree",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "CREATE_COMPETITION_RULE_AGREEMENT"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"CREATE_COMPETITION_RULE_AGREEMENT\")"
          },
          {
            "alias": "viewerCanJoin",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "JOIN_COMPETITION"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"JOIN_COMPETITION\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionMembership",
            "kind": "LinkedField",
            "name": "membership",
            "plural": false,
            "selections": (v19/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              }
            ],
            "concreteType": "CompetitionRuleAgreementConnection",
            "kind": "LinkedField",
            "name": "entityRuleAgreements",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetitionRuleAgreement",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v19/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": "entityRuleAgreements(first:1)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Forum",
            "kind": "LinkedField",
            "name": "forum",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "entitySubscription",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionRule",
            "kind": "LinkedField",
            "name": "latestRule",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetitionRuleAgreement",
                "kind": "LinkedField",
                "name": "entityAgreement",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fb2e86594c2a413507ee9079acbf38d",
    "id": null,
    "metadata": {},
    "name": "CompetitionScaffoldQuery",
    "operationKind": "query",
    "text": "query CompetitionScaffoldQuery(\n  $slug: String!\n) {\n  viewer @ifAllowed {\n    id\n    canApprove: can(action: CREATE_PROJECT_VERSION_APPROVAL)\n  }\n  competitionBySlug(slug: $slug) {\n    id\n    slug\n    title\n    shortDescription\n    description\n    createdAt\n    banner\n    thumbnail\n    viewerCanEdit: viewerCan(action: UPDATE_COMPETITION)\n    viewerCanEditMembers: viewerCan(action: ADD_COMPETITION_MEMBER)\n    isPrivate\n    requiresApproval\n    hasLeaderboard\n    host {\n      __typename\n      id\n      displayName\n      username\n      ...EntityProfilePicFragment_49kWkb\n    }\n    ...CompetitionSetupButtonFragment\n    ...CompetitionJoinButtonFragment\n  }\n}\n\nfragment CompetitionJoinButtonFragment on Competition {\n  id\n  viewerCanAgree: viewerCan(action: CREATE_COMPETITION_RULE_AGREEMENT)\n  viewerCanJoin: viewerCan(action: JOIN_COMPETITION)\n  membership {\n    id\n  }\n  entityRuleAgreements(first: 1) @ifAllowed {\n    nodes {\n      id\n    }\n  }\n  forum {\n    id\n    entitySubscription @ifAllowed {\n      __typename\n      id\n    }\n  }\n  latestRule {\n    id\n    text\n    entityAgreement @ifAllowed {\n      id\n      createdAt\n    }\n  }\n}\n\nfragment CompetitionSetupButtonFragment on Competition {\n  id\n  slug\n  useCase {\n    latest {\n      fileByKind(kind: TEMPLATE) {\n        kind\n        ...ProjectDownloadButtonFragment\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment ProjectDownloadButtonFragment on ProjectVersionFile {\n  id\n  kind\n  extension\n  projectVersion {\n    project {\n      __typename\n      competition {\n        slug\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5fb014d493223af835b1c24b3ad7215";

export default node;
