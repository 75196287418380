import React from "react";
import ReactDOM from "react-dom/client";
import App from "./common/App";
import "./index.css";
import * as Sentry from "@sentry/react";
import * as SentryIntegrations from "@sentry/browser";
import AuthProvider from "./common/browser/AuthProvider";
import LocationProvider from "./common/browser/LocationProvider";
import RelayEnvironment from "./common/browser/RelayEnvironment";
import Router from "./common/browser/Router";
import { logger } from "./common/logger";

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      SentryIntegrations.captureConsoleIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    tracePropagationTargets: ["localhost", /^https:\/\/aqora\.io/], // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  });
  logger.info("Sentry initialized: ", SENTRY_DSN);
} else {
  logger.warn("Sentry DSN not found");
}

ReactDOM.hydrateRoot(
  document.getElementById("root")!,
  <React.StrictMode>
    <App
      AuthProvider={AuthProvider}
      RelayEnvironment={RelayEnvironment}
      Router={Router}
      LocationProvider={LocationProvider}
    />
  </React.StrictMode>,
);
