import { graphql, Environment } from "react-relay";
import { preload, usePreloaded } from "../../utils/relay";
import { GlobalLeaderboardPageQuery as GlobalLeaderboardPageQueryType } from "./__generated__/GlobalLeaderboardPageQuery.graphql";

export type { GlobalLeaderboardPageQuery as GlobalLeaderboardPageQueryType } from "./__generated__/GlobalLeaderboardPageQuery.graphql";

export const GlobalLeaderboardPageQuery = graphql`
  query GlobalLeaderboardPageQuery {
    ...GlobalLeaderboardFragment
  }
`;

export const globalLeaderboardPageQueryLoader =
  (environment: Environment) => () =>
    preload<GlobalLeaderboardPageQueryType>(
      environment,
      GlobalLeaderboardPageQuery,
    );

export const useGlobalLeaderboardPageQuery = () =>
  usePreloaded<GlobalLeaderboardPageQueryType>();
