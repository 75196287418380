import { useState } from "react";
import { ConnectionHandler, graphql, useMutation } from "react-relay";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import EventMembers from "../components/EventMembers";
import { useEventMembersPageQuery } from "./loaders/EventMembersPage";
import { EventMembersPageCreateMutation as EventMembersPageCreateMutationType } from "./__generated__/EventMembersPageCreateMutation.graphql";
import Button from "../components/Button";
import UserAutocomplete from "../components/UserAutocomplete";
import ErrorPage from "./ErrorPage";
import { logger } from "../common/logger";

const EventMembersPageCreateMutation = graphql`
  mutation EventMembersPageCreateMutation(
    $eventId: ID!
    $entityId: ID!
    $connections: [ID!]!
  ) {
    addEventMember(eventId: $eventId, entityId: $entityId)
      @prependEdge(connections: $connections) {
      node {
        id
        kind
        viewerCanDelete: viewerCan(action: REMOVE_EVENT_MEMBER)
        entity {
          id
          username
          displayName
          ...EntityProfilePicFragment @arguments(thumbnail: true)
        }
        event {
          id
          viewerCanEditMembers: viewerCan(action: ADD_EVENT_MEMBER)
          viewerCanEdit: viewerCan(action: UPDATE_EVENT)
        }
      }
    }
  }
`;

type FormData = {
  entityId: string;
};

export default function EventMembersPage() {
  const intl = useIntl();
  const { query } = useEventMembersPageQuery();
  const event = query.eventBySlug;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | null>(null);
  const [commitAddMutation, isAddMutationInFlight] =
    useMutation<EventMembersPageCreateMutationType>(
      EventMembersPageCreateMutation,
    );
  if (!event) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Event not found",
        })}
      />
    );
  }
  const errorMessages = {
    entityId: {
      required: intl.formatMessage({
        defaultMessage: "Please select the username from the list",
      }),
    },
  };
  const onAddSubmit = handleSubmit(({ entityId }) => {
    setFormError(null);
    commitAddMutation({
      variables: {
        eventId: event.id,
        entityId,
        connections: [
          ConnectionHandler.getConnectionID(
            event.id,
            "EventMembersFragment_members",
          ),
        ],
      },
      onError: (error) => {
        logger.error(error);
        setFormError(
          intl.formatMessage({
            defaultMessage: "Something went wrong",
          }),
        );
      },
    });
  });
  return (
    <>
      {event.viewerCanEditMembers && (
        <div className="flex flex-col items-end pb-8">
          <form>
            <div className="flex flex-row space-x-2">
              <div>
                <UserAutocomplete
                  query={query}
                  placeholder={intl.formatMessage({
                    defaultMessage: "username",
                  })}
                  {...register("entityId", { required: true })}
                />
              </div>
              <div>
                <Button
                  kind="primary"
                  onClick={() => {
                    onAddSubmit();
                  }}
                  disabled={isAddMutationInFlight}
                >
                  <span className="sm:hidden">
                    <FormattedMessage defaultMessage="Add" />
                  </span>
                  <span className="hidden sm:inline">
                    <FormattedMessage defaultMessage="Add Member" />
                  </span>
                </Button>
              </div>
            </div>
          </form>
          <div>
            {typeof errors.entityId?.type === "string" && (
              <p className="text-red-500 text-sm">
                {
                  errorMessages.entityId[
                    errors.entityId.type as keyof typeof errorMessages.entityId
                  ]
                }
              </p>
            )}
            {formError && <p className="text-red-500 text-sm">{formError}</p>}
          </div>
        </div>
      )}
      <div>
        <EventMembers user={event} />
      </div>
    </>
  );
}
