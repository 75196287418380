import { useState, useCallback, ReactNode } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "sonner";
import { useAuth, useLoginRedirect } from "../utils/auth";
import Button from "./Button";
import * as Dialog from "./Dialog";
import Markdown from "./Markdown";
import { CompetitionJoinButtonFragment$key } from "./__generated__/CompetitionJoinButtonFragment.graphql";
import { CompetitionJoinButtonMutation as CompetitionJoinButtonMutationType } from "./__generated__/CompetitionJoinButtonMutation.graphql";

const CompetitionJoinButtonFragment = graphql`
  fragment CompetitionJoinButtonFragment on Competition {
    id
    viewerCanAgree: viewerCan(action: CREATE_COMPETITION_RULE_AGREEMENT)
    viewerCanJoin: viewerCan(action: JOIN_COMPETITION)
    membership {
      id
    }
    entityRuleAgreements(first: 1) @ifAllowed {
      nodes {
        id
      }
    }
    forum {
      id
      entitySubscription @ifAllowed {
        id
      }
    }
    latestRule {
      id
      text
      entityAgreement @ifAllowed {
        id
        createdAt
      }
    }
  }
`;

const CompetitionJoinButtonMutation = graphql`
  mutation CompetitionJoinButtonMutation(
    $competitionId: ID!
    $forumId: ID!
    $join: Boolean!
    $agree: Boolean!
    $subscribe: Boolean!
  ) {
    joinCompetition(competitionId: $competitionId) @include(if: $join) {
      node {
        id
        competition {
          membership {
            id
          }
        }
      }
    }
    agreeToCompetitionRule(competition: $competitionId) @include(if: $agree) {
      id
      createdAt
      competitionRule {
        competition {
          id
          entityRuleAgreements(first: 1) @ifAllowed {
            nodes {
              id
            }
          }
          latestRule {
            id
            entityAgreement @ifAllowed {
              id
              createdAt
            }
          }
        }
      }
    }
    subscribeToSubject(subject: $forumId) @include(if: $subscribe) {
      node {
        subject {
          id
          entitySubscription @ifAllowed {
            id
          }
        }
      }
    }
  }
`;

interface Props {
  competition: CompetitionJoinButtonFragment$key;
  children?: ReactNode;
}

export default function CompetitionJoinButton({
  competition: competitionFragment,
  children,
}: Props) {
  const competition = useFragment(
    CompetitionJoinButtonFragment,
    competitionFragment,
  );
  const { userId } = useAuth();

  if (!userId) {
    return <CompetitionLoginButton />;
  }
  const hasJoined = !!competition.membership;
  const hasAccepted = !!competition.latestRule?.entityAgreement;
  const hasSubscribed = !!competition.forum.entitySubscription;
  if (
    (!hasJoined && !competition.viewerCanJoin) ||
    (!hasAccepted && !competition.viewerCanAgree)
  ) {
    return null;
  }
  if (!hasJoined || !hasAccepted) {
    const hasAcceptedBefore =
      competition.entityRuleAgreements?.nodes.length > 0;
    return (
      <CompetitionJoinModal
        competitionId={competition.id}
        forumId={competition.forum.id}
        hasJoined={hasJoined}
        hasAccepted={hasAccepted}
        hasAcceptedBefore={hasAcceptedBefore}
        hasSubscribed={hasSubscribed}
        rules={competition.latestRule?.text}
      />
    );
  }
  return children;
}

function CompetitionLoginButton() {
  const to = useLoginRedirect();
  return (
    <Button to={to}>
      <FormattedMessage defaultMessage="Join competition" />
    </Button>
  );
}

function CompetitionJoinModal({
  competitionId,
  forumId,
  hasJoined,
  hasAccepted,
  hasAcceptedBefore,
  hasSubscribed,
  rules,
}: {
  competitionId: string;
  forumId: string;
  hasJoined: boolean;
  hasAccepted: boolean;
  hasAcceptedBefore: boolean;
  hasSubscribed: boolean;
  rules?: string;
}) {
  const intl = useIntl();

  const [commitMutation, isMutationInFlight] =
    useMutation<CompetitionJoinButtonMutationType>(
      CompetitionJoinButtonMutation,
    );
  const [open, setOpen] = useState(false);

  const doJoin = useCallback(() => {
    commitMutation({
      variables: {
        competitionId,
        forumId,
        agree: !hasAccepted,
        join: !hasJoined,
        subscribe: !hasSubscribed,
      },
      onError() {
        toast.error(
          intl.formatMessage({
            defaultMessage: "Could not join the competition. Please try again",
          }),
        );
      },
      onCompleted() {
        toast.success(
          intl.formatMessage({
            defaultMessage: "Successfully joined the competition",
          }),
        );
        setOpen(false);
      },
    });
  }, [
    intl,
    competitionId,
    forumId,
    commitMutation,
    setOpen,
    hasSubscribed,
    hasJoined,
    hasAccepted,
  ]);

  const onJoin = useCallback(() => {
    if (hasAccepted && hasJoined && hasSubscribed) {
      return;
    } else if (hasAccepted) {
      doJoin();
    } else {
      setOpen(true);
    }
  }, [hasAccepted, hasJoined, hasSubscribed, setOpen, doJoin]);

  const onAccept = useCallback(() => {
    doJoin();
  }, [doJoin]);

  return (
    <>
      <Button onClick={onJoin} disabled={isMutationInFlight}>
        {hasJoined && hasAcceptedBefore ? (
          <FormattedMessage defaultMessage="Review rules" />
        ) : (
          <FormattedMessage defaultMessage="Join competition" />
        )}
      </Button>
      <Dialog.Root open={open}>
        <Dialog.Content>
          <div className="flex flex-col space-y-4 p-4 md:px-2">
            <h1 className="text-2xl font-bold w-full font-poppins">
              <FormattedMessage defaultMessage="Rules, Terms & Conditions" />
            </h1>
            {rules && (
              <>
                <h2 className="font-bold w-full">
                  <FormattedMessage defaultMessage="Competition specific rules" />
                </h2>
                <div className="border border-gray-300 bg-background p-4 rounded-lg max-h-96 overflow-y-auto">
                  <Markdown>{rules}</Markdown>
                </div>
              </>
            )}
            <p className="py-2">
              <FormattedMessage defaultMessage="By default all competitions must comply to the rules defined in" />{" "}
              <a
                href="https://aqora.io/terms"
                target="_blank"
                rel="noreferrer"
                className="text-blue-400"
              >
                <FormattedMessage defaultMessage="aqora's Terms of Use" />
              </a>
            </p>
            <div className="flex flex-row space-x-4">
              <Button
                kind="secondary"
                className="w-full"
                onClick={() => setOpen(false)}
                disabled={isMutationInFlight}
              >
                <FormattedMessage defaultMessage="I decline" />
              </Button>
              <Button
                kind="primary"
                className="w-full"
                onClick={onAccept}
                disabled={isMutationInFlight}
              >
                <FormattedMessage defaultMessage="I accept" />
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
