import { graphql, useMutation, ConnectionHandler } from "react-relay";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { useCompetitionTopicCreatePageQuery } from "./loaders/CompetitionTopicCreatePage";
import {
  CompetitionTopicCreatePageMutation$variables,
  CompetitionTopicCreatePageMutation as CompetitionTopicCreatePageMutationType,
} from "./__generated__/CompetitionTopicCreatePageMutation.graphql";
import ErrorPage from "./ErrorPage";
import TopicEditForm from "../components/TopicEditForm";
import { logger } from "../common/logger";
import {
  DEFAULT_VOTABLE_ORDER,
  TopicVotableOrderContext,
} from "../utils/votableOrder";
import { getTopicPath } from "../utils/routing";

const CompetitionTopicCreatePageMutation = graphql`
  mutation CompetitionTopicCreatePageMutation(
    $forumId: ID!
    $input: CreateTopicInput!
    $connections: [ID!]!
  ) {
    createTopic(forumId: $forumId, input: $input)
      @prependEdge(connections: $connections) {
      node {
        id
        title
        forum {
          slug
          owner {
            __typename
            slug
          }
        }
      }
    }
  }
`;

export default function CompetitionTopicCreatePage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { query } = useCompetitionTopicCreatePageQuery();
  const competition = query?.competitionBySlug;
  const [commitMutation, isMutationInFlight] =
    useMutation<CompetitionTopicCreatePageMutationType>(
      CompetitionTopicCreatePageMutation,
    );

  const { order = DEFAULT_VOTABLE_ORDER } = location.state || {};

  if (!competition) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Competition not found",
        })}
      />
    );
  }

  return (
    <TopicVotableOrderContext.Provider value={order}>
      <div>
        <h1 className="text-xl font-bold pb-4 font-poppins">
          <FormattedMessage defaultMessage={"New Topic"} />
        </h1>
        <TopicEditForm
          onSubmit={({ data, setFormError }) => {
            const variables: CompetitionTopicCreatePageMutation$variables = {
              forumId: competition.forum.id,
              input: {
                title: data.title,
                url: data.url || undefined,
                description: data.description || undefined,
              },
              connections: [
                ConnectionHandler.getConnectionID(
                  competition.forum.id,
                  "ForumTopicsFragment_topics",
                  { order },
                ),
              ],
            };
            commitMutation({
              variables,
              onError: (error) => {
                logger.error(error);
                setFormError(
                  intl.formatMessage({
                    defaultMessage: "Could not create topic :(",
                  }),
                );
              },
              onCompleted: ({ createTopic: { node } }) => {
                navigate(getTopicPath(node));
              },
            });
          }}
          isDisabled={isMutationInFlight}
        />
      </div>
    </TopicVotableOrderContext.Provider>
  );
}
