/**
 * @generated SignedSource<<e7fcb65e681e7b0f1cbc19246374a308>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicDeleteButtonFragment$data = {
  readonly __id: string;
  readonly forum: {
    readonly id: string;
    readonly owner: {
      readonly __typename: string;
      readonly slug: string;
    };
    readonly slug: string;
  };
  readonly id: string;
  readonly title: string;
  readonly " $fragmentType": "TopicDeleteButtonFragment";
};
export type TopicDeleteButtonFragment$key = {
  readonly " $data"?: TopicDeleteButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicDeleteButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicDeleteButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Forum",
      "kind": "LinkedField",
      "name": "forum",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "owner",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__id",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Topic",
  "abstractKey": null
};
})();

(node as any).hash = "a1069ec12deda7a02ef870707c3091cc";

export default node;
