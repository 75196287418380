import React, {
  ReactElement,
  type HTMLAttributeAnchorTarget,
  isValidElement,
  cloneElement,
} from "react";
import { NavLink } from "react-router-dom";
import { cn } from "../utils/tailwind";

type Variant = "primary" | "secondary";

interface Props {
  children?: React.ReactNode;
  to: string;
  vertical?: boolean;
  exact?: boolean;
  target?: HTMLAttributeAnchorTarget;
  icon?: ReactElement<{ className?: string }>;
  variant?: Variant;
}

export default function NavItem({
  children,
  to,
  target,
  vertical = false,
  exact = false,
  variant = "primary",
  icon,
}: Props) {
  return (
    <NavLink
      to={to}
      end={exact}
      target={target}
      className={({ isActive }) =>
        cn([
          "relative flex items-center gap-1 cursor-pointer px-3 py-2 whitespace-nowrap rounded-lg hover:bg-black hover:bg-opacity-5",
          vertical && "font-semibold mx-2",
          (!isActive || variant == "secondary" || vertical) &&
            "[&>.active-indicator]:hidden",
          isActive &&
            variant == "secondary" &&
            !vertical &&
            "text-indigo bg-black bg-opacity-5",
          isActive && vertical && "bg-black bg-opacity-5",
        ])
      }
    >
      {children}
      {isValidElement(icon) &&
        cloneElement(icon, {
          className: cn(icon.props.className, "text-indigo w-3 h-auto"),
        })}
      <div className="active-indicator bg-indigo h-1 w-6 absolute bottom-0 left-[50%] -ml-3 rounded-full"></div>
    </NavLink>
  );
}
