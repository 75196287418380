import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { MdSearch } from "react-icons/md";
import Suspense from "../common/Suspense";
import GlobalLeaderboard from "../components/GlobalLeaderboard";
import TextInput from "../components/TextInput";
import { useGlobalLeaderboardPageQuery } from "./loaders/GlobalLeaderboardPage";

export default function GlobalLeaderboardPage() {
  const { query } = useGlobalLeaderboardPageQuery();
  const [search, setSearch] = useState("");
  return (
    <>
      <div className="flex flex-row  pt-8">
        <h1 className="text-2xl font-bold w-full font-poppins">
          <FormattedMessage defaultMessage="Leaderboard" />
        </h1>
        <TextInput
          prefix={<MdSearch />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="pt-4 pb-8">
        <Suspense>
          <GlobalLeaderboard query={query} search={search} />
        </Suspense>
      </div>
    </>
  );
}
