import { useState } from "react";
import { differenceInMilliseconds, format, parse } from "date-fns";
import { formatInTimeZone, fromZonedTime } from "date-fns-tz";
import { EVENT_COMPARATOR, dateTime } from "./Timetable/utils";
import Timetable from "./Timetable";
import { TimetableEventInit } from "./Timetable/types";
import { useIsomorphicLayoutEffect } from "../utils/hooks";
import { graphql } from "relay-runtime";
import { useFragment } from "react-relay";
import { EventTimetableFragment$key } from "./__generated__/EventTimetableFragment.graphql";

const EventTimetableFragment = graphql`
  fragment EventTimetableFragment on Event {
    timetable: agenda
  }
`;

const DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

const calculateTimeUntilNextEvent = (
  events: TimetableEventInit[],
  timeZone: string,
): number | null => {
  const sortedEvents = events.slice().sort(EVENT_COMPARATOR);
  const currentDateWithTimezone = new Date();

  const nextIndex = sortedEvents.findIndex((event) => {
    const eventTime = fromZonedTime(dateTime(event.date, event.time), timeZone);
    return eventTime > currentDateWithTimezone;
  });

  if (nextIndex < 0) return null;

  const nextEventTime = fromZonedTime(
    dateTime(sortedEvents[nextIndex].date, sortedEvents[nextIndex].time),
    timeZone,
  );

  return Math.max(
    60_000,
    differenceInMilliseconds(nextEventTime, currentDateWithTimezone),
  );
};

export interface Props {
  timetable: EventTimetableFragment$key;
}

export default function EventTimetable({
  timetable: timetableFragment,
}: Props) {
  const { timetable } = useFragment(EventTimetableFragment, timetableFragment);

  const [clock, setClock] = useState(new Date());

  const onChangeToday = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setClock(parse(evt.target.value, DATETIME_FORMAT, new Date()));
  };

  useIsomorphicLayoutEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    function intervalfn() {
      setClock(new Date());
      const newIntervalDuration = calculateTimeUntilNextEvent(
        timetable.events,
        timetable.timezone,
      );
      if (newIntervalDuration !== null) {
        interval = setTimeout(intervalfn, newIntervalDuration);
      } else {
        interval = undefined;
      }
    }

    const nextTime = calculateTimeUntilNextEvent(
      timetable.events,
      timetable.timezone,
    );
    if (nextTime !== null) {
      interval = setTimeout(intervalfn, nextTime);
    }

    return () => clearTimeout(interval);
  }, []);

  return (
    <>
      {import.meta.env.DEV && (
        <input
          type="datetime-local"
          value={format(clock, DATETIME_FORMAT)}
          onChange={onChangeToday}
        />
      )}
      <Timetable
        context={{
          clock: formatInTimeZone(clock, timetable.timezone, DATETIME_FORMAT),
          location: timetable.location,
          timezone: timetable.timezone,
        }}
        phases={timetable.phases}
        events={timetable.events}
      />
    </>
  );
}
