import clsx, { ClassValue as Clsx } from "clsx";
import {
  createContext,
  memo,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaCalendarDay, FaCaretDown } from "react-icons/fa6";
import {
  FormattedDate,
  FormattedDateTimeRange,
  FormattedMessage,
  FormattedTime,
} from "react-intl";
import { EVENT_COMPARATOR, dateTime, splitDateTime } from "./utils";

import {
  TimetableContextInit,
  TimetableEventInit,
  TimetablePhaseInit,
  TimetableProps,
} from "./types";
import { truthOrFail } from "../../utils/helpers";
import { Comparator } from "../../utils/comparator";

class TimetableContextValue implements TimetableContextInit {
  public readonly clock: string;
  public readonly location: string;
  public readonly timezone: string;

  constructor(
    init: TimetableContextInit,
    public readonly start: string,
    public readonly end: string,
  ) {
    this.clock = init.clock;
    this.location = init.location;
    this.timezone = init.timezone;
  }

  get today() {
    const [date, _time] = splitDateTime(this.clock);
    return date;
  }

  get time() {
    const [_date, time] = splitDateTime(this.clock);
    return time;
  }

  get isPending() {
    return this.isClockBetween(this.start, this.end);
  }

  isClockBetween = (start: string, end: string): boolean => {
    return start <= this.clock && this.clock <= end;
  };
}

const TimetableContext = createContext<TimetableContextValue | null>(null);

export default function Timetable({
  context: contextInit,
  phases,
  events,
}: TimetableProps) {
  const context = useMemo(() => {
    if (events.length <= 0) return null;

    const startEvent = Comparator.minimum(events, EVENT_COMPARATOR)!;
    const endEvent = Comparator.maximum(events, EVENT_COMPARATOR)!;
    return new TimetableContextValue(
      contextInit,
      dateTime(startEvent.date, startEvent.time),
      dateTime(endEvent.date, endEvent.time),
    );
  }, [contextInit, events]);

  if (events.length <= 0) {
    return null;
  }

  return (
    <TimetableContext.Provider value={context}>
      <div className="flex flex-col space-y-8 py-4">
        {phases.map((phase) => (
          <Phase
            key={phase.id}
            phase={phase}
            events={events.filter((e) => e.phase === phase.id)}
          />
        ))}
      </div>
    </TimetableContext.Provider>
  );
}

interface PhaseProps {
  phase: TimetablePhaseInit;
  events: TimetableEventInit[];
}

function Phase(props: PhaseProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { location } = truthOrFail(useContext(TimetableContext));

  const events = new Map<string, TimetableEventInit[]>();
  for (const event of props.events) {
    let dateEvents = events.get(event.date);
    if (!dateEvents) {
      events.set(event.date, (dateEvents = []));
    }
    dateEvents.push(event);
  }
  const dates = Array.from(events.keys()).sort(Comparator.string);
  for (const date of dates) {
    events.get(date)!.sort(Comparator.by((ev) => ev.time, Comparator.string));
  }

  const startPhase = dates[0];
  const endPhase = dates.at(-1);
  if (!startPhase || !endPhase) {
    return null;
  }

  return (
    <div ref={containerRef} className="flex flex-col space-y-4">
      <h3 className="text-xl">
        {props.phase.label} (
        <FormattedDateTimeRange
          from={Date.parse(startPhase)}
          to={Date.parse(endPhase)}
          month="short"
          day="numeric"
        />
        , {props.phase.location ?? location})
      </h3>

      {dates.map((date) => (
        <PhaseDate
          key={date}
          phase={props.phase}
          date={date}
          events={events.get(date)!}
        />
      ))}
    </div>
  );
}

interface PhaseDateProps {
  phase: TimetablePhaseInit;
  date: string;
  events: TimetableEventInit[];
}

function updatePhaseDate(
  prev: Readonly<PhaseDateProps>,
  next: Readonly<PhaseDateProps>,
): boolean {
  return prev.phase.id !== next.phase.id;
}

const PhaseDate = memo(function PhaseDate(props: PhaseDateProps) {
  const { today } = truthOrFail(useContext(TimetableContext));
  const [fold, setFold] = useState(false);

  const onToggleFold = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setFold((fold) => !fold);
  };

  if (fold) {
    return (
      <div
        className={clsx(
          "flex",
          "flex-row",
          "ps-8",
          "py-4",
          "rounded",
          "cursor-pointer",
          "text-slate-400",
          "bg-gray-50",
          "hover:bg-slate-100",
        )}
        onClick={onToggleFold}
      >
        <div className={clsx(["flex", "flex-row", "w-40", "items-start"])}>
          <span
            className={clsx(
              "inline-flex",
              "flex-row",
              "space-x-2",
              "items-center",
            )}
          >
            <span>
              <FormattedDate
                value={props.date}
                weekday="short"
                month="short"
                day="numeric"
              />
            </span>
            <span>{props.date === today && <FaCalendarDay />}</span>
          </span>
        </div>

        <div className="flex-1 flex-col space-y-2">
          <span>
            <FormattedMessage
              defaultMessage="{eventCount,plural, =0 {No events} one {# event} other {# events}} scheduled"
              values={{ eventCount: props.events.length }}
            />
          </span>
        </div>

        <span className="pt-1 pe-2">
          <FaCaretDown />
        </span>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "flex",
        "flex-row",
        "ps-8",
        "py-4",
        "rounded",
        props.date === today
          ? ["border-s-4", "border-indigo"]
          : ["cursor-pointer", "bg-gray-50", "hover:bg-slate-100"],
      )}
      onClick={props.date !== today ? onToggleFold : undefined}
    >
      <div
        className={clsx([
          "flex",
          "flex-row",
          "w-40",
          props.date === today ? "items-center" : "items-start",
        ])}
      >
        <span
          className={clsx(
            "inline-flex",
            "flex-row",
            "space-x-2",
            "items-center",
            props.date === today && ["font-bold", "text-indigo"],
          )}
        >
          <span>
            <FormattedDate
              value={props.date}
              weekday="short"
              month="short"
              day="numeric"
            />
          </span>
          <span>{props.date === today && <FaCalendarDay />}</span>
        </span>
      </div>

      <div className="flex-1 flex-col space-y-2">
        {props.events.map((event, index) => (
          <PhaseEvent
            key={index}
            phase={props.phase}
            event={event}
            nextEvent={props.events[index + 1]}
          />
        ))}
      </div>
    </div>
  );
}, updatePhaseDate);

interface PhaseEventProps {
  phase: TimetablePhaseInit;
  event: TimetableEventInit;
  nextEvent: TimetableEventInit | undefined;
}

function PhaseEvent(props: PhaseEventProps) {
  const context = truthOrFail(useContext(TimetableContext));

  return (
    <div
      className={clsx(
        "flex",
        "flex-row",
        "rounded",
        "px-3 py-1",
        getEventStyles(props, context),
      )}
    >
      <span className="w-32">
        <FormattedTime value={dateTime(props.event.date, props.event.time)} />
      </span>
      <span>{props.event.label}</span>
    </div>
  );
}

function getEventStyles(
  { event, nextEvent }: PhaseEventProps,
  { today, time }: TimetableContextValue,
): Clsx {
  if (event.date === today) {
    if (event.time > time) {
      return ["border", "bg-gray-50"];
    }

    if (nextEvent && time < nextEvent.time) {
      return [
        "font-bold",
        "text-indigo",
        "bg-violet-100",
        "border-2",
        "border-violet-200",
      ];
    }
  }

  return null;
}
