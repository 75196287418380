/**
 * @generated SignedSource<<d9fbf170bdaafe8057d991825a3faf18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventAcceptRulesButtonEventFragment$data = {
  readonly id: string;
  readonly latestRule: {
    readonly entityAgreement: {
      readonly createdAt: string;
      readonly id: string;
    } | null | undefined;
    readonly id: string;
  };
  readonly " $fragmentType": "EventAcceptRulesButtonEventFragment";
};
export type EventAcceptRulesButtonEventFragment$key = {
  readonly " $data"?: EventAcceptRulesButtonEventFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventAcceptRulesButtonEventFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventAcceptRulesButtonEventFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EventRule",
      "kind": "LinkedField",
      "name": "latestRule",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EventRuleAgreement",
          "kind": "LinkedField",
          "name": "entityAgreement",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "5579ddd8dfd4f38be0573ff5ed6ba753";

export default node;
