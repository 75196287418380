import { PropsWithChildren } from "react";
import { Helmet, MetaProps } from "react-helmet-async";
import { useLocation } from "../utils/location";

export interface MetaLayoutProps extends PropsWithChildren {
  metaTitle?: string;
  metaDescription?: string | null;
  metaImageUrl?: string;
  metaImageAlt?: string | null;
  customMetaTags?: MetaProps[]; // [{"name": "description", "content": "Test description"}]
  hideTitleTemplate?: boolean;
}

export function MetaLayout({
  metaTitle,
  metaDescription,
  metaImageAlt,
  metaImageUrl,
  customMetaTags,
  children,
  hideTitleTemplate,
}: Readonly<MetaLayoutProps>) {
  const { href } = useLocation();
  return (
    <>
      <Helmet
        prioritizeSeoTags
        htmlAttributes={{ lang: "en" }}
        titleTemplate={hideTitleTemplate ? "" : "%s | Aqora.io"}
        meta={customMetaTags}
      >
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
        {metaTitle && <meta name="twitter:title" content={metaTitle} />}
        {metaTitle && <meta property="og:title" content={metaTitle} />}
        {metaTitle && <title>{metaTitle}</title>}
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        {metaDescription && (
          <meta property="og:description" content={metaDescription} />
        )}
        {metaDescription && (
          <meta name="twitter:description" content={metaDescription} />
        )}
        {metaImageUrl && <meta name="og:image" content={metaImageUrl} />}
        {metaImageUrl && <meta property="og:image" content={metaImageUrl} />}
        {metaImageUrl && <meta name="twitter:image" content={metaImageUrl} />}
        {metaImageAlt && (
          <meta property="og:image:alt" content={metaImageAlt} />
        )}
        {metaImageAlt && (
          <meta name="twitter:image:alt" content={metaImageAlt} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {children}
    </>
  );
}
