import { IntlShape, MessageDescriptor, defineMessage } from "react-intl";
import { NotificationKind } from "../pages/loaders/__generated__/UserSettingsNotificationsPageFragment.graphql";

export const notificationEnabled = defineMessage({
  defaultMessage: "You will be notified when {notification}",
});

export const notificationDisabled = defineMessage({
  defaultMessage:
    "You will no longer receive notifications when {notification}",
});

export const notificationLabels: {
  [K in NotificationKind]: {
    title: MessageDescriptor;
    description: MessageDescriptor;
  };
} = {
  AWARD_BADGE: {
    title: defineMessage({ defaultMessage: "badge awarded" }),
    description: defineMessage({
      defaultMessage: "Aqora awards you a badge for your contribution",
    }),
  },
  CREATE_SUBMISSION: {
    title: defineMessage({ defaultMessage: "submission created" }),
    description: defineMessage({
      defaultMessage:
        "someone submitted a quantum solution to your competition",
    }),
  },
  CREATE_TOPIC: {
    title: defineMessage({ defaultMessage: "topic created" }),
    description: defineMessage({
      defaultMessage: "someone created a topic on a competition you follow",
    }),
  },
  REPLY_TOPIC: {
    title: defineMessage({ defaultMessage: "topic replied" }),
    description: defineMessage({
      defaultMessage: "someone replied to your topic",
    }),
  },
  SYSTEM: {
    title: defineMessage({ defaultMessage: "system" }),
    description: defineMessage({
      defaultMessage: "system related messages",
    }),
  },
  CONTENT_MENTIONED: {
    title: defineMessage({ defaultMessage: "mentions" }),
    description: defineMessage({
      defaultMessage: "someone mentions you in a comment or topic",
    }),
  },
  REPLY_COMMENT: {
    title: defineMessage({ defaultMessage: "comment replied" }),
    description: defineMessage({
      defaultMessage: "someone replied to your comment",
    }),
  },
};

export function formatNotificationToast(
  intl: IntlShape,
  notification: NotificationKind,
  subscribe: boolean,
): string {
  return intl.formatMessage(
    subscribe ? notificationEnabled : notificationDisabled,
    {
      notification: intl.formatMessage(notificationLabels[notification]),
    },
  );
}
