/**
 * @generated SignedSource<<3b89c243e2a7fb48ae3c10d9b0f61cbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EntityProfilePicFragment$data = {
  readonly displayName: string;
  readonly image?: any | null | undefined;
  readonly imageThumbnail?: any | null | undefined;
  readonly " $fragmentType": "EntityProfilePicFragment";
};
export type EntityProfilePicFragment$key = {
  readonly " $data"?: EntityProfilePicFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "thumbnail"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EntityProfilePicFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "condition": "thumbnail",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageThumbnail",
          "storageKey": null
        }
      ]
    },
    {
      "condition": "thumbnail",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Entity",
  "abstractKey": "__isEntity"
};

(node as any).hash = "95fa085373c606e4038a07853e8cd9a4";

export default node;
