/**
 * @generated SignedSource<<17c0849078763f36f64fc516affc1d7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicsPageFragment$data = {
  readonly owner: {
    readonly __typename: string;
    readonly slug: string;
  };
  readonly slug: string;
  readonly viewerCanCreateTopic: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"ForumTopicsFragment" | "SubjectSubscriptionButtonFragment">;
  readonly " $fragmentType": "TopicsPageFragment";
};
export type TopicsPageFragment$key = {
  readonly " $data"?: TopicsPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicsPageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "HOT",
      "kind": "LocalArgument",
      "name": "order"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicsPageFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "viewerCanCreateTopic",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "CREATE_TOPIC"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"CREATE_TOPIC\")"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ForumTopicsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubjectSubscriptionButtonFragment"
    }
  ],
  "type": "Forum",
  "abstractKey": null
};
})();

(node as any).hash = "c2f636c6a2b49b1cd059085e3e41c7bb";

export default node;
