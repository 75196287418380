import { useState } from "react";
import {
  graphql,
  useFragment,
  useMutation,
  useLazyLoadQuery,
} from "react-relay";
import { useForm } from "react-hook-form";
import { EventAcceptRulesButtonQuery as EventAcceptRulesButtonQueryType } from "./__generated__/EventAcceptRulesButtonQuery.graphql";
import { EventAcceptRulesButtonEventFragment$key } from "./__generated__/EventAcceptRulesButtonEventFragment.graphql";
import { EventAcceptRulesButtonMutation as EventAcceptRulesButtonMutationType } from "./__generated__/EventAcceptRulesButtonMutation.graphql";
import FormGroup from "./FormGroup";
import { FormattedMessage, useIntl } from "react-intl";
import UserEntitiesAutocomplete from "./UserEntitiesAutocomplete";
import { toast } from "sonner";
import { MdCheckCircleOutline } from "react-icons/md";
import { ConfirmDialog } from "./ConfirmDialog";

const EventAcceptRulesButtonQuery = graphql`
  query EventAcceptRulesButtonQuery($event: ID!) {
    viewer {
      id
      username
      entitiesHead: entities(
        first: 2
        permission: { to: CREATE_EVENT_RULE_AGREEMENT, on: $event }
      ) {
        edges {
          node {
            id
          }
        }
      }
      ...UserEntitiesAutocompleteFragment
        @arguments(permission: { to: CREATE_EVENT_RULE_AGREEMENT, on: $event })
    }
  }
`;

const EventAcceptRulesButtonEventFragment = graphql`
  fragment EventAcceptRulesButtonEventFragment on Event {
    id
    latestRule {
      id
      entityAgreement @ifAllowed {
        id
        createdAt
      }
    }
  }
`;

const EventAcceptRulesButtonMutation = graphql`
  mutation EventAcceptRulesButtonMutation(
    $event: ID!
    $asEntity: UsernameOrID
  ) {
    agreeToEventRule(event: $event, asEntity: $asEntity) {
      id
      createdAt
      eventRule {
        event {
          id
          latestRule {
            id
            entityAgreement {
              id
              createdAt
            }
          }
        }
      }
    }
  }
`;

interface Props {
  event: EventAcceptRulesButtonEventFragment$key;
}

export default function EventAcceptRulesButton({
  event: eventFragment,
}: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const event = useFragment(EventAcceptRulesButtonEventFragment, eventFragment);
  const { viewer: user } = useLazyLoadQuery<EventAcceptRulesButtonQueryType>(
    EventAcceptRulesButtonQuery,
    {
      event: event.id,
    },
  );
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [commitMutation, isMutationInFlight] =
    useMutation<EventAcceptRulesButtonMutationType>(
      EventAcceptRulesButtonMutation,
    );

  const onSubmit = handleSubmit((data) => {
    if (isMutationInFlight) {
      return;
    }
    setFormError(undefined);
    commitMutation({
      variables: {
        event: event.id,
        asEntity: data.asEntity,
      },
      onError() {
        setFormError(
          intl.formatMessage({
            defaultMessage: "Could not accept the rules. Please try again.",
          }),
        );
      },
      onCompleted() {
        setDialogOpen((prev) => !prev);
        toast.success(
          intl.formatMessage({
            defaultMessage: "Event rules accepted!",
          }),
        );
      },
    });
  });

  const showUserSelect =
    user.entitiesHead.edges.length > 1 ||
    user.entitiesHead.edges[0].node.id !== user.id;
  const errorMessages = {
    required: intl.formatMessage({
      defaultMessage: "Please enter a valid user or organization",
    }),
  };
  return (
    <ConfirmDialog
      open={dialogOpen}
      onOpenChange={setDialogOpen}
      onConfirm={onSubmit}
      title={<FormattedMessage defaultMessage="Accept the Rules" />}
      buttonMessage={<FormattedMessage defaultMessage="I Accept" />}
      ButtonIcon={event.latestRule.entityAgreement && MdCheckCircleOutline}
      message={
        <FormattedMessage defaultMessage="By clicking on the button below, I accept the rules of the Event" />
      }
    >
      {showUserSelect && (
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Accept for" })}
          error={
            typeof errors.asEntity?.type === "string" &&
            errorMessages[errors.asEntity.type as keyof typeof errorMessages]
          }
        >
          <UserEntitiesAutocomplete
            query={user}
            {...register("asEntity", { required: true })}
          />
        </FormGroup>
      )}
      {formError && <p className="pt-1 text-sm text-red-500">{formError}</p>}
    </ConfirmDialog>
  );
}
