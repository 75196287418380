import { GraphQLSubscriptionConfig, graphql } from "relay-runtime";
import { UserSettingIntegrationsFragment$key } from "./__generated__/UserSettingIntegrationsFragment.graphql";
import { useFragment, useSubscription } from "react-relay";
import { useForm } from "react-hook-form";
import Switch from "./Switch";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { OptionToggleCard } from "./OptionToggleCard";
import { Link } from "react-router-dom";
import { useMemo, useState } from "react";
import { UserSettingIntegrationsSubscription as UserSettingIntegrationsSubscriptionType } from "./__generated__/UserSettingIntegrationsSubscription.graphql";
import { Skeleton } from "./Skeleton";

const UserSettingIntegrationsFragment = graphql`
  fragment UserSettingIntegrationsFragment on User
  @refetchable(queryName: "UserSettingIntegrationsRefetchFragment") {
    id
    isAvailableOnQuantumJobs
    jobBoardProfileLink
  }
`;

const UserSettingIntegrationsSubscription = graphql`
  subscription UserSettingIntegrationsSubscription($id: ID!) {
    updatedEntity(id: $id) {
      id
      ... on User {
        ...UserSettingIntegrationsFragment
      }
    }
  }
`;

interface FormData {
  isRegistered: boolean;
}

export interface Props {
  user: UserSettingIntegrationsFragment$key;
  onSyncProfile: (value: boolean) => void;
  disabled: boolean;
}

const messages = defineMessages({
  registeredTitle: {
    defaultMessage: "Remove Your Profile from the Quantum Job Board",
  },
  removedTitle: {
    defaultMessage: "Register Your Profile on the Quantum Job Board",
  },
  registeredDescription: {
    defaultMessage: "You can view it here: <link>{url}</link>",
  },
  removedDescription: {
    defaultMessage:
      "Interested in quantum job opportunities? Visit the job board here: <link>{url}</link>",
  },
  pendingTitle: {
    defaultMessage: "Synchronizing Your Profile",
  },
  pendingDescription: {
    defaultMessage: "Your profile will synchronize soon. Please wait.",
  },
});

export function UserSettingIntegrations({
  user: userFragment,
  onSyncProfile,
  disabled,
}: Props) {
  const intl = useIntl();
  const { id, isAvailableOnQuantumJobs, jobBoardProfileLink } = useFragment(
    UserSettingIntegrationsFragment,
    userFragment,
  );
  const [pending, setPending] = useState(false);

  const isSynced = isAvailableOnQuantumJobs && jobBoardProfileLink;
  const { titleMessage, descriptionMessage } = getMessages(isSynced);

  useSubscription(
    useMemo(
      (): GraphQLSubscriptionConfig<UserSettingIntegrationsSubscriptionType> => ({
        variables: { id },
        subscription: UserSettingIntegrationsSubscription,
        onError: (error) => {
          if (id) {
            console.error(error);
          }
        },
        updater: () => {
          setPending(false);
        },
      }),
      [id],
    ),
  );

  const url = isSynced
    ? jobBoardProfileLink
    : import.meta.env.VITE_QUANTUM_JOBS;

  const { register, control } = useForm<FormData>();

  const description = (
    <FormattedMessage
      {...descriptionMessage}
      values={{
        url,
        link: (chunks) =>
          url && (
            <Link to={url} target="_blank" className="hover:text-indigo">
              {chunks}
            </Link>
          ),
      }}
    />
  );

  return (
    <OptionToggleCard
      title={
        pending
          ? intl.formatMessage(messages.pendingTitle)
          : intl.formatMessage(titleMessage)
      }
      description={
        pending ? intl.formatMessage(messages.pendingDescription) : description
      }
    >
      {pending ? (
        <Skeleton className="h-8 w-12" />
      ) : (
        <Switch
          control={control}
          defaultValue={isSynced}
          disabled={disabled}
          {...register("isRegistered", {
            onChange({ target: { value } }) {
              onSyncProfile(!!value);
              setPending(true);
            },
          })}
        />
      )}
    </OptionToggleCard>
  );
}

function getMessages(isRegistered: boolean) {
  return {
    titleMessage: isRegistered
      ? messages.registeredTitle
      : messages.removedTitle,
    descriptionMessage: isRegistered
      ? messages.registeredDescription
      : messages.removedDescription,
  };
}
