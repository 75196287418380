import { useCompetitionCodePageQuery } from "./loaders/CompetitionCodePage";
import ErrorPage from "./ErrorPage";
import { useIntl } from "react-intl";
import ProjectFileBrowser from "../components/ProjectFileBrowser";

export default function CompetitionCodePage() {
  const intl = useIntl();
  const {
    query: { competitionBySlug: competition },
  } = useCompetitionCodePageQuery();
  if (!competition) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Data set not found",
        })}
      />
    );
  }
  return (
    <div className="pb-16">
      <ProjectFileBrowser
        root={`/competitions/${competition.slug}/code`}
        project={competition.useCase}
        competition={competition.slug}
      />
    </div>
  );
}
