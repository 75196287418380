/**
 * @generated SignedSource<<7102bc49d5a48fe961d27b3c47c8e452>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Badge = "BIG_PARIS_2021" | "CHICAGO_2023" | "GRENOBLE_2022" | "INGENII_2024_FIRST" | "INGENII_2024_OTHERS" | "INGENII_2024_SECOND" | "INGENII_2024_SPECIAL" | "INGENII_2024_THIRD" | "MONTREAL_2022" | "PARIS_2021" | "PARIS_2023" | "PARIS_2024_HACKERS" | "PARIS_2024_WINNERS" | "QUANTUM_PIONEER" | "TEST" | "TRIESTE_2023" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type EntityBadgeFragment$data = {
  readonly badge: Badge;
  readonly " $fragmentType": "EntityBadgeFragment";
};
export type EntityBadgeFragment$key = {
  readonly " $data"?: EntityBadgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EntityBadgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EntityBadgeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "badge",
      "storageKey": null
    }
  ],
  "type": "EntityBadge",
  "abstractKey": null
};

(node as any).hash = "2ef70381dade5acb5b36591c321049c6";

export default node;
