import { useCallback } from "react";
import { IntlShape } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { getParamFromRequest, parseEnumParam } from "./enum";
import { EntityActivitiesConnectionKind } from "../components/__generated__/UserActivityCalendarFragmentQuery.graphql";

const YEAR_PARAM_NAME = "activity_year";
const KIND_PARAM_NAME = "activity_kind";

interface ActivityFilter {
  kind?: EntityActivitiesConnectionKind;
  year?: number;
}

export const ACTIVITY_KINDS: ReadonlyArray<EntityActivitiesConnectionKind> = [
  "SUBMISSION",
  "TOPIC",
  "COMMENT",
];

export const parseActivityFiltersParams = (params: {
  kind: string | null | undefined;
  year: string | null | undefined;
}) => {
  const kind = params.kind
    ? parseEnumParam(params.kind, ACTIVITY_KINDS)
    : undefined;
  const year = params.year
    ? parseInt(params.year, 10)
    : new Date().getFullYear();

  return { kind, year };
};

export const useSetSearchActivityFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  return useCallback(
    ({ year, kind }: { year?: string; kind?: string }) => {
      if (year == new Date().getFullYear().toString()) {
        year = undefined;
      }
      if (year) {
        searchParams.set(YEAR_PARAM_NAME, year);
      } else {
        searchParams.delete(YEAR_PARAM_NAME);
      }
      if (kind) {
        searchParams.set(KIND_PARAM_NAME, kind);
      } else {
        searchParams.delete(KIND_PARAM_NAME);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );
};

export const getActivityFiltersParams = (
  request: Request,
): ActivityFilter | undefined => {
  return parseActivityFiltersParams({
    kind: getParamFromRequest(request, KIND_PARAM_NAME),
    year: getParamFromRequest(request, YEAR_PARAM_NAME),
  });
};

export const formatActivityKind = (
  intl: IntlShape,
  kind?: EntityActivitiesConnectionKind,
) => {
  switch (kind) {
    case "SUBMISSION":
      return intl.formatMessage({ defaultMessage: "Submission" });
    case "TOPIC":
      return intl.formatMessage({ defaultMessage: "Topic" });
    case "COMMENT":
      return intl.formatMessage({ defaultMessage: "Comment" });
    default:
      return intl.formatMessage({ defaultMessage: "All" });
  }
};
