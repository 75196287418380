import { useEventTopicsPageQuery } from "./loaders/EventTopicsPage";
import TopicsPage from "./common/TopicsPage";
import { useIntl } from "react-intl";
import ErrorPage from "./ErrorPage";
import ForumHelmet from "../components/ForumHelmet";

export default function EventTopicsPage() {
  const {
    query,
    variables: { order: initialOrder },
  } = useEventTopicsPageQuery();
  const forum = query?.eventBySlug?.forum;
  const intl = useIntl();
  if (!forum) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Event not found",
        })}
      />
    );
  }
  return (
    <ForumHelmet forum={forum}>
      <TopicsPage
        forum={query?.eventBySlug?.forum}
        initialOrder={initialOrder}
      />
    </ForumHelmet>
  );
}
